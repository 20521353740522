import Axios from "../../plugins/axios";
import { useEffect, useRef, React, useState, useContext } from 'react';
import { Tab, Nav, Card, Button, OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import SalesAnalysistab from "./detailstab/SalesAnalysistab";
import RelevantVideostab from "./detailstab/RelevantVideostab";
import RelevantLivetab from "./detailstab/RelevantLivetab";
import RelevantInfluencerstab from "./detailstab/RelevantInfluencerstab";
import CustomerReviewstab from "./detailstab/CustomerReviewstab";
import { useParams } from 'react-router-dom';
import Conversion from "../CommonComponents/Conversion";
import { Context } from '../../../MasterLayout';
import { useTranslation } from 'react-i18next';


const TopsalesDetail = () => {
   const { t } = useTranslation();
   const { dispatch } = useContext(Context);
   const params = useParams();
   const childRef = useRef();
   const childRelevantLiveRef = useRef();
   const relevantInfluencerRef = useRef();
   const customerReviewsRef = useRef();
   const [product, setProduct] = useState(null);

   useEffect(() => {
      productDetail();
   }, []);

   const productDetail = async () => {
      try {
         let response = await Axios.post('/api/product/details', { product_id: params.productId });
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });

         if (response && response.status === 200) {
            setProduct(response.data.data);
         }
      } catch (error) {
         console.error('Error: ' + error.message);
      };
   }
   const markFavourite = async (data) => {
      await Axios.post('/api/favorite-product', data);
      productDetail();
   };

   return (
      <>
         {product &&
            <>
               <Card>
                  <Card.Body>
                     <div className='row'>
                        <div className='col-lg-2'>
                           {product?.product_images[0] && <a href={`https://shop.tiktok.com/view/product/${params.productId}?region=${product.country.country_code}&local=en`} target="_blank" rel="noreferrer"> <img src={"https://t-img.picturehaven.net/product/" + product.product_images[0] + "?imageMogr2/auto-orient/thumbnail/306x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={300} /></a>}
                        </div>
                        <div className='col-lg-10'>
                           <div className='d-flex align-items-center justify-content-between'>
                              <div className='d-flex align-items-center'>
                                 <h4 className='mb-0 mr-5'>{product.title.length > 50 ? product.title.substring(0, 50) + '...' : product.title}</h4>
                                 {/* <Button variant="outline-dark" size="sm" className="px-2 py-1 mr-3"><i className="fa fa-language" aria-hidden="true"></i></Button> */}
                                 <Button variant="outline-dark" size="sm" className="px-2 py-1 mr-3" onClick={() => navigator.clipboard.writeText(`https://shop.tiktok.com/view/product/${params.productId}?region=${product.country.country_code}&local=en`)}><i className="fa fa-link" aria-hidden="true"></i></Button>
                                 {/* <Button variant="outline-dark" size="sm" className="px-2 py-1 mr-3"><i className="fa fa-qrcode" aria-hidden="true"></i></Button> */}
                                 <Button variant="outline-dark" size="sm" className="px-2 py-1 mr-3" onClick={() => { markFavourite({ id: product.product_id, country_code: product.country.country_code }); }}><i className={"fa " + (product?.favorite.length > 0 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i></Button>
                                 {/* <Button variant="outline-dark" size="sm" className="px-2 py-1 mr-3"><i className="fa fa-download" aria-hidden="true"></i></Button>
                                 <Button variant="outline-dark" size="sm" className="px-2 py-1 mr-3"><i className="fa fa-desktop" aria-hidden="true"></i></Button> */}
                              </div>
                              <div className="text-right">
                                 <p className="mb-0"><small>{t('product_research.product_details.data_shown_below_are')}</small></p>
                                 <p className="mb-0"><small>{t('product_research.product_details.time_of_first_discovery')}: {product.first_discover_time ? new Date(product.first_discover_time).toISOString().split('T')[0] : '--/--/--'}</small></p>
                              </div>
                           </div>
                           <div className='row'>
                              <div className='col-lg-6'>
                                 <div className='mb-3'>
                                    <p className='mb-0'><strong>{t('common.country_or_region')}:</strong> <span className={product?.country?.country_code.toLowerCase() ? `fi fi-${product?.country?.country_code.toLowerCase()} mr-1` : ''}></span>{product?.country?.country} <span>{product?.free_shipping ? '     Free Shipping' : ''}</span></p>
                                    <p className='mb-0'><strong>{t('common.category')}:</strong> {product?.product_category1?.name}{product?.product_category2?.name ? '/ ' + product?.product_category2?.name : ''} {product?.product_category3?.name ? '/ ' + product?.product_category3?.name : ''}</p>
                                    <div className='d-flex'>
                                       <p className='mb-0 mr-4'><strong>{t('common.price')}:</strong> {product.original_price_usd} ({product.original_price})</p>
                                       <p className='mb-0'><strong>{t('product_research.product_details.commission')}: </strong> {parseFloat(parseFloat(product.commission_rate) * 100).toFixed(2)}% ({product.earn_amount_usd})</p>
                                    </div>
                                    <p className='mb-0'><strong className='mr-2'>{t('product_research.product_details.product_rating')}:</strong>
                                       {(product.product_rating >= 1) ? <i className="fa fa-star text-warning" aria-hidden="true"></i> : (product.product_rating > 0 && product.product_rating < 1) ? <i className="fa fa-star-half-o text-warning" aria-hidden="true"></i> : <i className="fa fa-star-o text-warning" aria-hidden="true"></i>}{' '}
                                       {(product.product_rating >= 2) ? <i className="fa fa-star text-warning" aria-hidden="true"></i> : (product.product_rating > 1 && product.product_rating < 2) ? <i className="fa fa-star-half-o text-warning" aria-hidden="true"></i> : <i className="fa fa-star-o text-warning" aria-hidden="true"></i>}{' '}
                                       {(product.product_rating >= 3) ? <i className="fa fa-star text-warning" aria-hidden="true"></i> : (product.product_rating > 2 && product.product_rating < 3) ? <i className="fa fa-star-half-o text-warning" aria-hidden="true"></i> : <i className="fa fa-star-o text-warning" aria-hidden="true"></i>}{' '}
                                       {(product.product_rating >= 4) ? <i className="fa fa-star text-warning" aria-hidden="true"></i> : (product.product_rating > 3 && product.product_rating < 4) ? <i className="fa fa-star-half-o text-warning" aria-hidden="true"></i> : <i className="fa fa-star-o text-warning" aria-hidden="true"></i>}{' '}
                                       {(product.product_rating == 5) ? <i className="fa fa-star text-warning" aria-hidden="true"></i> : (product.product_rating > 4 && product.product_rating < 5) ? <i className="fa fa-star-half-o text-warning" aria-hidden="true"></i> : <i className="fa fa-star-o text-warning" aria-hidden="true"></i>}{' '}
                                       {product.product_rating} / 5.0
                                    </p>
                                 </div>
                                 <div className="d-flex">
                                    <OverlayTrigger key="right" trigger={["hover", "focus"]} placement="right" rootClose overlay={<Popover>
                                       <Popover.Body>
                                          {product.shop.shop_logo_privatization && <img src={"https://t-img.picturehaven.net/product/" + product.shop.shop_logo_privatization} alt="" width={500} />}
                                       </Popover.Body>
                                    </Popover>
                                    }
                                    >
                                       <Button variant="link" className='p-0' size="sm" to={'/products/' + product.id + '/details'}>
                                          <Link to={'/shops/' + product.shop.id + '/details'} target="_blank">
                                             <div className='d-flex gap-1'>
                                                <div className="prd-img-dtl mr-3">
                                                   {product?.shop?.shop_logo_privatization && <img src={'https://t-img.picturehaven.net/product/' + product.shop.shop_logo_privatization + '?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={80} />}
                                                </div>
                                             </div>
                                          </Link>
                                       </Button>
                                    </OverlayTrigger>

                                    <div className="prd-title-dtl">
                                       <p><strong>{t('product_research.product_details.store')}: 
                                       <Link to={'/shops/' + product.shop.id + '/details'} target="_blank">
                                             <div className='d-flex gap-1'>
                                                   <p>{product.shop.shop_name}</p>
                                             </div>
                                          </Link>
                                       </strong><small> <span className={product?.country?.country_code.toLowerCase() ? `fi fi-${product?.country?.country_code.toLowerCase()} mr-1` : ''}></span> <i className="fa fa-chevron-right" aria-hidden="true"></i></small></p>
                                       {product?.shop?.category_list && product?.shop?.category_list.length != 0 && <p><strong>{t('product_research.product_details.main_category')}: </strong>
                                          <span className="btn btn-outline-dark btn-sm">{product?.shop?.category_list[0]?.category_name}</span>
                                          {product?.shop?.category_list.length > 1 && <OverlayTrigger
                                             key="right"
                                             trigger="click"
                                             placement="right"
                                             rootClose
                                             overlay={
                                                <Popover className="variants-tooltip">
                                                   <Popover.Body>
                                                      <div className="m-2">
                                                         {product?.shop?.category_list && product.shop.category_list.map((v, i) => {
                                                            if (i != 0) return <Button key={i} variant="outline-dark" size="sm" className="mb-1 mr-1">{v.category_name}</Button>
                                                         })}
                                                      </div>
                                                   </Popover.Body>
                                                </Popover>
                                             }
                                          >
                                             <Button variant="outline-dark" size="sm" className='ml-2'>{product?.shop?.category_list.length > 1 ? ('+' + (product.shop.category_list.length - 1)) : ''} <i className="fa fa-chevron-down" aria-hidden="true"></i></Button>
                                          </OverlayTrigger>}
                                       </p>}
                                    </div>
                                 </div>
                              </div>
                              <div className='col-lg-6'>
                                 <div className='row mt-4'>
                                    <div className='col-md-4'>
                                       <p className='mb-0'><strong>{t('product_research.product_details.sales_volume')}</strong></p>
                                       <h3>{product.sold_count || '-'}</h3>
                                    </div>
                                    <div className='col-md-4'>
                                       <p className='mb-0'><strong>{t('common.views')}</strong></p>
                                       <h3><Conversion value={product.play_count || 0} /></h3>
                                    </div>
                                    <div className='col-md-4'>
                                       <p className='mb-0'><strong>{t('common.likes')}</strong></p>
                                       <h3><Conversion value={product.digg_count || 0} /></h3>
                                    </div>
                                    <div className='col-md-4'>
                                       <p className='mb-0'><strong>{t('common.relevant_videos')}</strong></p>
                                       <h3><Conversion value={product.video_rel_count || 0} /></h3>
                                    </div>
                                    <div className='col-md-4'>
                                       <p className='mb-0'><strong>{t('common.relevant_influencers')}</strong></p>
                                       <h3><Conversion value={product.author_rel_count || 0} /></h3>
                                    </div>
                                    <div className='col-md-4'>
                                       <p className='mb-0'><strong>{t('common.relevant_live')}</strong></p>
                                       <h3><Conversion value={product.live_count || 0} /></h3>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </Card.Body>
               </Card>
               <div className="custom-tab-1">
                  <Tab.Container
                     defaultActiveKey="salesanalysis"
                  >
                     <Nav as="ul" className="nav-tabs mb-0 bg-white">
                        <Nav.Item as="li" key="salesanalysis">
                           <Nav.Link eventKey="salesanalysis" as={Link}
                           >
                              <i className="fa fa-bar-chart" aria-hidden="true"></i> {t('product_research.product_details.sales_analysis')}
                           </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" key="relevantvideos">
                           <Nav.Link eventKey="relevantvideos" as={Link} onClick={() => childRef.current.relevantVideosTab()}
                           >
                              <i className="fa fa-file-video-o" aria-hidden="true"></i> {t('common.relevant_videos')}
                           </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" key="relevantlive">
                           <Nav.Link eventKey="relevantlive" as={Link} onClick={() => childRelevantLiveRef.current.relevantLiveTab()}
                           >
                              <i className="fa fa-video-camera" aria-hidden="true"></i> {t('common.relevant_live')}
                           </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" key="relevantinfluencers">
                           <Nav.Link eventKey="relevantinfluencers" as={Link} onClick={() => relevantInfluencerRef.current.relevantInfluencersTab()}>
                              <i className="fa fa-user" aria-hidden="true"></i> {t('common.relevant_influencers')}
                           </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" key="customerreviews">
                           <Nav.Link eventKey="customerreviews" as={Link} onClick={() => customerReviewsRef.current.customerReviewsTab()}>
                              <i className="fa fa-commenting-o" aria-hidden="true"></i> {t('product_research.product_details.customer_reviews')}
                           </Nav.Link>
                        </Nav.Item>
                     </Nav>
                     <Tab.Content>
                        <Tab.Pane
                           eventKey="salesanalysis"
                           key="salesanalysis"
                        >
                           <SalesAnalysistab product={product} />
                        </Tab.Pane>

                        <Tab.Pane
                           eventKey="relevantvideos"
                           key="relevantvideos"
                        >
                           <RelevantVideostab ref={childRef} product={product} />
                        </Tab.Pane>

                        <Tab.Pane
                           eventKey="relevantlive"
                           key="relevantlive"
                        >
                           <RelevantLivetab ref={childRelevantLiveRef} product={product} />
                        </Tab.Pane>

                        <Tab.Pane
                           eventKey="relevantinfluencers"
                           key="relevantinfluencers"
                        >
                           <RelevantInfluencerstab ref={relevantInfluencerRef} product={product} />
                        </Tab.Pane>
                        <Tab.Pane
                           eventKey="customerreviews"
                           key="customerreviews"
                        >
                           <CustomerReviewstab ref={customerReviewsRef} />
                        </Tab.Pane>
                     </Tab.Content>
                  </Tab.Container>
               </div>
            </>
         }
      </>
   );
};

export default TopsalesDetail;