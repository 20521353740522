import React, { useEffect, useRef, useState, useContext } from 'react';
import Axios from "../../plugins/axios";
import { Tab, Nav, Card, Button, OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Conversion from "../CommonComponents/Conversion";
import { Context } from '../../../MasterLayout';
import BasicInformationTab from './Details/BasicInformationTab';
import RelevantProductsTab from './Details/RelevantProductsTab';
import RelevantInfluencersTab from './Details/RelevantInfluencersTab';
import RelevantVideosTab from './Details/RelevantVideosTab';
import RelevantLiveTab from './Details/RelevantLiveTab';
import { useTranslation } from 'react-i18next';


const ShopDetails = () => {
   const { t } = useTranslation();

   const { dispatch } = useContext(Context);
   const params = useParams();
   const relevantProductsRef = useRef();
   const relevantInfluencersRef = useRef();
   const relevantVideosRef = useRef();
   const relevantLiveRef = useRef();
   const [shop, setShop] = useState(null);
   const [category, setCategory] = useState([]);

   useEffect(() => {
      if (category.length == 0) {
         getCategory();
      }
      shopDetail();
   }, []);

   const getCategory = async () => {
      const response = await Axios.get('/api/categories');

      setCategory(response?.data?.data || []);
   };

   const shopDetail = async () => {
      try {
         let response = await Axios.post('/api/shop/detail', { shop_id: params.shop_id });
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });

         if (response && response.status === 200 && response.data.data) {
            setShop(response.data.data);
         }

      } catch (error) {
         console.error('Error: ' + error.message);
      };
   }
   const markFavourite = async (data) => {
      await Axios.post('/api/favorite-product', data);
      shopDetail();
   };

   return (
      <>
         {shop &&
            <>
               <Card>
                  <Card.Body>
                     <div className='row'>
                        <div className='col-lg-2'>
                           {shop?.shop_logo_privatization && <img src={`https://t-img.picturehaven.net/product/${shop.shop_logo_privatization}?imageMogr2/auto-orient/thumbnail/270x/strip/format/WEBP/quality/75!/ignore-error/1`} alt="" width={300} />}
                        </div>
                        <div className='col-lg-10'>
                           <div className='d-flex align-items-center justify-content-between'>
                              <div className='d-flex align-items-center'>
                                 <h4 className='mb-0 mr-5'>{shop.shop_name}</h4>
                                 <Button variant="outline-dark" size="sm" className="px-2 py-1 mr-3" onClick={() => { markFavourite({ id: shop.id, country_code: shop.sys_country.country_code }); }}><i className={"fa " + (shop?.favorite.length > 0 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i></Button>
                              </div>
                           </div>
                           <div className='row'>
                              <div className='col-lg-6'>
                                 <div className='mb-3'>
                                    <p className='mb-0'><strong>{t('common.country_or_region')}:</strong> <span className={shop?.sys_country?.country_code.toLowerCase() ? `fi fi-${shop.sys_country.country_code.toLowerCase()} mr-1` : ''}></span>{shop?.sys_country?.country} <span>{shop?.free_shipping ? '     Free Shipping' : ''}</span></p>
                                    <p className='mb-0'><strong>{t('shops.shop_attribute')}:</strong> {shop?.local && shop?.overseas ? 'Local Shop / Cross-Border Shop' : shop?.local ? 'Local Shop' : shop?.overseas ? 'Cross-Border Shop' : '-'}</p>
                                    {shop?.category_list && shop?.category_list.length != 0 && <p><strong>{t('shops.main_category')}: </strong>
                                       <span className="btn btn-outline-dark btn-sm m-1">{shop?.category_list[0]?.category_name}</span>
                                       {shop?.category_list[1] && <span className="btn btn-outline-dark btn-sm m-1">{shop?.category_list[1]?.category_name}</span>}
                                       {shop?.category_list.length > 2 && <OverlayTrigger
                                          key="right"
                                          trigger="click"
                                          placement="right"
                                          rootClose
                                          overlay={<Popover className="variants-tooltip">
                                             <Popover.Body>
                                                <div className="m-2">
                                                   {shop?.category_list && shop.category_list.map((v, i) => {
                                                      return (i > 1) ? <Button key={i} variant="outline-dark" size="sm" className="mb-1 mr-1">{v.category_name}</Button> : ''
                                                   })}
                                                </div>
                                             </Popover.Body>
                                          </Popover>}
                                       >
                                          <Button variant="outline-dark" size="sm" className='m-1'>{shop?.category_list.length > 2 ? ('+' + (shop.category_list.length - 2)) : ''} <i className="fa fa-chevron-down" aria-hidden="true"></i></Button>
                                       </OverlayTrigger>}
                                    </p>}
                                 </div>
                              </div>
                              <div className='col-lg-6'>
                                 <div className='row mt-4'>
                                    <div className='col-md-4'>
                                       <p className='mb-0'><strong>{t('shops.total_sales_volume')}</strong></p>
                                       <h3><Conversion value={shop.sold_count || 0} /></h3>
                                    </div>
                                    <div className='col-md-4'>
                                       <p className='mb-0'><strong>{t('common.revenue')}</strong></p>
                                       <h3>{shop.sales_amount_usd || 0}</h3>
                                       <p>({shop.sales_amount_str || 0})</p>
                                    </div>
                                    <div className='col-md-4'>
                                       <p className='mb-0'><strong>{t('shops.total_products')}</strong></p>
                                       <h3><Conversion value={shop.related_products || 0} /></h3>
                                    </div>
                                    <div className='col-md-4'>
                                       <p className='mb-0'><strong>{t('shops.relevant_videos')}</strong></p>
                                       <h3><Conversion value={shop.related_videos || 0} /></h3>
                                    </div>
                                    <div className='col-md-4'>
                                       <p className='mb-0'><strong>{t('shops.relevant_influencers')}</strong></p>
                                       <h3><Conversion value={shop.related_authors || 0} /></h3>
                                    </div>
                                    <div className='col-md-4'>
                                       <p className='mb-0'><strong>{t('shops.relevant_live')}</strong></p>
                                       <h3><Conversion value={shop.related_lives || 0} /></h3>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </Card.Body>
               </Card>
               <div className="custom-tab-1">
                  <Tab.Container defaultActiveKey="basicInformation">
                     <Nav as="ul" className="nav-tabs mb-0 bg-white">
                        <Nav.Item as="li" key="basicInformation">
                           <Nav.Link eventKey="basicInformation" as={Link}>
                              <i className="fa fa-bar-chart" aria-hidden="true"></i> {t('common.basic_information')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" key="relevantProducts">
                           <Nav.Link eventKey="relevantProducts" as={Link} onClick={() => relevantProductsRef.current.relevantProductsTab()}>
                              <i className="fa fa-shopping-bag" aria-hidden="true"></i> {t('common.relevant_products')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" key="relevantInfluencers">
                           <Nav.Link eventKey="relevantInfluencers" as={Link} onClick={() => relevantInfluencersRef.current.relevantInfluencersTab()}>
                              <i className="fa fa-user" aria-hidden="true"></i> {t('common.relevant_influencers')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" key="relevantVideos">
                           <Nav.Link eventKey="relevantVideos" as={Link} onClick={() => relevantVideosRef.current.relevantVideosTab()}>
                              <i className="fa fa-file-video-o" aria-hidden="true"></i> {t('common.relevant_videos')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" key="relevantLive">
                           <Nav.Link eventKey="relevantLive" as={Link} onClick={() => relevantLiveRef.current.relevantLiveTab()}>
                              <i className="fa fa-video-camera" aria-hidden="true"></i> {t('common.relevant_live')}</Nav.Link>
                        </Nav.Item>
                     </Nav>
                     <Tab.Content>
                        <Tab.Pane eventKey="basicInformation" key="basicInformation">
                           <BasicInformationTab shop={shop} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="relevantProducts" key="relevantProducts">
                           <RelevantProductsTab ref={relevantProductsRef} shop={shop} category={category} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="relevantInfluencers" key="relevantInfluencers">
                           <RelevantInfluencersTab ref={relevantInfluencersRef} shop={shop} category={category} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="relevantVideos" key="relevantVideos">
                           <RelevantVideosTab ref={relevantVideosRef} shop={shop} category={category} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="relevantLive" key="relevantLive">
                           <RelevantLiveTab ref={relevantLiveRef} shop={shop} category={category} />
                        </Tab.Pane>
                     </Tab.Content>
                  </Tab.Container>
               </div>
            </>
         }
      </>
   );
};

export default ShopDetails;