import React from 'react'
import { Button, OverlayTrigger, Popover, Table, Tooltip } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function ProductList({ sorting, sortClass, handleSort, productdata, totalCount, handlePageClick, currentPage }) {
    const { t } = useTranslation();

    const tableHead = [
        {
            title: t('common.products'),
        },
        {
            title: t('common.price'),
            className: 'text-left'
        },
        {
            title: '',
        },
        {
            title: t('influencers.commision_rate'),
            className: 'text-right'
        },
        {
            title: t('common.revenue'),
            className: 'text-right sorting-desc-icon',
            sort: 2,
            sort_type: 1
        },
        {
            title: t('common.sales'),
            className: 'text-right sorting-desc-icon',
            sort: 2,
            sort_type: 2
        },
        {
            title: t('influencers.shops'),
        },
        {
            title: t('common.videos'),
            className: 'text-right',
        },
        {
            title: t('influencers.avg_views'),
            className: 'text-right sorting-desc-icon',
            sort: 2,
            sort_type: 3
        },
        {
            title: t('influencers.live'),
            className: 'text-right',
        },

    ];
    const countryicon = Object.keys(global?.config?.region).find(key => global?.config?.region[key] === productdata[0]?.country?.country)

    return (
        <div>
            <div className="card-body p-0">
                <Table responsive className="w-100">
                    <div id="example_wrapper" className="dataTables_wrapper">
                        <table id="example" className="display w-100 dataTable">
                            <thead>
                                <tr role="row">
                                    {tableHead.map((value, index) => (
                                        <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort && value.sort_type == sorting.sort_type ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort, value.sort_type) }} dangerouslySetInnerHTML={{ __html: value.title }}></th>

                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {productdata.length ? (productdata.map((product) => (
                                    <tr>
                                        <td>
                                            <div className='d-flex'>
                                                <div className="prd-img mr-3">
                                                    <OverlayTrigger
                                                        key="right"
                                                        trigger={["hover", "focus"]}
                                                        placement="right"
                                                        rootClose
                                                        overlay={
                                                            <Popover>
                                                                <Popover.Body>
                                                                    {product.images_privatization[0] &&
                                                                        <img alt="" width={500} src={`
                                                                        https://t-img.picturehaven.net/product/${product.images_privatization[0]}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1
                                                                        `} />
                                                                    }
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                    >
                                                        <Button variant="link" className='p-0' size="sm">
                                                        <Link to={'/products/' + product.product_id + '/details'} target="_blank">
                                                            {product.images_privatization[0] &&
                                                                <img alt="" width={70} className="prod-thum-img" src={`https://t-img.picturehaven.net/product/${product.images_privatization[0]}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                            }
                                                            </Link>
                                                        </Button>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className='prd-title'>
                                                    <p>
                                                        <OverlayTrigger overlay={<Tooltip>{product.title}</Tooltip>}>
                                                            <Link to={'/products/' + product?.product_id + '/details'} target="_blank">
                                                                <span>{product.title.length > 40 ? product.title.substring(0, 40) + '...' : product.title}</span>
                                                            </Link>
                                                        </OverlayTrigger>
                                                    </p>
                                                    {/* </Link> */}
                                                    <p><span className={`fi fi-${countryicon.toLocaleLowerCase()} mr-1`}></span>{product?.country?.country}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {product?.usd_price} <br />
                                            <span className='text-muted'>({product?.min_price_str})</span>
                                        </td>
                                        <td className='text-right'>
                                            {product?.sale_prop ?
                                                <div className="d-flex justify-content-between">
                                                    <div className='d-flex p-variants'>
                                                        <div className='v-btn'>
                                                            <OverlayTrigger
                                                                key="right"
                                                                trigger="click"
                                                                placement="right"
                                                                rootClose
                                                                overlay={
                                                                    <Popover id={`popover-positioned-$"right"`} className="variants-tooltip">
                                                                        <Popover.Header as="h3">Variants</Popover.Header>
                                                                        <Popover.Body>
                                                                            <div className="d-flex mb-3">
                                                                                <div className="mr-3">
                                                                                    <img src={"https://t-img.picturehaven.net/product/" + product.images_privatization[0] + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={200} />
                                                                                </div>
                                                                                <div>
                                                                                    <div>
                                                                                        <p className="">{product.title}</p>
                                                                                    </div>
                                                                                    <div className='d-flex'>
                                                                                        <h4 className="text-muted">{product?.usd_price}</h4> &nbsp;
                                                                                        ({product.min_price_str})
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {product.sale_prop.map((variant, index1) => (
                                                                                <div className="mb-3" key={index1}>
                                                                                    <p className="mb-0"><strong>{variant.prop_name}</strong></p>
                                                                                    <div className="variant-box">
                                                                                        {variant.sale_prop_values.map((value, index2) => (
                                                                                            <Button key={index2} variant="outline-dark" size="sm" className="mb-1 mr-1">{value.prop_value}</Button>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <Button variant="outline-primary" size="sm">Variants</Button>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className='v-truck'>
                                                            {product.free_shipping &&
                                                                <OverlayTrigger overlay={<Tooltip>Free Shipping</Tooltip>}>
                                                                    <span><i className="fa fa-truck mt-2 ml-2 text-muted" aria-hidden="true"></i></span>
                                                                </OverlayTrigger>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''}
                                        </td>
                                        <td className='text-right'>{product?.commission_rate_str}</td>
                                        <td className='text-right'>
                                            {product?.estimated_sales_amount_usd_str} <br />
                                            <span className='text-muted'>({product?.estimated_sales_amount_str})</span>
                                        </td>
                                        <td className='text-right'>{product?.estimated_sales_volume_str}</td>
                                        <td className='text-right'>
                                            <div className='d-flex'>
                                                <div className="prd-img mr-3">
                                                    <OverlayTrigger
                                                        key="right"
                                                        trigger={["hover", "focus"]}
                                                        placement="right"
                                                        rootClose
                                                        overlay={
                                                            <Popover>
                                                                <Popover.Body>
                                                                    {product.shop_logo_privatization &&
                                                                        <img alt="" width={500} src={`
                                                                    https://t-img.picturehaven.net/product/${product.shop_logo_privatization}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1
                                                                    `} />
                                                                    }
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                    >
                                                        <Button variant="link" className='p-0' size="sm">
                                                        <Link to={'/shops/' + product.seller_id + '/details'} target="_blank">
                                                            {product.shop_logo_privatization &&
                                                                <img alt="" width={20} className="prod-thum-img" src={`https://t-img.picturehaven.net/product/${product.shop_logo_privatization}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                            }
                                                            </Link>
                                                        </Button>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className="prd-title text-left">
                                                    <Link to={'/shops/' + product.seller_id + '/details'} target="_blank">
                                                        <p className='mb-2'>{product?.shop_name}</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='text-right' style={{ color: 'red' }}>{product?.video_qty_str}</td>
                                        <td className='text-right'>{product?.video_play_count_avg_str}</td>
                                        <td className='text-right'>{product?.live_count}</td>
                                    </tr>
                                ))) : 'NO Data'}
                            </tbody>
                        </table>
                    </div>
                </Table>
                <div className="dataTables_paginate paging_simple_numbers">
                    <ReactPaginate
                        nextLabel="Next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={totalCount}
                        previousLabel="< Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={currentPage}
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
        </div>
    )
}

export default ProductList