import React from 'react';
import LogoImg from "../../../../src/images/logo.svg";
import { useTranslation } from 'react-i18next';
//import LogoImg from "../../../../src/images/logo.svg";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="la-footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-12">
              <div className="single-footer f-about">
                <div className="logo">
                  <a href="/">
                    <img src={LogoImg} alt="Logo" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-12">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="single-footer f-link">
                    <h3><a href="/products-research">{t('footer.products_research')}</a></h3>
                    <ul>
                      <li><a href="/products/top-sales">{t('footer.top_sales')}</a></li>
                      <li><a href="/products/products-search">{t('footer.products')}</a></li>
                      <li><a href="/products/products-videos">{t('footer.product_video')}</a></li>
                      <li><a href="/price-calculator">{t('footer.profitability_calculator')}</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="single-footer f-link">
                    <h3><a href="/influencers">{t('footer.influencers')}</a></h3>
                    <ul>
                      <li><a href="/influencers/top-sales-influencers">{t('footer.top_sales_influencers')}</a></li>
                      <li><a href="/influencers/influencers-search">{t('footer.influencers_search')}</a></li>
                      <li><a href="/influencers-review-videos">{t('footer.content_creation')}</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="single-footer f-link">
                    <h3><a href="/my-tiktok-shop">{t('footer.my_tiktok_analytics')}</a></h3>
                    <ul>
                      <li><a href="/tiktok-store/profit-dashboard">{t('footer.profit_dashboard')}</a></li>
                      <li><a href="/tiktok-store/store-listing">{t('footer.store_manager')}</a></li>
                      <li><a href="/tiktok-store/product-master-list">{t('footer.product_master_list')}</a></li>
                      <li><a href="/tiktok-store/reports">{t('footer.reports')}</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="single-footer f-link">
                    <h3>{t('footer.need_help')}</h3>
                    <ul>
                      <li><a href="/tutorials">{t('footer.tutorials')}</a></li>
                      <li><a href="mailto:support@tikshoptrends.com">{t('footer.contact_support')}</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="container pb-3 small">
        <div className="row">
          <div className="col-lg-8">
            <ul className='d-flex'>
              <li className='pr-3'>
                <a href="/#">
                  <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
                    <circle cx="2.83058" cy="2.83058" r="2.83058" fill="#f82c8f"></circle>
                  </svg>
                  {t('footer.terms_of_services')}
                </a>
              </li>
              <li className='pr-3'>
                <a href="/#">
                  <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
                    <circle cx="2.83058" cy="2.83058" r="2.83058" fill="#f82c8f"></circle>
                  </svg>
                  {t('footer.privacy_policy')}
                </a>
              </li>
              <li>
                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
                  <circle cx="2.83058" cy="2.83058" r="2.83058" fill="#f82c8f"></circle>
                </svg>
                {t('footer.contact_us')}:
                <a href="mailto:support@tikshoptrends.com" className='ml-2' target='_blank' rel="noreferrer">
                  support@tikshoptrends.com
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 text-right">
            © Tikshop Trends 2024
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
