import React, { forwardRef, useEffect, useImperativeHandle, useState, useContext } from 'react'
import { Button, Card, OverlayTrigger, Popover, Table, Tooltip } from 'react-bootstrap';
import Axios from '../../../../plugins/axios';
import { Link, useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Context } from '../../../../../MasterLayout';
import { useTranslation } from 'react-i18next';

const RelevantProductvideo = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { dispatch } = useContext(Context);
    const [onLoad, setOnLoad] = useState(false)
    const [page, setpage] = useState(1)

    useImperativeHandle(ref, () => ({
        async relevantProductVideo() {
            if (onLoad == false) {
            }
            setOnLoad(true);
        }
    }));
    const [relevantVideolist, setRelevantVideolist] = useState([]);
    const params = useParams();

    const relevantVideosAPI = async () => {
        try {
            let bodyParams = {};
            bodyParams.video_id = params.productId;

            let response = await Axios.post('/api/video-product', bodyParams);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.status === 200) {

                const docs = [];
                for (let i = 0; i < 5; i++) {
                    if (response.data.data[i]) {
                        docs.push(response.data.data[i])
                    } else { break }
                };
                setRelevantVideolist(docs);
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        };
    }
    useEffect(() => {
        relevantVideosAPI();
    }, []);

    const handlePageClick = (event) => {
        setpage(event.selected + 1);
        const startIndex = ((event.selected + 1) - 1) * 5;
        const endIndex = Math.min(startIndex + 5, relevantVideolist?.length);
        const docs = [];
        for (let i = startIndex; i < endIndex; i++) {
            if (relevantVideolist[i]) {
                docs.push(relevantVideolist[i])
            } else { break; }
        };
        setRelevantVideolist(docs)
    };

    const countryicon = Object.keys(global?.config?.region).find(key => global?.config?.region[key] === relevantVideolist[0]?.country?.country)


    return (
        <div>
            <Card>
                <Card.Body>
                    <div className="row mb-4">
                        <div className="col-lg-5">
                            <h3>{t('common.relevant_products')}</h3>
                        </div>
                        <div>
                            <div>
                                <div className="card-body">
                                    <Table responsive className="w-100">
                                        <div id="example_wrapper" className="dataTables_wrapper">
                                            <table id="example" className="display w-100 dataTable">
                                                <thead>
                                                    <tr role="row">
                                                        <th>{t('common.products')}</th>
                                                        <th>{t('common.price')}</th>
                                                        <th></th>
                                                        <th>{t('product_research.product_details.commission')}</th>
                                                        <th>{t('product_research.product_details.top_sales')}</th>
                                                        <th>{t('common.sales')}</th>
                                                        <th>{t('common.revenue')}</th>
                                                        <th>{t('product_research.product_details.commission')}</th>
                                                        <th>{t('product_research.product_details.shop')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {relevantVideolist?.map((product) => (
                                                        <tr>
                                                            <td>
                                                                <div className='d-flex'>
                                                                    <div className="prd-img mr-3">
                                                                        <OverlayTrigger
                                                                            key="right"
                                                                            trigger={["hover", "focus"]}
                                                                            placement="right"
                                                                            rootClose
                                                                            overlay={
                                                                                <Popover>
                                                                                    <Popover.Body>
                                                                                        {product?.product_cover_privatization &&
                                                                                            <img alt="" width={500} src={`https://t-img.picturehaven.net/product/${product?.product_cover_privatization}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                                                        }
                                                                                    </Popover.Body>
                                                                                </Popover>
                                                                            }
                                                                        >
                                                                            <Button variant="link" className='p-0' size="sm">
                                                                                <Link to={'/products/' + product?.product_id + '/details'} target="_blank">
                                                                                    {product?.product_cover_privatization &&
                                                                                        <img alt="" width={70} className="prod-thum-img" src={`https://t-img.picturehaven.net/product/${product.product_cover_privatization}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                                                    }
                                                                                </Link>
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <div className='prd-title'>
                                                                        <OverlayTrigger overlay={<Tooltip>{product?.title}</Tooltip>}>
                                                                            <Link to={'/products/' + product?.product_id + '/details'} target="_blank">
                                                                                <span>{product?.title?.length > 40 ? product?.title?.substring(0, 40) + '...' : product?.title}</span>
                                                                            </Link>
                                                                        </OverlayTrigger>
                                                                        <p><span className={`fi fi-${countryicon.toLocaleLowerCase()} mr-1`}></span>{product?.country?.country}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <p>{product?.price_usd}</p>
                                                                    <p>({product?.price})</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {product?.sale_prop ?
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className='d-flex p-variants'>
                                                                            <div className='v-btn'>
                                                                                <OverlayTrigger
                                                                                    key="right"
                                                                                    trigger="click"
                                                                                    placement="right"
                                                                                    rootClose
                                                                                    overlay={
                                                                                        <Popover id={`popover-positioned-$"right"`} className="variants-tooltip">
                                                                                            <Popover.Header as="h3">Variants</Popover.Header>
                                                                                            <Popover.Body>
                                                                                                <div className="d-flex mb-3">
                                                                                                    <div className="mr-3">
                                                                                                        <img alt="" width={500} src={`
                                                                        https://t-img.picturehaven.net/product/${product.product_cover_privatization}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1
                                                                        `} />                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <div>
                                                                                                            <p className="">{product.title}</p>
                                                                                                        </div>
                                                                                                        <div className='d-flex'>
                                                                                                            <h4 className="text-muted">{product?.price_usd}</h4> &nbsp;
                                                                                                            ({product.price})
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {product.sale_prop.map((variant, index1) => (
                                                                                                    <div className="mb-3" key={index1}>
                                                                                                        <p className="mb-0"><strong>{variant.prop_name}</strong></p>
                                                                                                        <div className="variant-box">
                                                                                                            {variant.sale_prop_values.map((value, index2) => (
                                                                                                                <Button key={index2} variant="outline-dark" size="sm" className="mb-1 mr-1">{value.prop_value}</Button>
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </Popover.Body>
                                                                                        </Popover>
                                                                                    }
                                                                                >
                                                                                    <Button variant="outline-primary" size="sm">Variants</Button>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                            <div className='v-truck'>
                                                                                {product.free_shipping &&
                                                                                    <OverlayTrigger overlay={<Tooltip>Free Shipping</Tooltip>}>
                                                                                        <span><i className="fa fa-truck mt-2 ml-2 text-muted" aria-hidden="true"></i></span>
                                                                                    </OverlayTrigger>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : ''}
                                                            </td>
                                                            <td>

                                                                <div>
                                                                    <h4> {product?.earn_amount_usd ? product?.earn_amount_usd : '-'}</h4>
                                                                    <p>({product?.earn_amount ? product?.earn_amount : '-'})</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {product?.sold_count ? product?.sold_count : '--'}
                                                            </td>
                                                            <td>
                                                                {product?.estimated_sales_volume ? product.estimated_sales_volume : '-'}
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <h4>{product?.estimated_sales_amount_usd ? product?.estimated_sales_amount_usd : '-'}</h4>
                                                                    <p>({product?.estimated_sales_amount ? product?.estimated_sales_amount : '-'})</p>
                                                                </div>
                                                            </td>
                                                            <td>

                                                                <div>
                                                                    <h4> {product?.author_earn_amount_usd ? product.author_earn_amount_usd : ''}</h4>
                                                                    <p>({product?.author_earn_amount ? product?.author_earn_amount : '-'})</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='d-flex'>
                                                                    <div className="prd-img mr-3">
                                                                        <OverlayTrigger
                                                                            key="right"
                                                                            trigger={["hover", "focus"]}
                                                                            placement="right"
                                                                            rootClose
                                                                            overlay={
                                                                                <Popover>
                                                                                    <Popover.Body>
                                                                                        {product.shop_logo_privatization &&
                                                                                            <img alt="" width={500} src={`
                                                                    https://t-img.picturehaven.net/product/${product.shop_logo_privatization}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1
                                                                    `} />
                                                                                        }
                                                                                    </Popover.Body>
                                                                                </Popover>
                                                                            }
                                                                        >
                                                                            <Button variant="link" className='p-0' size="sm">
                                                                            <Link to={'/shops/' + product?.seller_id + '/details'} target="_blank">
                                                                                {product.shop_logo_privatization &&
                                                                                    <img alt="" width={20} className="prod-thum-img" src={`https://t-img.picturehaven.net/product/${product.shop_logo_privatization}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                                                }
                                                                                </Link>
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <div className="prd-title text-left">
                                                                        <Link to={'/shops/' + product.seller_id + '/details'} target="_blank">
                                                                            <p className='mb-2'>{product?.shop_name}</p>
                                                                        </Link>
                                                                    </div>
                                                                </div>                                                              
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Table>

                                    <div className="dataTables_paginate paging_simple_numbers">
                                        <ReactPaginate
                                            nextLabel="Next >"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={1}
                                            marginPagesDisplayed={1}
                                            pageCount={relevantVideolist.length / 5}
                                            previousLabel="< Previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            forcePage={page - 1}
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
});

export default RelevantProductvideo