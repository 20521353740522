import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle, useContext } from "react";
import { ButtonGroup, Button, Card } from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Line } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import Axios from "../../../plugins/axios";
import moment from 'moment';
import { Context } from '../../../../MasterLayout';
import { useTranslation } from 'react-i18next';

const SongAlanysisChart = forwardRef((props, ref) => {
   const { t } = useTranslation();
   const dateFilterOption = {
      '7_days': {
         label: 'Past 7 Days',
         start_day: moment().subtract(7, 'days').format('YYYY-MM-DD'),
         end_day: moment().subtract(1, 'days').format('YYYY-MM-DD')
      },
      '15_days': {
         label: 'Past 15 Days',
         start_day: moment().subtract(15, 'days').format('YYYY-MM-DD'),
         end_day: moment().subtract(1, 'days').format('YYYY-MM-DD')
      },
      '30_days': {
         label: 'Past 30 Days',
         start_day: moment().subtract(30, 'days').format('YYYY-MM-DD'),
         end_day: moment().subtract(1, 'days').format('YYYY-MM-DD')
      }
   };
   const { dispatch } = useContext(Context);
   const params = useParams();
   const dateRangeRef = useRef();
   const [data, setData] = useState([]);
   const [songAnalysisFilter, setSongAnalysisFilter] = useState('7_days');
   const [dateRange, setDateRange] = useState(dateFilterOption[songAnalysisFilter]);
   const [dataAnalysisChartType, setDataAnalysisChartType] = useState('views');
   const [customerRating, setCustomerRating] = useState({
      data: {
         labels: [],
         datasets: [
            {
               label: "Views",
               data: [],
               borderWidth: "0",
               borderColor: "rgba(41,43,232,1)",
               backgroundColor: "rgba(41,43,232, 0)",
            },
         ],
      },
      options: {

         plugins: {
            tooltips: {
               enabled: true,
            },
            legend: {
               display: false,
               labels: {
                  usePointStyle: false,
               },
            },
            tooltip: {
               callbacks: {
                  // to change the label context
                  label: function (context) {
                     let text = parseFloat(context.parsed.y);
                     if (text >= 1000000000) {
                        text = parseFloat(text / 1000000000).toFixed(1) + 'B'
                     } else if (text >= 1000000) {
                        text = parseFloat(text / 1000000).toFixed(1) + 'M'
                     } else if (text >= 1000) {
                        text = parseFloat(text / 1000).toFixed(1) + 'K'
                     };

                     return (context.dataset.label + ": " + text);
                  },
               },
            },
         },
         maintainAspectRatio: false,

         scales: {
            y: {
               ticks: {
                  // Include a dollar sign in the ticks
                  callback: function (value, index, ticks) {
                     let text = parseFloat(value);
                     if (text >= 1000000000) {
                        text = parseFloat(text / 1000000000).toFixed(1) + 'B'
                     } else if (text >= 1000000) {
                        text = parseFloat(text / 1000000).toFixed(1) + 'M'
                     } else if (text >= 1000) {
                        text = parseFloat(text / 1000).toFixed(1) + 'K'
                     };
                     return (text);
                  }
               }
            },
         },
      }
   });

   useImperativeHandle(ref, () => ({
      async songAnalysisTab() { songAnalysis(); }
   }));

   useEffect(() => { songAnalysis(); }, [songAnalysisFilter]);

   const songAnalysis = async () => {
      try {
         let bodyParams = {};
         if (songAnalysisFilter === 'custom') bodyParams = { start_day: dateRange.start_day, end_day: dateRange.end_day };
         else bodyParams = dateFilterOption[songAnalysisFilter];
         bodyParams.music_id = params?.musicId;
         changeDate(bodyParams.start_day, bodyParams.end_day);

         let response = await Axios.post('/api/music/analysis', bodyParams);
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });
         if (response && response.status === 200) {
            setData(response?.data?.data);
            changeLineGraphData(response?.data?.data, dataAnalysisChartType)
         };
      } catch (error) { console.error('Error: ' + error.message); }
   };

   const updateDataAnalysisChart = async (value) => {
      if (dataAnalysisChartType != value) {
         setDataAnalysisChartType(value);
         changeLineGraphData(data, value);
      }
   }

   const changeLineGraphData = async (data, dataAnalysisChartType) => {
      let labelsArray = [], dataArray = [];
      data.forEach((v, i) => {
         labelsArray.push(v.date);
         dataArray.push(dataAnalysisChartType == 'views' ? v.play_count : v.video_count);
      });

      setCustomerRating({
         data: {
            defaultFontFamily: "Barlow",
            labels: labelsArray,
            datasets: [
               {
                  data: dataArray,
                  borderColor: "rgba(41,43,232,1)",
                  backgroundColor: "rgba(41,43,232, 0)",
                  borderWidth: 2.5,
                  tension: 0.4,
                  label: dataAnalysisChartType.charAt(0).toUpperCase() + dataAnalysisChartType.slice(1),
               },
            ],
         },
         options: { ...customerRating.options }
      });
      console.error(customerRating);
   };

   const dateFilterOptionHtml = Object.keys(dateFilterOption).map((key) => (
      <Button key={key} variant="outline-dark" size="sm" className={songAnalysisFilter === key ? 'btn-primary' : ''} onClick={() => setSongAnalysisFilter(key)}>{dateFilterOption[key].label}</Button>
   ));

   const handleApply = (event, picker) => {
      picker.element.val(`${picker.startDate.format('YYYY-MM-DD')} - ${picker.endDate.format('YYYY-MM-DD')}`);
      setDateRange({ start_day: picker.startDate.format('YYYY-MM-DD'), end_day: picker.endDate.format('YYYY-MM-DD') });
      setSongAnalysisFilter('custom');
   };

   const handleCancel = (event, picker) => {
      picker.element.val('');
      setDateRange([]);
   };

   const changeDate = (startDate, endDate) => {
      dateRangeRef.current.setStartDate(moment(new Date(startDate)).format('MM/DD/YYYY'));
      dateRangeRef.current.setEndDate(moment(new Date(endDate)).format('MM/DD/YYYY'));
   };

   return (
      <Card className='rounded-0'>
         <Card.Body>
            <div className='row'>
               <div className='col-lg-12 mb-4'>
                  <div className='row align-items-center justify-content-end mb-3'>
                     <div className="col-lg-12">
                        <div className="d-flex justify-content-between align-items-center">
                           <h3>{t('creator_center.songs_ranking.title.song_analysis')}</h3>
                           <div className="d-flex justify-content-center align-items-center">
                              <ButtonGroup className='mr-4'>{dateFilterOptionHtml}</ButtonGroup>
                              <DateRangePicker ref={dateRangeRef} initialSettings={{ startDate: moment(new Date(dateRange.start_day)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_day)).format('MM/DD/YYYY') }} onApply={handleApply} onCancel={handleCancel}>
                                 <input type="text" placeholder={'First Seen'} className="form-control form-control-sm input-daterange-timepicker w-auto" value={`${dateRange.start_day} - ${dateRange.end_day}`} />
                              </DateRangePicker>
                           </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                           <div className="d-flex justify-content-center align-items-center">
                              <Button variant="outline-dark" size="sm" className={dataAnalysisChartType === 'views' ? 'btn-primary' : ''} onClick={() => updateDataAnalysisChart('views')}>Views</Button>
                              <Button variant="outline-dark" size="sm" className={dataAnalysisChartType === 'videos' ? 'btn-primary' : ''} onClick={() => updateDataAnalysisChart('videos')}>Videos</Button>
                           </div>
                        </div>
                     </div>
                  </div>
                  {customerRating?.data?.data?.length !== 0 && <div style={{ "height": "400px" }}><Line data={customerRating.data} options={customerRating.options} /></div>}
               </div>
            </div>
         </Card.Body>
      </Card>
   );
});

export default SongAlanysisChart;
