import Axios from "../../../plugins/axios";
import { React, useState, useEffect, useContext } from 'react';
import { ButtonGroup, Button, Card, Table, Popover, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Bar } from "react-chartjs-2";
import { Link, useParams } from 'react-router-dom';
import ConvertMillisecond from "../../Products/ConvertMillisecond";
import moment from 'moment';
import Conversion from "../../CommonComponents/Conversion";
import { TikTokEmbed } from 'react-social-media-embed';
import { Context } from '../../../../MasterLayout';
import ReactApexChart from "react-apexcharts";
import { useTranslation } from 'react-i18next';


const barOptions = {
   plugins: { tooltips: { enabled: false, }, legend: { display: false, labels: { usePointStyle: false } } },
   maintainAspectRatio: false,
   scales: {
      yAxes: [{ display: false, ticks: { beginAtZero: true, min: 0, } }],
      xAxes: [{ display: false, categoryPercentage: 1, barPercentage: 1, gridLines: { display: false, drawBorder: false } }],
   },
};

const pieChartOptions = {
   chart: { type: 'donut' },
   labels: [],
   responsive: [{ breakpoint: 480, options: { chart: { width: 200 }, legend: { position: 'bottom' } } }],
   plotOptions: { pie: { donut: { labels: { show: true, total: { showAlways: true, show: true } } }, dataLabels: { offset: -5 } } },
};

const BasicInformationTab = ({ shop }) => {
   const params = useParams();
   const { t } = useTranslation();
   const countryCode = shop.sys_country.country_code;
   const { dispatch } = useContext(Context);
   const [onlyOneTimeDataFetch] = useState(false);
   const [productCycle, setProductCycle] = useState('H24');
   const [videoCycle, setVideoCycle] = useState('H24');
   const [dateFilterOption] = useState({
      '7_days': {
         label: 'Past 7 Days',
         start_date: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
         end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
      },
      '15_days': {
         label: 'Past 15 Days',
         start_date: new Date(new Date().setDate(new Date().getDate() - 15)).toISOString().split('T')[0],
         end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
      },
      '30_days': {
         label: 'Past 30 Days',
         start_date: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
         end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
      }
   });
   const [statisticsFilter, setStatisticsFilter] = useState('7_days');
   const [statistics, setStatistics] = useState(null);
   const [statisticsGraph, setStatisticsGraph] = useState(null);
   const [livePromotionGraph, setLivePromotionGraph] = useState(null);
   const [top5CategoriesGraph, setTop5CategoriesGraph] = useState(null);
   const [salesCategoryPropGraph, setSalesCategoryPropGraph] = useState(null);
   const [dateRange, setDateRange] = useState(dateFilterOption[statisticsFilter]);
   const [liveStreamingList, setLiveStreamingList] = useState([]);
   const [productSalesVolumeList, setProductSalesVolumeList] = useState([]);
   const [favouriteProduct, setFavouriteProduct] = useState([]);
   const [newArrivalList, setnewArrivalList] = useState([]);
   const [favouriteNewArrival, setFavouriteNewArrival] = useState([]);
   const [relevantInfluencerList, setRelevantInfluencerList] = useState([]);
   const [favouriteRelevantInfluencer, setFavouriteRelevantInfluencer] = useState([]);
   const [relevantVideoList, setRelevantVideoList] = useState([]);
   const [favouriteRelevantVideo, setFavouriteRelevantVideo] = useState([]);
   const [contentModal, setContentModal] = useState(false);
   const [tikTokEmbedID, setTikTokEmbedID] = useState('');
   const cycleList = {
      'H24': '1 Day',
      'D3': '3 Days',
      'W1': 'Weekly',
   };

   const metricsHead = [
      { title: t('common.date'), className: 'text-center' },
      { title: t('common.no_live_broadcasts'), className: 'text-center' },
      { title: t('common.cumulative_viewers'), className: 'text-center' },
      { title: t('common.average_viewers'), className: 'text-center' },
      { title: t('common.online_viewers_peak'), className: 'text-center' },
   ];

   const liveStreamingHead = [
      { title: '#', className: 'text-center' },
      { title: t('common.live_intro'), className: 'text-center' },
      { title: t('common.cumulative_viewers'), className: 'text-center' },
      { title: t('common.online_viewers_peak'), className: 'text-center' },
      { title: t('common.average_viewers'), className: 'text-center' },
      { title: t('common.listed_products'), className: 'text-center' },
      { title: t('common.sales'), className: 'text-center' },
      { title: t('common.revenue'), className: 'text-center' },
      { title: t('common.sales_conversion_rate'), className: 'text-center' },
   ];

   const productSalesVolumeListHead = [
      { title: '#', className: 'text-center' },
      { title: t('common.products'), className: 'text-rigth', },
      { title: t('common.sales'), className: 'text-center' },
      { title: t('common.favorite'), className: 'text-center' },
   ];

   const newArrivalsHead = [
      { title: '#', className: 'text-center' },
      { title: t('common.products'), className: 'text-rigth', },
      { title: t('common.date_issued'), className: 'text-center' },
      { title: t('common.favorite'), className: 'text-center' },
   ];

   const relevantInfluencerHead = [
      { title: '#', className: 'text-center' },
      { title: t('common.influencers'), className: 'text-rigth', },
      { title: t('common.videos'), className: 'text-center' },
      { title: t('common.favorite'), className: 'text-center' },
   ];

   const relevantVideoHead = [
      { title: '#', className: 'text-center' },
      { title: t('common.videos'), className: 'text-rigth', },
      { title: t('common.views'), className: 'text-center' },
      { title: t('common.favorite'), className: 'text-center' },
   ];

   useEffect(() => {
      if (onlyOneTimeDataFetch === false) {
         getProductRatio();
         getliveStreamingList();
         getVersionProduct(productCycle);
         getVersionVideo(videoCycle);
         getNewArrivalsList();
         getRelevantInfluencerList();
      };
      getStatistics();
   }, [dateRange]);

   const dateFilterOptionHtml = Object.keys(dateFilterOption).map(function (key) {
      return <Button key={key} variant="outline-dark" size="sm" className={(statisticsFilter === key ? 'btn-primary' : '')} onClick={() => { setStatisticsFilter(key); setDateRange(dateFilterOption[key]) }}>{dateFilterOption[key].label}</Button>
   });

   const handleApply = (event, picker) => {
      picker.element.val(picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD'));
      setDateRange({ start_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
      setStatisticsFilter('custom');
   };

   const getProductRatio = async () => {
      try {
         let response = await Axios.post('/api/shop/product-ratio', { shop_id: params.shop_id });
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });

         if (response && response.status === 200 && response.data.data) {
            if (response.data.data.product_category_ratio && response.data.data.product_category_ratio.length > 0) {
               let labels = [], data = [], options = { ...pieChartOptions };

               response.data.data.product_category_ratio.map(function (value, index) {
                  value.ratio = (value.ratio * 100).toFixed(2) + '%'
                  labels[index] = value.category_name + ' ' + value.ratio;
                  data[index] = value.count;
               });
               options.labels = labels;
               setTop5CategoriesGraph({ data: data, options: options });
            };
            if (response.data.data.sold_count_category_ratio && response.data.data.sold_count_category_ratio.length > 0) {
               let labels = [], data = [], options = { ...pieChartOptions };

               response.data.data.sold_count_category_ratio.map(function (value, index) {
                  value.ratio = (value.ratio * 100).toFixed(2) + '%'
                  labels[index] = value.category_name + ' ' + value.ratio;
                  data[index] = value.sold_count;
               });
               options.labels = labels;
               setSalesCategoryPropGraph({ data: data, options: options });
            };
            // setProductRatio(response.data.data);
         };
      } catch (error) {
         console.error(error.message);
      };
   };

   const getStatistics = async () => {
      try {
         let response = await Axios.post('/api/shop/sold-count-trend', {
            shop_id: params.shop_id,
            start_day: dateRange.start_date,
            end_day: dateRange.end_date
         });
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });

         if (response && response.status === 200 && response.data.data) {
            if (response.data.data.cycle_trend && response.data.data.cycle_trend.length > 1) {
               var labels = [], data = [{ label: "Revenue", data: [], borderColor: "#2bc155", backgroundColor: "#0aa2ee" }, { label: "Sales", data: [], borderColor: "rgba(210, 47, 110, 1)", backgroundColor: "#f82c8f" }];

               response.data.data.cycle_trend.map(function (value, index) {
                  labels[index] = value.date;
                  data[0]['data'][index] = value.usd_sold_amount;
                  data[1]['data'][index] = value.sold_count;
               });
               setStatisticsGraph({ labels: labels, datasets: data });
            };
            if (response.data.data.shop_live_video_dtos && response.data.data.shop_live_video_dtos.length > 1) {
               // var labels = [], data = [{ label: "No. of live broadcast", data: [], borderColor: "#2bc155", backgroundColor: "#0aa2ee" }];

               response.data.data.shop_live_video_dtos.map(function (value, index) {
                  labels[index] = value.date;
                  data[0]['data'][index] = value.live_count;
               });
               setLivePromotionGraph({ labels: labels, datasets: data });
            };
            response.data.data.shop_live_video_dtos.reverse();
            setStatistics(response.data.data);
         };
      } catch (error) {
         console.error(error.message);
      };
   };

   const getliveStreamingList = async () => {
      try {
         let response = await Axios.post('/api/shop/related-lives', { shop_id: params.shop_id });
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });

         if (response && response.status === 200 && response.data.data) {
            setLiveStreamingList(response.data.data);
         };
      } catch (error) {
         console.error('Error: ' + error.message);
      };
   };

   const productCycleHtml = Object.keys(cycleList).map(function (key) {
      return <Button key={key} variant={"outline-primary " + (productCycle === key ? 'btn-primary' : '')} size="sm" onClick={() => { setProductCycle(key); getVersionProduct(key); }}>{cycleList[key]}</Button>
   });

   const getVersionProduct = async (cycle) => {
      let response = await Axios.get('/api/version', { params: { cycle: cycle, rank_type: 'PRODUCT_RISING', size: 8 } });
      if (response && response.status === 200) {
         response = await Axios.post('/api/top-sales', {
            seller_id: params.shop_id, version: response.data.data[0].version, sort: 10, size: 8, page: 0, sort_type: 'DESC', cycle: cycle, country_code: countryCode
         }).catch(e => console.error('Axios-Error: ' + e.message));

         dispatch({ type: 'subscription_expired', response: response.data.statusCode });

         if (response && response.status === 200 && response.data.data) {
            let array = response.data.favorite ? response.data.favorite.map((value, index) => value.product_id) : favouriteProduct;
            setProductSalesVolumeList(response.data.data);
            setFavouriteProduct(array);
         };
      };
   };

   const markFavouriteProduct = async (data) => {
      await Axios.post('/api/favorite-product', data);
      const index = favouriteProduct.indexOf(data.id);
      if (index > -1) {
         favouriteProduct.splice(index, 1);
      } else {
         favouriteProduct.push(data.id);
      };

      setFavouriteProduct(favouriteProduct.concat([]));
   };

   const getNewArrivalsList = async () => {
      try {
         let response = await Axios.post('/api/product-search', { seller_id: params.shop_id, country_code: countryCode, sort: 42, cursor: 0, sort_type: 'DESC' });
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });

         if (response && response.status === 200 && response.data.data) {
            let array = response.data.favorite ? response.data.favorite.map((value, index) => value.product_id) : favouriteNewArrival;
            let data = response.data.data.slice(0, 8);
            setnewArrivalList(data);
            setFavouriteNewArrival(array);
         };
      } catch (error) {
         console.error('Error: ' + error.message);
      };
   };

   const markFavouriteNewArrival = async (data) => {
      await Axios.post('/api/favorite-product', data);
      const index = favouriteNewArrival.indexOf(data.id);
      if (index > -1) {
         favouriteNewArrival.splice(index, 1);
      } else {
         favouriteNewArrival.push(data.id);
      };

      setFavouriteProduct(favouriteNewArrival.concat([]));
   };

   const getRelevantInfluencerList = async () => {
      try {
         let response = await Axios.post('/api/shop/relevant-influencer-list', { seller_id: params.shop_id });
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });

         if (response && response.status === 200 && response.data.data) {
            let array = await response.data.favorite ? response.data.favorite.map((value, index) => value.influencer_id) : favouriteRelevantInfluencer;
            let data = response.data.data.slice(0, 8);
            setRelevantInfluencerList(data);
            setFavouriteRelevantInfluencer(array);
         };
      } catch (error) {
         console.error('Error: ' + error.message);
      };
   };

   const markFavouriteRelevantInfluencer = async (data) => {
      await Axios.post('/api/favorite-influencer', data);
      const index = favouriteRelevantInfluencer.indexOf(data.influencer_id);
      if (index > -1) {
         favouriteRelevantInfluencer.splice(index, 1);
      } else {
         favouriteRelevantInfluencer.push(data.influencer_id);
      };
      setFavouriteRelevantInfluencer(favouriteRelevantInfluencer.concat([]));
   };

   const videoCycleHtml = Object.keys(cycleList).map(function (key) {
      return <Button key={key} variant={"outline-primary " + (videoCycle === key ? 'btn-primary' : '')} size="sm" onClick={() => { setVideoCycle(key); getVersionVideo(key); }}>{cycleList[key]}</Button>
   });

   const getVersionVideo = async (cycle) => {
      let response = await Axios.get('/api/version', { params: { cycle: cycle, rank_type: 'PRODUCT_VIDEO_RISING_RANK', size: 8 } });
      if (response && response.status === 200) {
         response = await Axios.post('/api/product-video-breakout', { seller_id: params.shop_id, version: response.data.data[0].version, sort: 27, size: 8, page: 0, sort_type: 'DESC', cycle: cycle, country_code: countryCode }).catch(e => console.error('Axios-Error: ' + e.message));

         dispatch({ type: 'subscription_expired', response: response.data.statusCode });

         if (response && response.status === 200 && response.data.data) {
            let array = response.data.favorite ? response.data.favorite.map((value, index) => value.video_id) : favouriteRelevantVideo;
            setRelevantVideoList(response.data.data);
            setFavouriteRelevantVideo(array);
         };
      };
   };

   const markFavouriteRelevantVideo = async (data) => {
      await Axios.post('/api/favorite-product-video', data);
      const index = favouriteRelevantVideo.indexOf(data.id);
      if (index > -1) {
         favouriteRelevantVideo.splice(index, 1);
      } else {
         favouriteRelevantVideo.push(data.id);
      };

      setFavouriteRelevantVideo(favouriteRelevantVideo.concat([]));
   };


   return (
      <>
         {statistics &&
            <Card className='rounded-0'>
               <Card.Body>
                  <div className="row mb-4">
                     <div className="col-lg-5">
                        <h3>{t('common.statistics')}</h3>
                     </div>
                     <div className="col-lg-7 text-right">
                        <div className='d-flex justify-content-end align-items-center'>
                           <ButtonGroup className='mr-4'> {dateFilterOptionHtml}</ButtonGroup>
                           <DateRangePicker onApply={handleApply}
                              initialSettings={{ startDate: moment(new Date(dateRange.start_date)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_date)).format('MM/DD/YYYY') }}>
                              <input type="text" placeholder={'First Seen'} className="form-control form-control-sm input-daterange-timepicker w-auto" value={dateRange.start_date + " - " + dateRange.end_date} />
                           </DateRangePicker>
                        </div>
                     </div>
                  </div>
                  <div className='d-flex mb-4'>
                     <Card className="bg-light mr-3">
                        <Card.Body className=" mb-0">
                           <Card.Text>
                              <p className='mb-0'><strong>{t('common.sales')}</strong></p>
                              <h3><Conversion value={statistics.sold_count_incr || 0} /></h3>
                           </Card.Text>
                        </Card.Body>
                     </Card>
                     <Card className="bg-light mr-3">
                        <Card.Body className=" mb-0">
                           <Card.Text>
                              <p className='mb-0'><strong>{t('common.revenue')}</strong></p>
                              <h3><Conversion value={statistics.sold_amount_incr || 0} /><small>({statistics.sold_amount_incr_usd || 0})</small></h3>
                           </Card.Text>
                        </Card.Body>
                     </Card>
                     <Card className="bg-light mr-3">
                        <Card.Body className=" mb-0">
                           <Card.Text>
                              <p className='mb-0'><strong>{t('common.relevant_videos')}</strong></p>
                              <h3><Conversion value={statistics.video_count_incr || 0} /></h3>
                           </Card.Text>
                        </Card.Body>
                     </Card>
                     <Card className="bg-light mr-3">
                        <Card.Body className=" mb-0">
                           <Card.Text>
                              <p className='mb-0'><strong>{t('common.relevant_products')}</strong></p>
                              <h3><Conversion value={statistics.product_count_incr || 0} /></h3>
                           </Card.Text>
                        </Card.Body>
                     </Card>
                     <Card className="bg-light mr-3">
                        <Card.Body className=" mb-0">
                           <Card.Text>
                              <p className='mb-0'><strong>{t('common.relevant_influencers')}</strong></p>
                              <h3><Conversion value={statistics.author_count_incr || 0} /></h3>
                           </Card.Text>
                        </Card.Body>
                     </Card>
                     <Card className="bg-light mr-3">
                        <Card.Body className=" mb-0">
                           <Card.Text>
                              <p className='mb-0'><strong>{t('common.relevant_live')}</strong></p>
                              <h3><Conversion value={statistics.related_live_count || 0} /></h3>
                           </Card.Text>
                        </Card.Body>
                     </Card>
                  </div>
                  <div className="row mb-4">
                     <div className="col-lg-12">
                        <div style={{ "height": "400px" }}>
                           {statisticsGraph && <Bar data={statisticsGraph} options={barOptions} />}
                        </div>
                     </div>
                  </div>
               </Card.Body>
            </Card>}
         {statistics &&
            <Card className='rounded-0'>
               <Card.Body>
                  <div className="row mb-4">
                     <div className="col-lg-5">
                        <h3>{t('common.live_promotion_trend')}</h3>
                     </div>
                     <div className="col-lg-7 text-right">
                        <div className='d-flex justify-content-end align-items-center'>
                           <ButtonGroup className='mr-4'>
                              {dateFilterOptionHtml}
                           </ButtonGroup>
                           <DateRangePicker onApply={handleApply}
                              initialSettings={{ startDate: moment(new Date(dateRange.start_date)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_date)).format('MM/DD/YYYY') }}>
                              <input type="text" placeholder={'First Seen'} className="form-control form-control-sm input-daterange-timepicker w-auto" value={dateRange.start_date + " - " + dateRange.end_date} />
                           </DateRangePicker>
                        </div>
                     </div>
                  </div>
                  <div className="row mb-4">
                     <div className="col-lg-12">
                        <div style={{ "height": "400px" }}>
                           {livePromotionGraph && <Bar data={livePromotionGraph} options={barOptions} />}
                        </div>
                     </div>
                  </div>
                  <div className="row mb-4">
                     <div className="col-lg-12">
                        <div className="fh-table">
                           <Table responsive className="w-100">
                              <div id="example_wrapper" className="dataTables_wrapper">
                                 <table id="example" className="display w-100 dataTable">
                                    <thead>
                                       <tr role="row" className='bg-light'>
                                          {metricsHead.map((v, i) => (<th key={i} className={v.className}>{v.title}</th>))}
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {statistics.shop_live_video_dtos.map((value, index) => (
                                          <tr key={index}>
                                             <td className="text-center">{value.date}</td>
                                             <td className="text-center"><Conversion value={value.live_count || 0} /></td>
                                             <td className="text-center"><Conversion value={value.total_user_count || 0} /></td>
                                             <td className="text-center"><Conversion value={value.avg_user_count || 0} /></td>
                                             <td className="text-center"><Conversion value={value.max_user_count || 0} /></td>
                                          </tr>
                                       ))}
                                    </tbody>
                                 </table>
                              </div>
                           </Table>
                        </div>
                     </div>
                  </div>
               </Card.Body>
            </Card>}
         <Card className='rounded-0'>
            <Card.Body>
               <div className="row mb-4">
                  <div className="col-lg-5">
                     <h3>{t('common.product_categories_distribution')}</h3>
                  </div>
               </div>
               <div className='row'>
                  <div className="col-lg-6 mb-4">
                     <div id="chart" className="text-right m-auto">
                        <div id="chart" className="text-right m-auto" style={{ width: "80%" }}>
                           <h5>{t('common.piechart_1')}</h5>
                           <p>{t('common.piechart_test_1')}</p>
                           {top5CategoriesGraph && <ReactApexChart options={top5CategoriesGraph.options} series={top5CategoriesGraph.data} type="donut" height={350} />}
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                     <div id="chart" className="text-right m-auto">
                        <div id="chart" className="text-right m-auto" style={{ width: "80%" }}>
                           <h5>{t('common.piechart_2')}</h5>
                           <p>{t('common.piechart_test_2')}</p>
                           {salesCategoryPropGraph && <ReactApexChart options={salesCategoryPropGraph.options} series={salesCategoryPropGraph.data} type="donut" height={350} />}
                        </div>
                     </div>
                  </div>
               </div>
            </Card.Body>
         </Card>
         <Card className='rounded-0'>
            <Card.Body>
               <div className="row mb-4">
                  <div className="col-lg-12 mb-2">
                     <h3>{t('product_research.product_details.live_streaming_list')}</h3>
                  </div>
                  <div className="col-lg-12">
                     <div className="fh-table">
                        <Table responsive className="w-100">
                           <div id="example_wrapper" className="dataTables_wrapper">
                              <table id="example" className="display w-100 dataTable">
                                 <thead>
                                    <tr role="row" className='bg-light'>
                                       {liveStreamingHead.map((value, index) => (<th key={index} className={value.className}>{value.title}</th>))}
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {liveStreamingList.map((value, index) => (
                                       <tr key={index}>
                                          <td className="text-right">{index + 1}</td>
                                          <td>
                                             <div className="d-flex">
                                                <div className="prd-img mr-3">
                                                   <OverlayTrigger
                                                      key="right"
                                                      trigger={["hover", "focus"]}
                                                      placement="right"
                                                      rootClose
                                                      overlay={<Popover>
                                                         <Popover.Body>
                                                            {value.room_cover && <img src={'https://t-img.picturehaven.net/product/' + value.room_cover + '?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={500} />}
                                                         </Popover.Body>
                                                      </Popover>}
                                                   >
                                                      <Button variant="link" className='p-0' size="sm">
                                                         {value.room_cover && <img src={'https://t-img.picturehaven.net/product/' + value.room_cover + '?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={80} />}
                                                      </Button>
                                                   </OverlayTrigger>
                                                </div>
                                                <div className="prd-title">
                                                   <p className='mb-2'>
                                                      <OverlayTrigger overlay={<Tooltip>{value.title}</Tooltip>}>
                                                         <span>{value.title.length > 40 ? value.title.substring(0, 40) + '...' : value.title}</span>
                                                      </OverlayTrigger>
                                                   </p>
                                                   <p className="mb-2">
                                                      {t('product_research.product_details.duration')}: <ConvertMillisecond ms={value.live_time} />
                                                   </p>
                                                   <div className="d-flex align-items-center">
                                                      <div className="mr-2">
                                                         <OverlayTrigger
                                                            key="right"
                                                            trigger={["hover", "focus"]}
                                                            placement="right"
                                                            rootClose
                                                            overlay={<Popover>
                                                               <Popover.Body>
                                                                  {value.room_cover && <img src={'https://t-img.picturehaven.net/product/' + value.room_cover + '?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={500} />}
                                                               </Popover.Body>
                                                            </Popover>}
                                                         >
                                                            <Button variant="link" className='p-0' size="sm">
                                                               {value.room_cover && <img src={'https://t-img.picturehaven.net/product/' + value.room_cover + '?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={25} />}
                                                            </Button>
                                                         </OverlayTrigger>
                                                      </div>
                                                      <div>
                                                         {value.nickname}
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </td>
                                          <td className="text-center"><Conversion value={value.total_user || 0} /></td>
                                          <td className="text-center"><Conversion value={value.max_user_count || 0} /></td>
                                          <td className="text-center"><Conversion value={value.avg_user_count || 0} /></td>
                                          <td className="text-center"><Conversion value={value.product_count || 0} /></td>
                                          <td className="text-center"><Conversion value={value.sold_count || 0} /></td>
                                          <td className="text-center">
                                             {value.total_sales_usd || '-'}<br />
                                             <span className="text-muted">({value.total_sales_str || '-'})</span>
                                          </td>
                                          <td className="text-center">{value.sales_conversion_rate + ' %' || '-'}</td>
                                       </tr>
                                    ))}
                                 </tbody>
                              </table>
                           </div>
                        </Table>
                     </div>
                  </div>
               </div>
            </Card.Body>
         </Card>
         <div className='row'>
            <div className='col-lg-6'>
               <Card className='rounded-0'>
                  <Card.Body>
                     <div className="row mb-4">
                        <div className="col-lg-5">
                           <h3>{t('common.product_sales_volume_list')}</h3>
                        </div>
                        <div className="col-lg-7 text-right">
                           <div className='d-flex justify-content-end align-items-center'>
                              <ButtonGroup>
                                 {productCycleHtml}
                              </ButtonGroup>
                           </div>
                        </div>
                        <div className="col-lg-12">
                           <div className="fh-table">
                              <Table responsive className="w-100 mt-3">
                                 <div id="example_wrapper" className="dataTables_wrapper">
                                    <table id="example" className="display w-100 dataTable">
                                       <thead>
                                          <tr role="row" className='bg-light'>
                                             {productSalesVolumeListHead.map((value, index) => (<th key={index} className={value.className}>{value.title}</th>))}
                                          </tr>
                                       </thead>
                                       <tbody>
                                          {productSalesVolumeList.map((value, index) => (
                                             <tr key={index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td>
                                                   <div className="d-flex">
                                                      <div className="prd-img mr-3">
                                                         <OverlayTrigger
                                                            key="right"
                                                            trigger={["hover", "focus"]}
                                                            placement="right"
                                                            rootClose
                                                            overlay={<Popover>
                                                               <Popover.Body>
                                                                  {value.product_cover_privatization && <img src={'https://t-img.picturehaven.net/product/' + value.product_cover_privatization + '?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={500} />}
                                                               </Popover.Body>
                                                            </Popover>}
                                                         >
                                                            <Button variant="link" className='p-0' size="sm">
                                                               <Link to={'/products/' + value.id + '/details'} target="_blank">
                                                                  {value.product_cover_privatization && <img src={'https://t-img.picturehaven.net/product/' + value.product_cover_privatization + '?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={80} />}
                                                               </Link>
                                                            </Button>
                                                         </OverlayTrigger>
                                                      </div>
                                                      <div className="prd-title">
                                                         <p className='mb-2'>
                                                            <OverlayTrigger overlay={<Tooltip>{value.title}</Tooltip>}>
                                                               <Link to={'/products/' + value.id + '/details'} target="_blank">
                                                                  <span>{value.title.length > 30 ? value.title.substring(0, 30) + '...' : value.title}</span>
                                                               </Link>
                                                            </OverlayTrigger></p>
                                                         {value?.category_list && value?.category_list.length !== 0 && <div className="mb-1">
                                                            <span className="btn btn-outline-dark btn-sm px-2 py-1 mr-1 mb-1">{value?.category_list[0]?.category_name}</span> <br />
                                                            {value?.category_list.length > 1 && <OverlayTrigger
                                                               key="right"
                                                               trigger="click"
                                                               placement="bottom"
                                                               rootClose
                                                               overlay={<Popover className="variants-tooltip">
                                                                  <Popover.Body>
                                                                     <div className="m-2">
                                                                        {value.category_list && value.category_list.map((v, i) => {
                                                                           if (i !== 0) return <span key={i} className="btn btn-outline-dark btn-sm px-2 py-1  mr-1">{v.category_name}</span>
                                                                        })}
                                                                     </div>
                                                                  </Popover.Body>
                                                               </Popover>}
                                                            >
                                                               <Button variant="outline-dark" size="sm" className='px-2 py-1'>{value.category_list.length > 1 ? ('+' + (value.category_list.length - 1)) : ''}  <i className="fa fa-chevron-down" aria-hidden="true"></i></Button>
                                                            </OverlayTrigger>}
                                                         </div>}
                                                      </div>
                                                   </div>
                                                </td>
                                                <td className="text-center">{value.sold_count_incr || '-'}</td>
                                                <td className="text-center">
                                                   <Button variant="outline-dark" size="sm" className="px-2 py-1" onClick={() => { markFavouriteProduct({ id: value.id, country_code: countryCode }); }}> <i className={"fa " + (favouriteProduct.indexOf(value.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                                   </Button>
                                                </td>
                                             </tr>
                                          ))}
                                          {productSalesVolumeList.length === 0 && <tr><td colSpan="4" className="text-right">{t('common.no_data_found')}</td></tr>}
                                       </tbody>
                                    </table>
                                 </div>
                              </Table>
                           </div>
                        </div>
                     </div>
                  </Card.Body>
               </Card>
            </div>
            <div className='col-lg-6'>
               <Card className='rounded-0'>
                  <Card.Body>
                     <div className="row mb-4">
                        <div className="col-lg-5">
                           <h3>{t('common.new_arrivals')}</h3>
                        </div>
                        <div className="col-lg-12">
                           <div className="fh-table">
                              <Table responsive className="w-100 mt-3">
                                 <div id="example_wrapper" className="dataTables_wrapper">
                                    <table id="example" className="display w-100 dataTable">
                                       <thead>
                                          <tr role="row" className='bg-light'>
                                             {newArrivalsHead.map((value, index) => (<th key={index} className={value.className}>{value.title}</th>))}
                                          </tr>
                                       </thead>
                                       <tbody>
                                          {newArrivalList.map((value, index) => (
                                             <tr key={index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td>
                                                   <div className="d-flex">
                                                      <div className="prd-img mr-3">
                                                         <OverlayTrigger
                                                            key="right"
                                                            trigger={["hover", "focus"]}
                                                            placement="right"
                                                            rootClose
                                                            overlay={<Popover>
                                                               <Popover.Body>
                                                                  {value.images_privatization && value.images_privatization.length > 0 && <img src={'https://t-img.picturehaven.net/product/' + value.images_privatization[0] + '?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={500} />}
                                                               </Popover.Body>
                                                            </Popover>}
                                                         >
                                                            <Button variant="link" className='p-0' size="sm">
                                                               <Link to={'/products/' + value.id + '/details'} target="_blank">
                                                                  {value.images_privatization && value.images_privatization.length > 0 && <img src={'https://t-img.picturehaven.net/product/' + value.images_privatization[0] + '?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={80} />}
                                                               </Link>
                                                            </Button>
                                                         </OverlayTrigger>
                                                      </div>
                                                      <div className="prd-title">
                                                         <p className='mb-2'>
                                                            <OverlayTrigger overlay={<Tooltip>{value.title}</Tooltip>}>
                                                               <Link to={'/products/' + value.id + '/details'} target="_blank">
                                                                  <span>{value.title.length > 30 ? value.title.substring(0, 30) + '...' : value.title}</span>
                                                               </Link>
                                                            </OverlayTrigger></p>
                                                      </div>
                                                   </div>
                                                </td>
                                                <td className="text-center">{value.first_time ? new Date(value.first_time).toISOString().split('.')[0] : '-'}</td>
                                                <td className="text-center">
                                                   <Button variant="outline-dark" size="sm" className="px-2 py-1" onClick={() => { markFavouriteNewArrival({ id: value.id, country_code: countryCode }); }}> <i className={"fa " + (favouriteNewArrival.indexOf(value.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                                   </Button>
                                                </td>
                                             </tr>
                                          ))}
                                          {productSalesVolumeList.length === 0 && <tr><td colSpan="4" className="text-center">{t('common.no_data_found')}</td></tr>}
                                       </tbody>
                                    </table>
                                 </div>
                              </Table>
                           </div>
                        </div>
                     </div>
                  </Card.Body>
               </Card>
            </div>
         </div>
         <div className='row'>
            <div className='col-lg-6'>
               <Card className='rounded-0'>
                  <Card.Body>
                     <div className="row mb-4">
                        <div className="col-lg-5">
                           <h3>{t('common.relevant_influencer_list')}</h3>
                        </div>
                        <div className="col-lg-12">
                           <div className="fh-table">
                              <Table responsive className="w-100 mt-3">
                                 <div id="example_wrapper" className="dataTables_wrapper">
                                    <table id="example" className="display w-100 dataTable">
                                       <thead>
                                          <tr role="row" className='bg-light'>
                                             {relevantInfluencerHead.map((value, index) => (<th key={index} className={value.className}>{value.title}</th>))}
                                          </tr>
                                       </thead>
                                       <tbody>
                                          {relevantInfluencerList.map((value, index) => (
                                             <tr key={index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td>
                                                   <div className="d-flex">
                                                      <div className="prd-img mr-3">
                                                         <OverlayTrigger
                                                            key="right"
                                                            trigger={["hover", "focus"]}
                                                            placement="right"
                                                            rootClose
                                                            overlay={<Popover>
                                                               <Popover.Body>
                                                                  {value.avatar_larger_privatization && <img src={'https://t-img.picturehaven.net/tikmeta/' + value.avatar_larger_privatization + '?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={500} />}
                                                               </Popover.Body>
                                                            </Popover>}
                                                         >
                                                            <Button variant="link" className='p-0' size="sm">
                                                               <Link to={'/influencers/top-sales-influencers/video-details/' + value.author_id + '/video-creators'} target="_blank">
                                                                  {value.avatar_larger_privatization && <img src={'https://t-img.picturehaven.net/tikmeta/' + value.avatar_larger_privatization + '?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={80} />}
                                                               </Link>
                                                            </Button>
                                                         </OverlayTrigger>
                                                      </div>
                                                      <div className="prd-title">
                                                         <p className='mb-2'>
                                                            <OverlayTrigger overlay={<Tooltip>{value.nickname}</Tooltip>}>
                                                               <Link to={'/influencers/top-sales-influencers/video-details/' + value.author_id + '/video-creators'} target="_blank">
                                                                  <span>{value.nickname}</span>
                                                               </Link>
                                                            </OverlayTrigger></p>
                                                         {value?.category_list && value?.category_list.length !== 0 && <div className="mb-1">
                                                            <span className="btn btn-outline-dark btn-sm px-2 py-1 mr-1 mb-1">{value?.category_list[0]?.category_name}</span> <br />
                                                            {value?.category_list.length > 1 && <OverlayTrigger
                                                               key="right"
                                                               trigger="click"
                                                               placement="bottom"
                                                               rootClose
                                                               overlay={<Popover className="variants-tooltip">
                                                                  <Popover.Body>
                                                                     <div className="m-2">
                                                                        {value.category_list && value.category_list.map((v, i) => {
                                                                           if (i !== 0) return <span key={i} className="btn btn-outline-dark btn-sm px-2 py-1  mr-1">{v.category_name}</span>
                                                                        })}
                                                                     </div>
                                                                  </Popover.Body>
                                                               </Popover>}
                                                            >
                                                               <Button variant="outline-dark" size="sm" className='px-2 py-1'>{value.category_list.length > 1 ? ('+' + (value.category_list.length - 1)) : ''}  <i className="fa fa-chevron-down" aria-hidden="true"></i></Button>
                                                            </OverlayTrigger>}
                                                         </div>}
                                                      </div>
                                                   </div>
                                                </td>
                                                <td className="text-center">{value.video_count || '-'}</td>
                                                <td className="text-center">
                                                   <Button variant="outline-dark" size="sm" className="px-2 py-1 mr-1" onClick={() => { markFavouriteRelevantInfluencer({ influencer_id: value.author_id, nickname: value?.nickname }); }}>
                                                      <i className={"fa " + (favouriteRelevantInfluencer.indexOf(value.author_id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                                   </Button>
                                                </td>
                                             </tr>
                                          ))}
                                          {productSalesVolumeList.length === 0 && <tr><td colSpan="4" className="text-center">{t('common.no_data_found')}</td></tr>}
                                       </tbody>
                                    </table>
                                 </div>
                              </Table>
                           </div>
                        </div>
                     </div>
                  </Card.Body>
               </Card>
            </div>
            <div className='col-lg-6'>
               <Card className='rounded-0'>
                  <Card.Body>
                     <div className="row mb-4">
                        <div className="col-lg-5">
                           <h3>{t('common.relevant_video_list')}</h3>
                        </div>
                        <div className="col-lg-7 text-right">
                           <div className='d-flex justify-content-end align-items-center'>
                              <ButtonGroup>
                                 {videoCycleHtml}
                              </ButtonGroup>
                           </div>
                        </div>
                        <div className="col-lg-12">
                           <div className="fh-table">
                              <Table responsive className="w-100 mt-3">
                                 <div id="example_wrapper" className="dataTables_wrapper">
                                    <table id="example" className="display w-100 dataTable">
                                       <thead>
                                          <tr role="row" className='bg-light'>
                                             {relevantVideoHead.map((value, index) => (<th key={index} className={value.className}>{value.title}</th>))}
                                          </tr>
                                       </thead>
                                       <tbody>
                                          {relevantVideoList.map((value, index) => (
                                             <tr key={index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td>
                                                   <div className="d-flex">
                                                      <div className="prd-img mr-3">
                                                         <Link to={'/products/' + value.id + '/video-details'} target="_blank">
                                                            {value.origin_cover_privatization && <img src={'https://t-img.picturehaven.net/tikmeta/' + value.origin_cover_privatization + '?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={80} />}
                                                         </Link>
                                                         <Button variant="primary" className="p-0 video-popup-btn" onClick={() => { setTikTokEmbedID(value.id); setContentModal(!contentModal); }}>
                                                            <i className="fa fa-play-circle" aria-hidden="true"></i>
                                                         </Button>
                                                      </div>
                                                      <div className="prd-title">
                                                         <p className='mb-2'>
                                                            <OverlayTrigger overlay={<Tooltip>{value.desc}</Tooltip>}>
                                                               <Link to={'/products/' + value.id + '/video-details'} target="_blank">
                                                                  <span>{value.desc.length > 30 ? value.desc.substring(0, 30) + '...' : value.desc}</span>
                                                               </Link>
                                                            </OverlayTrigger>
                                                         </p>
                                                         <div className="d-flex align-items-center">
                                                            <div className="mr-2">
                                                               <OverlayTrigger
                                                                  key="right"
                                                                  trigger={["hover", "focus"]}
                                                                  placement="right"
                                                                  rootClose
                                                                  overlay={<Popover>
                                                                     <Popover.Body>
                                                                        {value.avatar_larger_privatization && <img src={'https://t-img.picturehaven.net/tikmeta/' + value.avatar_larger_privatization + '?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={500} />}
                                                                     </Popover.Body>
                                                                  </Popover>}
                                                               >
                                                                  <Button variant="link" className='p-0' size="sm">
                                                                     {value.avatar_larger_privatization && <img src={'https://t-img.picturehaven.net/tikmeta/' + value.avatar_larger_privatization + '?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={25} />}
                                                                  </Button>
                                                               </OverlayTrigger>
                                                            </div>
                                                            <div>

                                                               <Link to={'/influencers/top-sales-influencers/video-details/' + value.author_id + '/video-creators'} target="_blank">{value.author_nickname || '-'}</Link>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </td>
                                                <td className="text-center"><Conversion value={value.play_count || 0} /></td>
                                                <td className="text-center">
                                                   <Button variant="outline-dark" size="sm" className="px-2 py-1 mr-1" onClick={() => { markFavouriteRelevantVideo({ id: value.id, country_code: countryCode }); }}>
                                                      <i className={"fa " + (favouriteRelevantVideo.indexOf(value.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                                   </Button>
                                                </td>
                                             </tr>
                                          ))}
                                          {productSalesVolumeList.length === 0 && <tr><td colSpan="4" className="text-center">{t('common.no_data_found')}</td></tr>}
                                       </tbody>
                                    </table>
                                 </div>
                              </Table>
                           </div>
                        </div>
                     </div>
                  </Card.Body>
               </Card>
            </div>
         </div>
         <Modal className="fade video-popup" show={contentModal} >
            <Modal.Body className='p-0'>
               <div className='d-flex'>
                  <div className='text-right'>
                     <TikTokEmbed url={`https://www.tiktok.com/embed/${tikTokEmbedID}`} width={325} />
                  </div>
                  <div className='px-3'>
                     <div className='d-flex flex-column'>
                        <Button
                           variant="dark"
                           className="mb-3"
                           onClick={() => { setTikTokEmbedID(''); setContentModal(!contentModal); }}
                        >
                           <i className="fa fa-times" aria-hidden="true"></i>
                        </Button>
                        {/* <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-line-chart" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-download" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-file-code-o" aria-hidden="true"></i>
                        </Button> */}
                     </div>
                  </div>
               </div>
            </Modal.Body>
         </ Modal>
      </>
   );
};

export default BasicInformationTab;