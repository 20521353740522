import moment from 'moment'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState, useContext } from 'react'
import { Button, ButtonGroup, Card } from 'react-bootstrap'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { useParams } from 'react-router-dom'
import Axios from '../../../../plugins/axios'
import SalesMatricsTabProdVideo from './SalesMatricsTabProdVideo'
import Conversion from '../../../CommonComponents/Conversion'
import VideoAnalysisChart from "./VideoAnalysisChart";
import { Context } from '../../../../../MasterLayout';
import { useTranslation } from 'react-i18next';

const SalesAnalysisVideo = forwardRef((props, ref) => {
    const { t } = useTranslation();

    const { dispatch } = useContext(Context);
    const [relevantVideosFilter, setrelevantVideosFilterFilter] = useState('7_days');
    const dateRangeRef = useRef();
    const [relevantVideo, setRelevantVideo] = useState();
    const params = useParams();
    const [onLoad, setOnLoad] = useState(false)
    const [chartData, setChartData] = useState([]);
    const [page, setpage] = useState(1)

    useImperativeHandle(ref, () => ({
        async videoAnalysis() {
            if (onLoad == false) {
            }
            setOnLoad(true);
        }
    }));
    const [dateFilterOption] = useState({
        '7_days': {
            label: 'Past 7 Days',
            start_day: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
            end_day: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        },
        '15_days': {
            label: 'Past 15 Days',
            start_day: new Date(new Date().setDate(new Date().getDate() - 15)).toISOString().split('T')[0],
            end_day: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        },
        '30_days': {
            label: 'Past 30 Days',
            start_day: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
            end_day: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        }
    });
    const [dateRange, setDateRange] = useState(dateFilterOption[relevantVideosFilter]);
    const dateFilterOptionHtml = Object.keys(dateFilterOption).map(function (key) {
        return <Button key={key} variant="outline-dark" size="sm" className={(relevantVideosFilter == key ? 'btn-primary' : '')} onClick={() => { setrelevantVideosFilterFilter(key); }}>{dateFilterOption[key].label}</Button>
    });
    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD')
        );
        setDateRange({ start_day: picker.startDate.format('YYYY-MM-DD'), end_day: picker.endDate.format('YYYY-MM-DD') })
        setrelevantVideosFilterFilter('custom');
    };

    const handleCancel = (event, picker) => {
        picker.element.val('');
        setDateRange([]);
    };
    const changeDate = (startDate, endDate) => {
        dateRangeRef.current.setStartDate(moment(new Date(startDate)).format('MM/DD/YYYY'));
        dateRangeRef.current.setEndDate(moment(new Date(endDate)).format('MM/DD/YYYY'));
    };
    const [relevantVideolist, setRelevantVideolist] = useState([]);


    const relevantVideosAPI = async () => {
        try {
            let bodyParams = {};
            if (relevantVideosFilter == 'custom') {
                bodyParams = {
                    start_day: dateRange.start_day,
                    end_day: dateRange.end_day,
                };
            } else {
                bodyParams = dateFilterOption[relevantVideosFilter];
                setDateRange(bodyParams);
            }
            bodyParams.video_id = params.productId;

            changeDate(bodyParams.start_day, bodyParams.end_day);

            let response = await Axios.post('/api/video-analysis', bodyParams);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.status === 200) {
                let passChartData = [...response.data.data.historys];
                setChartData(passChartData);

                setRelevantVideo(response.data.data);
                response.data.data.historys = response.data.data.historys.reverse();
                const docs = [];
                for (let i = 0; i < 5; i++) {
                    if (response.data.data.historys[i]) {
                        docs.push(response.data.data.historys[i])
                    } else { break }
                };
                setRelevantVideolist(docs);
                setpage(1);
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        };
    }
    useEffect(() => {
        relevantVideosAPI();
    }, [relevantVideosFilter]);

    const handlePageClick = (event) => {
        setpage(event.selected + 1);
        const startIndex = ((event.selected + 1) - 1) * 5;
        const endIndex = Math.min(startIndex + 5, relevantVideo?.historys?.length);
        const docs = [];
        for (let i = startIndex; i < endIndex; i++) {
            if (relevantVideo?.historys[i]) {
                docs.push(relevantVideo?.historys[i])
            } else { break; }
        };
        setRelevantVideolist(docs)
    };
    return (
        <div>
            <Card>
                <Card.Body>
                    <div className="row mb-4">
                        <div className="col-lg-5">
                            <h3>{t('product_research.product_details.video_analysis')}</h3>
                        </div>
                        <div className="col-lg-7 text-right">
                            <div className='d-flex justify-content-end align-items-center'>
                                <ButtonGroup className='mr-4'>
                                    {dateFilterOptionHtml}
                                </ButtonGroup>
                                <DateRangePicker
                                    ref={dateRangeRef}
                                    initialSettings={{ startDate: moment(new Date(dateRange.start_day)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_day)).format('MM/DD/YYYY') }}
                                    onApply={handleApply} onCancel={handleCancel} /*onCallback={handleCallback}*/
                                >
                                    <input type="text" placeholder={'First Seen'} className="form-control form-control-sm input-daterange-timepicker w-auto" value={dateRange.start_day + " - " + dateRange.end_day} />
                                </DateRangePicker>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex mb-4'>
                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('product_research.product_details.average_views')}</strong></p>
                                    <h3>{(relevantVideo?.avg_play_count / 1000).toFixed(1)}K</h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('product_research.product_details.average_likes')}</strong></p>
                                    <h3><Conversion value={relevantVideo?.avg_digg_count} /></h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('product_research.product_details.average_comments')}</strong></p>
                                    <h3>{relevantVideo?.avg_comment_count}</h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('product_research.product_details.average_shares')}</strong></p>
                                    <h3>{relevantVideo?.avg_share_count}</h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    {/* GRAPH PART WILL BE DONE */}
                    <div className="row mb-4">
                        {chartData.length > 0 && <VideoAnalysisChart data={chartData} />}
                    </div>

                    {/* METRICS */}
                    <div className="row mb-4">
                        <div className="col-lg-5">
                            <h3>{t('product_research.product_details.metrics')}</h3>
                        </div>
                        <SalesMatricsTabProdVideo currentpage={page} historys={relevantVideolist} handlePageClick={handlePageClick} totalCount={relevantVideo?.historys?.length} />
                    </div>
                    {/* <RelevantVideoList relevantVideolist={relevantVideolist} totalCount={relevantVideo?.video_list_v2_list?.length} handlePageClick={handlePageClick} /> */}
                </Card.Body>
            </Card>
        </div>
    )
})

export default SalesAnalysisVideo
