import React, { useState } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { TikTokEmbed } from 'react-social-media-embed';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Conversion from '../../CommonComponents/Conversion';


const VideoListView = ({ items, favourite, markFavourite, handleSort, sorting }) => {
    const { t } = useTranslation();
    const [tikTokEmbedID, setTikTokEmbedID] = useState('');
    const [contentModal, setContentModal] = useState(false);
    const sortType = sorting.sort_type == 'DESC' ? 'ASC' : 'DESC';
    const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';
    function formatDateFromTimestamp(timestamp) {
        let date = new Date(timestamp * 1000);        
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let day = String(date.getDate()).padStart(2, '0');        
        let formattedDate = `${year}-${month}-${day}`;        
        return formattedDate;
    }
    function formatLikeRate(likeRate) {
        let rate = likeRate * 100;        
        let formattedRate = rate.toFixed(2);    
        return formattedRate + '%';
    }
    const tableHead = [
        { title: t('creator_center.viral_videos.table.video'), className: 'text-left' },
        { title: t('creator_center.viral_videos.table.influencer'), className: 'text-left' },
        { title: t('creator_center.viral_videos.table.views'), className: 'text-left sorting', sort: 3 },
        { title: t('creator_center.viral_videos.table.likes'), className: 'text-left sorting', sort: 8 },
        { title: t('creator_center.viral_videos.table.like_rate'), className: 'text-left sorting', sort: 70 },
        { title: t('creator_center.viral_videos.table.comments'), className: 'text-left sorting', sort: 5 },
        { title: t('creator_center.viral_videos.table.shares'), className: 'text-left sorting', sort: 6 },
        { title: t('creator_center.viral_videos.table.post_date'), className: 'text-left' },
        { title: t('creator_center.viral_videos.table.favourite'), className: 'text-left' },
    ];
    return (
        <>
            <table id="example" className="display w-100 dataTable">
                <thead>
                    <tr role="row">
                        {tableHead.map((value, index) => (
                            <th className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort, sorting.sort == value.sort ? sortType : 'DESC') }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {items.map((product, index) => (
                        <tr key={index}>
                            <td>
                                <div className="d-flex">
                                    <div className="prd-img mr-3">
                                        {product.origin_cover_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + product.origin_cover_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />}
                                        <Button
                                            variant="primary"
                                            className="p-0 video-popup-btn"
                                            onClick={() => { setTikTokEmbedID(product.id); setContentModal(!contentModal); }}
                                        >
                                            <i className="fa fa-play-circle" aria-hidden="true"></i>
                                        </Button>
                                    </div>
                                    <div className="prd-title">
                                        <p>
                                            <OverlayTrigger overlay={<Tooltip>{product.desc}</Tooltip>}>
                                                <Link to={'/products/' + product.id + '/video-details'} target="_blank">
                                                    <span>{product.desc.length > 40 ? product.desc.substring(0, 40) + '...' : product.desc}</span>
                                                </Link>
                                            </OverlayTrigger>
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex">
                                    <div className="prd-img mr-3">
                                        {product.avatar_larger_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + product.avatar_larger_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />}
                                    </div>
                                    <div className="prd-title">
                                        <p>
                                            <OverlayTrigger overlay={<Tooltip>{product.author_nickname}</Tooltip>}>
                                                <Link to={'/influencers/details/' + product.author_id + '/video-creators'} target="_blank">
                                                    <div className='d-flex justify-content-center align-items-center flex-column'>
                                                        <div>
                                                            <span>{product.author_nickname.length > 40 ? product.title.substring(0, 40) + '...' : product.author_nickname}</span>
                                                        </div>
                                                        <div>
                                                            <p className='small'>
                                                                {product?.sys_country?.country_code && <span className={`fi fi-${product?.sys_country?.country_code.toLowerCase()}`}></span>}
                                                                <span className={`fi  mr-1`}></span>{product?.sys_country?.country || '--'}</p>
                                                        </div>
                                                        <div>
                                                            <span className='text-sm-center'>
                                                                {t('creator_center.viral_videos.table.followers')} : {product.author_follower_count ? <Conversion value={product.author_follower_count} /> : '--'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </OverlayTrigger>
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td className="text-left">
                                {product?.play_count ? <Conversion value={product?.play_count} /> : '--'}
                            </td>
                            <td className="text-left">
                                {product?.digg_count ? <Conversion value={product?.digg_count} /> : '--'}
                            </td>
                            <td className="text-left">
                                {product?.digg_count_rate ? formatLikeRate(product?.digg_count_rate) : '--'}
                            </td>
                            <td className="text-left">
                                {product?.comment_count ? <Conversion value={product?.comment_count} /> : '--'}
                            </td>
                            <td className="text-left">
                                {product?.share_count ? <Conversion value={product?.share_count} /> : '--'}
                            </td>
                            <td>
                            {product?.create_time ? formatDateFromTimestamp(product.create_time ) : '--'}
                            </td>
                            <td className="text-left">
                                <div className="d-flex justify-content-center">
                                    <div className="mr-1">
                                        {<Button
                                            variant="outline-dark" size="sm"
                                            className="px-2 py-1"
                                            onClick={() => { markFavourite({ id: product.id }); }}>
                                            <i className={"fa " + (favourite.indexOf(product.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                        </Button>}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal className="fade video-popup" show={contentModal}>
                <Modal.Body className='p-0'>
                    <div className='d-flex'>
                        <div className='text-center'>
                            <TikTokEmbed url={`https://www.tiktok.com/embed/${tikTokEmbedID}`} width={325} />
                        </div>
                        <div className='px-3'>
                            <div className='d-flex flex-column'>
                                <Button
                                    variant="dark"
                                    className="mb-3"
                                    onClick={() => { setTikTokEmbedID(''); setContentModal(!contentModal); }}
                                >
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default VideoListView;