import { React, useState, useCallback, useEffect } from 'react';
import { Collapse } from "react-bootstrap";
import Axios from "../../plugins/axios.js";
import { useTranslation } from 'react-i18next';


const CategoryPrintify = ({ handleCategory, categoryId1, endLevelShow, singleCategory, setSingleCategory, setLoader }) => {
   const [open, setOpen] = useState(false);
   const { t } = useTranslation();

   const [categoryList, setCategoryList] = useState([]);

   const getCategory = useCallback(async () => {
      const response = await Axios.get('/api/printify');

      return response.data.data;
   }, []);

   useEffect(() => {
      getCategory().then((categories) => {
         setCategoryList(categories);
      });
   }, [])

   const selectCategory = async (categoryId, categoryName) => {
      let categoryIds = [], categoryNames = [];
      if (categoryId != '') {
         categoryIds = categoryId.toString().split(",");
      }
      if (typeof categoryName !== 'undefined' && categoryName != '') {
         categoryNames = categoryName.toString().split(",");
      }
      handleCategory(categoryIds, categoryNames);
   };

   const RecursiveComponent = ({ parentCategory, className, previousCategoryId, previousCategoryName, parent, setLoader }) => {
      previousCategoryId = previousCategoryId ? previousCategoryId : parentCategory.id
      previousCategoryName = previousCategoryName ? previousCategoryName : parentCategory.category

      return (
         <ul key={parentCategory.id} className={className} aria-labelledby="dropdownMenuLink">
            {
               parentCategory.child.map((category, index) => (
                  <li key={category.id} className={endLevelShow == 1 && category.child.length > 0 ? 'submenu submenu-arrow' : ''} style={{ cursor: 'pointer' }}>
                     <a className="dropdown-item" onClick={() => { selectCategory(previousCategoryId + "," + category.id, previousCategoryName + "," + category.category); setLoader(true) }}>
                        {category.category}
                     </a>
                     {endLevelShow == 1 && category.child.length > 0 && <RecursiveComponent parentCategory={category} previousCategoryId={previousCategoryId + "," + category.id} previousCategoryName={previousCategoryName + "," + category.category} className={'submenu'} />}
                  </li>
               ))
            }
         </ul>
      );
   };

   return (
      <>
         <div className="d-flex align-items-center flex-wrap mb-2">
            <p className="m-0 mr-3"><strong>{t('common.category')}</strong></p>
            <div>
               <a
                  className={"btn " + (categoryId1 === 0 ? 'btn-primary' : 'btn-outline-dark') + " btn-sm mr-2 mb-2"}
                  onClick={() => { selectCategory('', ''); setLoader(true)}}>
                  All
               </a>
            </div>
            {
               categoryList.slice(0, 6).map((category, index) => (
                  (singleCategory ?
                     (<div key={index}>
                        <a
                           className={"btn " + (categoryId1 == category.id ? 'btn-primary' : 'btn-outline-dark') + " btn-sm mr-2 mb-2"}
                           onClick={() => { setSingleCategory(category) }}>
                           {category.category}
                        </a>
                     </div>)
                     :
                     (<div key={index} className="dropdown">
                        <a
                           className={"btn " + (categoryId1 == category.id ? 'btn-primary' : 'btn-outline-dark') + " btn-sm mr-2 dropdown-toggle mb-2"}
                           onClick={() => { selectCategory(category.id, category.category); setLoader(true) }}>
                           {category.category}
                           <span className='cl-fl' onClick={() => { alert('alert'); }}></span>
                        </a>
                        {category.child.length > 0 && <RecursiveComponent parentCategory={category} parent={true} className={'dropdown-menu'} setLoader={setLoader}/>}
                     </div>)
                  )
               ))
            }
            <a
               onClick={() => setOpen(!open)}
               aria-controls="example-collapse-text"
               aria-expanded={open}
               className='btn btn-outline-dark btn-sm show-sub mb-2'><i className="fa fa-chevron-down" aria-hidden="true"></i></a>
         </div>
         <Collapse in={open}>
            <div id="example-collapse-text" className='pl-5'>
               <div className='d-flex align-items-center flex-wrap'>
                  {
                     categoryList.slice(6).map((category, index) => (
                        (
                           singleCategory ? (
                              <div key={index} className="dropdown mb-2">
                                 <a
                                    className={"btn " + (categoryId1 == category.id ? 'btn-primary' : 'btn-outline-dark') + " btn-sm mr-2 "}
                                    onClick={() => { setSingleCategory(category) }}>
                                    {category.category}
                                 </a>
                              </div>) : (
                              <div key={category.id} className="dropdown mb-2">
                                 <a className={"btn " + (categoryId1 == category.id ? 'btn-primary' : 'btn-outline-dark') + " btn-sm mr-2 "} role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => { selectCategory(category.id, category.category); }} >
                                    {category.category}
                                    <span className='cl-fl' onClick={() => { alert('alert'); }}></span>
                                 </a>
                                 {category.child.length > 0 && <RecursiveComponent parentCategory={category} parent={category} className={'dropdown-menu'} />}
                              </div>

                           ))

                     ))
                  }
               </div>
            </div>
         </Collapse>
      </>
   );
};

export default CategoryPrintify;