import React from 'react';
import { useTranslation } from 'react-i18next';
const FooterSlick = () => {
  const { t } = useTranslation();
  return (
    <footer className="la-footer la-footer-slick">
      <div className="container pb-3 pt-3 small">
        <div className="row">
          <div className="col-lg-8">
            <ul className='d-flex'>
              <li className='pr-3'>
                <a href="/#">
                  <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
                    <circle cx="2.83058" cy="2.83058" r="2.83058" fill="#f82c8f"></circle>
                  </svg>
                  {t('footer.terms_of_services')}
                </a>
              </li>
              <li className='pr-3'>
                <a href="/#">
                  <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
                    <circle cx="2.83058" cy="2.83058" r="2.83058" fill="#f82c8f"></circle>
                  </svg>
                  {t('footer.privacy_policy')}
                </a>
              </li>
              <li>
                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
                  <circle cx="2.83058" cy="2.83058" r="2.83058" fill="#f82c8f"></circle>
                </svg>
                {t('footer.contact_us')}:
                <a href="mailto:support@tikshoptrends.com" className='ml-2' target='_blank' rel="noreferrer">
                  support@tikshoptrends.com
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 text-right">
            © Tikshop Trends 2024
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterSlick;
