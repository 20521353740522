import Axios from "../../plugins/axios";
import TopsalesProducts from "./TopsalesProducts";
import BreakoutProducts from "./BreakoutProducts";
import NewProducts from "./NewProducts";
import HotProducts from "./HotProducts";
import { useContext, React, useState, useEffect } from 'react';
import { ButtonGroup, Button, Card, Table } from "react-bootstrap";
import Category from "./Category";
import RangeSlider from "./RangeSlider";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import '../../../config';
import { Context } from '../../../MasterLayout';
import { useTranslation } from 'react-i18next';
import Export from "../CommonComponents/export";

const TopSalesFilter = ({ tab, defaultCycle, rankType, sort }) => {
   const { t } = useTranslation();
   const [page, setPage] = useState(0);
   const [items, setItems] = useState([]);
   const [hasMore, setHasMore] = useState(true);
   const [countryCode, setCountryCode] = useState('US');
   const [category1, setCategory1] = useState('');
   const [category2, setCategory2] = useState('');
   const [category3] = useState('');
   const [categoryId1, setCategoryId1] = useState('');
   const [categoryId2, setCategoryId2] = useState('');
   const [categoryId3] = useState('');
   const [shopAttribute, setShopAttribute] = useState('');
   const [freeShipping, setFreeShipping] = useState('');
   const [bizType, setBizType] = useState('');
   const [productStatus] = useState('');
   const [ratingRange, setRatingRange] = useState([]);
   const [searchClick] = useState(0);
   const [cycle, setCycle] = useState(defaultCycle);
   const [version, setVersion] = useState('');
   const [versionList, setVersionList] = useState([]);
   const [priceRange, setPriceRange] = useState([]);
   const [commissionRange, setCommissionRange] = useState([]);
   const [relevantInfluencersRange, setRelevantInfluencersRange] = useState([]);
   const [dateRange, setDateRange] = useState([]);
   const [favourite, setFavourite] = useState([]);
   const [sorting, setSorting] = useState({ sort: sort, sort_type: 'DESC' });
   const { dispatch } = useContext(Context);
   const [payload,setPayload] = useState({})

   const cycleList = {
      'hot': {
         'H24': '1 Day',
         'W1': 'Weekly',
         'M1': 'Monthly',
      },
      'breakout': {
         'H24': '1 Day',
         'D3': '3 Day',
         'W1': 'Weekly',
      },
      'new': {
         'H24': '1 Day',
         'D3': '3 Day',
         'W1': 'Weekly',
      },
      'popular': {
         'LD1': '1 Day',
         'LD3': '3 Day',
         'LD7': 'Weekly',
      },
   };

   const endPointURL = {
      'hot': '/api/top-sales',
      'breakout': '/api/top-sales-breakout',
      'new': '/api/top-sales-new',
      'popular': '/api/top-sales-hot',
   }


   const priceRangeOption = {
      '0-1': '≤$1',
      '1-5': '$1-$5',
      '5-10': '$5-$10',
      '10-20': '$10-$20',
      '20-50': '$20-$50',
      '50-100': '$50-$100',
      "100-": '≥$100',
   }, commissionRangeOption = {
      '0-1': '≤1%',
      '1-5': '1%-5%',
      '5-10': '5%-10%',
      '10-20': '10%-20%',
      '20-30': '20%-30%',
      '30-40': '30%-40%',
      '40-50': '40%-50%',
   }, relevantInfluencersRangeOption = {
      '0-5': '≤5',
      '5-10': '5-10',
      '10-20': '10-20',
      '20-50': '20-50',
      '50-100': '50-100',
      "100-": '>100',
   }, ratingRangeOption = {
      '0-1': '0-1',
      '1-2': '1-2',
      '2-3': '2-3',
      '3-4': '3-4',
      '4-5': '4-5',
   };

   useEffect(() => {
      //do operation on state change
      setItems([]);
      setPage(0);
      if (version == '') {
         getVersion(cycle);
      } else {
         productSearch(0);
      }
   }, [category1, category2, category3, countryCode, shopAttribute, freeShipping, bizType, productStatus, searchClick, ratingRange, priceRange, commissionRange, relevantInfluencersRange, dateRange, version, sorting]);


   const markFavourite = async (data) => {
      await Axios.post('/api/favorite-product', data);
      const index = favourite.indexOf(data.id);
      if (index > -1) {
         favourite.splice(index, 1);
      } else {
         favourite.push(data.id);
      }

      setFavourite(favourite.concat([]));
   };

   const handleCategory = async (categoryIds, categoryNames) => {
      setCategory1(categoryNames[0] ? categoryNames[0] : '');
      setCategory2(categoryNames[1] ? categoryNames[1] : '');
      setCategoryId1(categoryIds[0] ? categoryIds[0] : '');
      setCategoryId2(categoryIds[1] ? categoryIds[1] : '');
   };

   const selectCountry = async (countryCode) => {
      setCountryCode(countryCode);
   };

   const getVersion = async (cycle) => {
      let params = {
         cycle
      }
      if (rankType) {
         params['rank_type'] = rankType;
      }
      let response = await Axios.get('/api/version', { params });
      if (response.data.data) {
         setVersion(response.data.data[0].version);
      }
      setVersionList(response.data.data ? response.data.data : []);
   };

   const productSearch = async (page) => {
      try {
         let params = {
            page,
            cycle,
            version
         };
         if (countryCode != '') {
            params['country_code'] = countryCode;
         }
         if (category1 != '') {
            params['category1'] = category1;
         }
         if (category2 != '') {
            params['category2'] = category2;
         }
         if (category3 != '') {
            params['category3'] = category3;
         }
         if (categoryId1 != '') {
            params['category_id1'] = categoryId1;
         }
         if (categoryId2 != '') {
            params['category_id2'] = categoryId2;
         }
         if (categoryId3 != '') {
            params['category_id3'] = categoryId3;
         }
         if (shopAttribute != '') {
            params['ship_from'] = shopAttribute;
         }
         if (freeShipping != '') {
            params['free_shipping'] = freeShipping == 1 ? true : false;
         }
         if (bizType != '') {
            params['biz_type'] = bizType == 1 ? true : false;
         }
         if (productStatus != '') {
            params['product_status'] = productStatus;
         }
         if (priceRange.from) {
            params['start_price'] = priceRange.from;
         }
         if (priceRange.to && priceRange.to != '') {
            params['end_price'] = priceRange.to;
         }
         if (commissionRange.from) {
            params['start_commission_rate'] = commissionRange.from;
         }
         if (commissionRange.to && commissionRange.to != '') {
            params['end_commission_rate'] = commissionRange.to;
         }
         if (relevantInfluencersRange.from) {
            params['start_related_author'] = relevantInfluencersRange.from;
         }
         if (relevantInfluencersRange.to && relevantInfluencersRange.to != '') {
            params['end_related_author'] = relevantInfluencersRange.to;
         }
         if (ratingRange.from && ratingRange.to) {
            params['start_product_rating'] = ratingRange.from;
            params['end_product_rating'] = ratingRange.to;
         }

         if (dateRange.start && dateRange.end) {
            params['discover_time_start'] = dateRange.start;
            params['discover_time_end'] = dateRange.end;
         }

         params['sort'] = sorting.sort;
         params['sort_type'] = sorting.sort_type;
         setPayload(params)
         let response = await Axios.post(endPointURL[tab], params);
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });

         if (response && response.status === 200 && response.data.data) {
            let array = await response.data.favorite ? response.data.favorite.map((value, index) => value.product_id) : favourite;
            if (page == 0) {
               setItems(response.data.data);
               setFavourite(array);
            } else {
               setItems(items.concat(response.data.data));
               setFavourite(favourite.concat(array));
            }
            if (response.data.data.length === 0 || response.data.data.length < 20) {
               setHasMore(false);
            } else {
               setHasMore(true);
            }
         }
      } catch (error) {
         console.error('Error: ' + error.message);
      };
   }

   const handleSort = async (sort, sortType) => {
      if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
         setSorting({ sort, sort_type: sortType });
      }
   };

   const functionNext = async () => {
      if (items.length > 0) {
         let number = page + 1;
         setPage(number);
         productSearch(number);
      }
   }

   const handlePriceRange = async (value) => {
      setPriceRange(value);
   };
   const handleCommissionRange = async (value) => {
      setCommissionRange(value);
   };
   const handleRelevantInfluencersRange = async (value) => {
      setRelevantInfluencersRange(value);
   };
   const handleRatingRange = async (value) => {
      setRatingRange(value);
   };

   const handleApply = (event, picker) => {
      picker.element.val(
         picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD')
      );
      setDateRange({ start: picker.startDate.format('YYYY-MM-DD'), end: picker.endDate.format('YYYY-MM-DD') })
   };
   const handleCancel = (event, picker) => {
      picker.element.val('');
      setDateRange([]);
   };
   /*const handleCallback = (start, end) => {
      setDateRange({start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD')});
   };*/

   const regionList = Object.keys(global.config.region).map(function (key) {
      return <a key={key} className={"btn my-1 " + (countryCode == key ? 'btn-primary' : 'btn-outline-dark') + " btn-sm mr-2 d-flex align-items-center mb-2"} onClick={() => { selectCountry(key); }}><span className={`fi fi-${key.toLocaleLowerCase()} mr-1`}></span> {global.config.region[key]}</a>
   });

   const cycleHtml = Object.keys(cycleList[tab]).map(function (key) {
      return <Button key={key} variant={"outline-primary " + (cycle == key ? 'btn-primary' : '')} size="sm" onClick={() => { setCycle(key); getVersion(key); }}>{cycleList[tab][key]}</Button>
   });
   const longText = {
      hot:t('product_research.top_sales.filter.long_text_1'),
      breakout:t('product_research.top_sales.filter.long_text_3'),
      new:t('product_research.top_sales.filter.long_text_4'),
      popular:t('product_research.top_sales.filter.long_text_5')
   }

   return (
      <>
         <Card className='rounded-0'>
            <Card.Body>
               <div className='topfilter'>
                  <p><small>{longText[tab]}</small></p>
                  <div className="d-flex align-items-center flex-wrap mb-2">
                     <p className="m-0 mr-3"><strong>{t('product_research.top_sales.filter.country')}</strong></p>
                     {regionList}
                  </div>
                  <Category singleCategory={false} handleCategory={handleCategory} categoryId1={categoryId1} />
                  <div className="d-flex align-items-center mb-2">
                     <p className="m-0 mr-3"><strong>{t('product_research.top_sales.filter.filters')}</strong></p>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-sm mr-2 dropdown-toggle"
                           role="button"
                           id="dropdownMenuLink"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           {t('product_research.top_sales.filter.price')}
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={50000000} defaultMin={0} defaultMax={50000000} rangeOption={priceRangeOption} rangeOptionName={'price'} step={10} handleUpdate={handlePriceRange} />
                        </div>
                     </div>
                     {tab != 'popular' && <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-sm mr-2 dropdown-toggle"
                           role="button"
                           id="dropdownMenuLink"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           {t('product_research.top_sales.filter.commission')}
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={100} defaultMin={0} defaultMax={100} rangeOption={commissionRangeOption} rangeOptionName={'commission'} step={1} handleUpdate={handleCommissionRange} />
                        </div>
                     </div>}
                     {tab != 'new' && <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-sm mr-2 dropdown-toggle"
                           role="button"
                           id="dropdownMenuLink"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           {t('product_research.top_sales.filter.relevant_influencers')}
                           {/*<i className="fa fa-times" aria-hidden="true"></i>*/}
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={5000} defaultMin={0} defaultMax={5000} rangeOption={relevantInfluencersRangeOption} rangeOptionName={'relevantInfluencers'} step={10} handleUpdate={handleRelevantInfluencersRange} />
                        </div>
                     </div>}
                     {tab != 'new' && <DateRangePicker
                        initialSettings={{
                           autoUpdateInput: false,
                           locale: {
                              cancelLabel: 'Clear',
                           },
                        }} onApply={handleApply} onCancel={handleCancel} /*onCallback={handleCallback}*/
                     >
                        <input type="text" placeholder={'First Seen'} className="form-control form-control-sm input-daterange-timepicker w-auto" />
                     </DateRangePicker>}
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <p className="m-0 mr-3"><strong>
                        {t('product_research.top_sales.filter.advanced_filtering')}
                        </strong></p>
                     <div className="form-group mb-0 mr-3">
                        <select className="form-control form-control-sm" onChange={(e) => { setShopAttribute(e.target.value); }}>
                           <option value="">
                              {t('product_research.top_sales.filter.shop_attribute')}
                              </option>
                           <option value="overseas">
                              {t('product_research.top_sales.filter.Cross_border_shop')}
                              </option>
                           <option value="local">
                              {t('product_research.top_sales.filter.local_shop')}
                              </option>
                        </select>
                     </div>
                     <div className="form-group mb-0 mr-3">
                        <select className="form-control form-control-sm" onChange={(e) => { setFreeShipping(e.target.value); }}>
                           <option value="">{t('product_research.top_sales.filter.free_shipping')}</option>
                           <option value="1">{t('product_research.top_sales.filter.yes')}</option>
                           <option value="0">{t('product_research.top_sales.filter.no')}</option>
                        </select>
                     </div>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-sm mr-2 dropdown-toggle"
                           role="button"
                           id="dropdownMenuLink"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           {t('product_research.top_sales.filter.product_rating')}
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={5} defaultMin={0} defaultMax={5} rangeOption={ratingRangeOption} rangeOptionName={'rating'} step={0.1} handleUpdate={handleRatingRange} />
                        </div>
                     </div>
                     <div className="form-group mb-0 mr-3">
                        <select className="form-control form-control-sm" onChange={(e) => { setBizType(e.target.value); }}>
                           <option value="">{t('product_research.top_sales.filter.project_s_product')}</option>
                           <option value="1">{t('product_research.top_sales.filter.yes')}</option>
                           <option value="0">{t('product_research.top_sales.filter.no')}</option>
                        </select>
                     </div>
                     { /*<div className="form-group mb-0 mr-3">
                        <select className="form-control form-control-sm">
                           <option value="">Product Status</option>
                           <option value="1">Yes</option>
                           <option value="0">No</option>
                        </select>
                     </div>*/ }
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <ButtonGroup aria-label="Basic example" className='mr-2'>
                        {cycleHtml}
                     </ButtonGroup>
                     <div className="dropdown product-rating-menu">
                        <div className="form-group mb-0 mr-3">
                           <select className="form-control form-control-sm" onChange={(e) => { setVersion(e.target.value); }} value={version}>
                              {versionList.map((value, index) => (
                                 <option key={index} value={value.version}>{value.label}</option>
                              ))}
                           </select>
                        </div>
                     </div>
                     <p className='text-muted mb-0'><small>{t('product_research.top_sales.filter.long_text_2')}</small></p>
                  </div>
                  <div className="d-flex justify-content-end align-items-center mx-2 my-3">
                  <Export payload={payload} data_source={tab === 'hot' ? "Top Sales Products" : tab === 'breakout' ? "Breakout Products" : tab === 'new' ? "New Products" : tab === 'popular' ? "Hot Products" : ""} type="Product"/>
                  </div>
               </div>
            </Card.Body>
         </Card>
         <Card>
            <Card.Body className='p-2'>
               <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                     {tab == 'hot' && <TopsalesProducts functionNext={functionNext} items={items} favourite={favourite} markFavourite={markFavourite} hasMore={hasMore} handleSort={handleSort} sorting={sorting} />}
                     {tab == 'breakout' && <BreakoutProducts functionNext={functionNext} items={items} favourite={favourite} markFavourite={markFavourite} hasMore={hasMore} handleSort={handleSort} sorting={sorting} />}
                     {tab == 'new' && <NewProducts functionNext={functionNext} items={items} favourite={favourite} markFavourite={markFavourite} hasMore={hasMore} handleSort={handleSort} sorting={sorting} />}
                     {tab == 'popular' && <HotProducts functionNext={functionNext} items={items} favourite={favourite} markFavourite={markFavourite} hasMore={hasMore} handleSort={handleSort} sorting={sorting} />}
                  </div>
               </Table>
            </Card.Body>
         </Card>
      </>
   );
};

export default TopSalesFilter;