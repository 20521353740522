import { React } from 'react';
import { Link } from 'react-router-dom';
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { Sparklines, SparklinesLine } from "react-sparklines";
import InfiniteScrollWrapper from "./InfiniteScrollWrapper";
import Conversion from '../CommonComponents/Conversion';
import { useTranslation } from 'react-i18next';


const TopsalesProducts = ({ functionNext, items, favourite, markFavourite, hasMore, handleSort, sorting }) => {
   const { t } = useTranslation();

   const tableHead = [
      {
         title: '#',
      },
      {
         title: t('product_research.top_sales.top_sales_products.products'),
      },
      {
         title: t('product_research.top_sales.top_sales_products.price'),
         className: 'text-left',
      },
      {
         title: t('product_research.top_sales.top_sales_products.commission'),
         className: 'text-right',
      },
      {
         title: t('product_research.top_sales.top_sales_products.sales'),
         className: 'text-right',
         sort: 10,
      },
      {
         title: t('product_research.top_sales.top_sales_products.revenue'),
         className: 'text-right',
         sort: 11,
      },
      {
         title: t('product_research.top_sales.top_sales_products.sales_trend'),
         className: 'text-right',

      },
      {
         title: `${t('product_research.top_sales.top_sales_products.relevant')} <br/> ${t('product_research.top_sales.top_sales_products.videos')}`,
         className: 'text-right',
         sort: 12,
      },
      {
         title: `${t('product_research.top_sales.top_sales_products.relevant')} <br/> ${t('product_research.top_sales.top_sales_products.influencers')}`,
         className: 'text-right',
         sort: 13,
      },
      {
         title: t('product_research.top_sales.top_sales_products.favourite'),
         className: 'text-right',
      }
   ];

   return (
      <InfiniteScrollWrapper
         isInfiniteScrollOn={true}
         lengthData={items.length}
         functionNext={functionNext}
         hasMore={hasMore}
      >
         <table id="example" className="display w-100 dataTable">
            <thead>
               <tr role="row">
                  {tableHead.map((value, index) => (
                     <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? 'sorting-desc-blue' : (value.sort ? 'sorting-desc-icon' : ''))} onClick={(e) => { value.sort && handleSort(value.sort) }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                  ))}
               </tr>
            </thead>
            <tbody>
               {items.map((product, index) => (
                  <tr key={index}>
                     <td>{index + 1}</td>
                     <td>
                        <div className="d-flex">
                           <div className="prd-img mr-3">
                              <OverlayTrigger
                                 key="right"
                                 trigger={["hover", "focus"]}
                                 placement="right"
                                 rootClose
                                 overlay={
                                    <Popover>
                                       <Popover.Body>
                                          {product.images_privatizatione[0] && <img src={"https://t-img.picturehaven.net/product/" + product.images_privatizatione[0] + "?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={500} />}
                                       </Popover.Body>
                                    </Popover>
                                 }
                              >
                                 <Button variant="link" className='p-0' size="sm" to={'/products/' + product.id + '/details'}>
                                    <Link to={'/products/' + product.id + '/details'} target='_blank'>
                                       {product.images_privatizatione[0] && <img src={"https://t-img.picturehaven.net/product/" + product.images_privatizatione[0] + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />}
                                    </Link>
                                 </Button>

                              </OverlayTrigger>
                           </div>
                           <div className="prd-title">
                              <p>
                                 <OverlayTrigger overlay={<Tooltip>{product.title}</Tooltip>}>
                                    <Link to={'/products/' + product.id + '/details'} target='_blank'>
                                       <span>{product.title.length > 40 ? product.title.substring(0, 40) + '...' : product.title}</span>
                                    </Link>
                                 </OverlayTrigger>
                              </p>
                              {!product.shop ? <span>Closed</span> :                                 
                                 <OverlayTrigger
                                    key="right"
                                    trigger={["hover", "focus"]}
                                    placement="right"
                                    rootClose
                                    overlay={
                                       <Popover>
                                          <Popover.Body>
                                             {product.shop.shop_logo_privatization && <img src={"https://t-img.picturehaven.net/product/" + product.shop.shop_logo_privatization} alt="" width={500} />}
                                          </Popover.Body>
                                       </Popover>
                                    }
                                 >
                                    <Button variant="link" className='p-0' size="sm" to={'/products/' + product.id + '/details'}>
                                       <Link to={'/shops/' + product.shop.shop_id+ '/details'} target='_blank'>
                                       <div className='d-flex gap-1'>
                                          {product.shop.shop_logo_privatization && <img src={"https://t-img.picturehaven.net/product/" + product.shop.shop_logo_privatization} alt="" width={20} className="prod-thum-img" />}
                                             {product.shop.shop_name}
                                          </div>
                                       </Link>
                                    </Button>                                    
                                 </OverlayTrigger>
                              }
                           </div>
                        </div>
                     </td>
                     <td>
                        {product.sale_prop ?
                           <div className="d-flex justify-content-between">
                              <div className="mr-3">
                                 {product.price_usd}<br />
                                 <span className="text-muted">({product.price})</span>
                              </div>
                              <div className='d-flex p-variants'>
                                 <div className='v-btn'>
                                    <OverlayTrigger
                                       key="right"
                                       trigger="click"
                                       placement="right"
                                       rootClose
                                       overlay={
                                          <Popover id={`popover-positioned-$"right"`} className="variants-tooltip">
                                             <Popover.Header as="h3">Variants</Popover.Header>
                                             <Popover.Body>
                                                <div className="d-flex mb-3">
                                                   <div className="mr-3">
                                                      {product.images_privatizatione[0] && <img src={"https://t-img.picturehaven.net/product/" + product.images_privatizatione[0] + "?imageMogr2/auto-orient/thumbnail/140x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={200} />}
                                                   </div>
                                                   <div>
                                                      <p className="mb-0">{product.title}</p>
                                                      <p className="mb-0">{product.price_usd} <span className="text-muted">({product.price})</span></p>
                                                   </div>
                                                </div>
                                                {product.sale_prop.map((variant, index1) => (
                                                   <div className="mb-3" key={index1}>
                                                      <p className="mb-0"><strong>{variant.prop_name}</strong></p>
                                                      <div className="variant-box">
                                                         {variant.sale_prop_values.map((value, index2) => (
                                                            <Button key={index2} variant="outline-dark" size="sm" className="mb-1 mr-1">{value.prop_value}</Button>
                                                         ))}
                                                      </div>
                                                   </div>
                                                ))}
                                             </Popover.Body>
                                          </Popover>
                                       }
                                    >
                                       <Button variant="outline-primary" size="sm">Variants</Button>
                                    </OverlayTrigger>
                                 </div>
                                 <div className='v-truck'>
                                    {product.free_shipping &&
                                       <OverlayTrigger overlay={<Tooltip>Free Shipping</Tooltip>}>
                                          <span><i className="fa fa-truck mt-2 ml-2 text-muted" aria-hidden="true"></i></span>
                                       </OverlayTrigger>
                                    }
                                 </div>
                              </div>
                           </div>
                           : ''}
                     </td>
                     <td className="text-right">
                        {product.commission_rate || '-'}
                     </td>
                     <td className="text-right">
                        {product.sold_count_incr}
                     </td>
                     <td className="text-right">
                        {product.sales_incr_usd}<br />
                        <span className="text-muted">({product.sales_incr})</span>
                     </td>
                     <td>
                        <Sparklines
                           data={
                              (product.trends && product.trends.length > 0)
                                 ? product.trends.map(value => value.sold_count_incr).reverse()
                                 : [0, 0]
                           }
                           width={60}
                           height={30}
                        >
                           <SparklinesLine color="#f82c8f" />
                        </Sparklines>
                     </td>
                     <td className="text-right">
                        {product.related_videos ? <Conversion value={product.related_videos} /> : '-'}
                     </td>
                     <td className="text-right">
                        {product.related_author ? <Conversion value={product.related_author} /> : '-'}
                     </td>
                     <td className="text-right">
                        <div className="d-flex justify-content-end">
                           { /*<div className="mr-1">
                                 <Button variant="outline-dark" size="sm" className="px-2 py-1"><i className="fa fa-line-chart" aria-hidden="true"></i></Button>
                              </div>*/ }
                           <div className="mr-1">
                              {<Button
                                 variant="outline-dark" size="sm"
                                 className="px-2 py-1"
                                 onClick={() => { markFavourite({ id: product.id, country_code: product.country_code }); }}>
                                 <i className={"fa " + (favourite.indexOf(product.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                              </Button>}
                           </div>
                           { /*<div>
                                 <Button variant="outline-dark" size="sm" className="px-2 py-1"><i className="fa fa-download" aria-hidden="true"></i></Button>
                              </div>*/ }
                        </div>
                     </td>
                  </tr>

               ))}
            </tbody>
         </table>
      </InfiniteScrollWrapper>
   );
};

export default TopsalesProducts;