import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import ShopRankingFilter from './ShopRankingListTabs/ShopRankingFilter';
import { useTranslation } from 'react-i18next';


function ShopRankingList() {
    const { t } = useTranslation();

    const search = useLocation().search;
    const tab = new URLSearchParams(search).get('tab')|| 'trending-shops';    
    
    return (
        <div>
            <div className="custom-tab-1">
                <Tab.Container
                    defaultActiveKey={tab}
                >
                    <Nav as="ul" className="nav-tabs mb-0 bg-white">
                        <Nav.Item as="li" key="trending-shops">
                            <Nav.Link eventKey="trending-shops" as={Link} to="?tab=trending-shops"
                            >
                                {t('shops.trending_shops')}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" key="hot-shops">
                            <Nav.Link eventKey="hot-shops" as={Link} to="?tab=hot-shops"
                            >
                               {t('shops.hot_shop_list')}
                            </Nav.Link>
                        </Nav.Item>

                    </Nav>
                    <Tab.Content>

                        {tab == 'trending-shops' &&
                            <Tab.Pane
                                eventKey="trending-shops"
                                key="trending-shops"
                            >
                                <ShopRankingFilter defaultCycle={'H24'} tab={tab} />
                            </Tab.Pane>}
                        {tab == 'hot-shops' &&
                            <Tab.Pane
                                eventKey="hot-shops"
                                key="hot-shops"
                            >
                                <ShopRankingFilter defaultCycle={'H24'} tab={tab} />
                            </Tab.Pane>}
                        
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    )
}

export default ShopRankingList