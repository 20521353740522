import React, { useEffect, useState, useContext } from 'react';
import { Card, Col, Button, OverlayTrigger, Popover, Spinner, Collapse } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from 'moment';
import Axios from '../../plugins/axios';
import { Context } from '../../../MasterLayout';
import { useTranslation } from 'react-i18next';

const StorePerformance = ({ date, currentStore, name, func }) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [dateRange, setDateRange] = useState({ start_date: new Date(date.start_date), end_date: new Date(date.end_date) });
  const [summaryData, setSummaryData] = useState(null);


  useEffect(() => {
    getSummaryReport();
  }, [dateRange, currentStore]);

  const handleApply = (event, picker) => {
    picker.element.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    setDateRange({ start_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
    func(name, { start_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
  };

  const getSummaryReport = async () => {
    try {
      setSpinner(true);
      let response = await Axios.post('/api/dashboard/store-performance', {
        store_id: currentStore._id, start_date: dateRange.start_date, end_date: dateRange.end_date
      }).catch((e) => console.error('Axios-Catch: ' + e.message));
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200) {
        setSummaryData(response.data.data);
      };
    } catch (error) {
      console.error('Error-Catch: ' + error.message);
    };
    setSpinner(false);
  };

  return (
    <Card>
      <Card.Body className="p-0">
        <div className="row justify-content-center p-3">
          {spinner && <div className='loader-warp'>
            <Spinner animation="border" variant="primary" />
          </div>}
          <Col className="text-right">
            <DateRangePicker
              initialSettings={
                {
                  startDate: moment(new Date(dateRange.start_date)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_date)).format('MM/DD/YYYY'),
                  ranges: {
                    Today: [moment().toDate(), moment().toDate()],
                    Yesterday: [
                      moment().subtract(1, 'days').toDate(),
                      moment().subtract(1, 'days').toDate(),
                    ],
                    'Last 7 Days': [
                      moment().subtract(6, 'days').toDate(),
                      moment().toDate(),
                    ],
                    'Last 30 Days': [
                      moment().subtract(29, 'days').toDate(),
                      moment().toDate(),
                    ],
                    'This Month': [
                      moment().startOf('month').toDate(),
                      moment().endOf('month').toDate(),
                    ],
                    'Last Month': [
                      moment().subtract(1, 'month').startOf('month').toDate(),
                      moment().subtract(1, 'month').endOf('month').toDate(),
                    ],
                  },
                }}
              onApply={handleApply}
            >
              <input type="text" className="form-control w-auto form-control-sm input-daterange-timepicker pull-right" />
            </DateRangePicker>
          </Col>
        </div>
        <hr className="mt-0" />
        <div className="px-3">
          <p className="mb-0">{dateRange?.start_date ? moment(new Date(dateRange.start_date)).format('MMM DD, YYYY') : '--/--/--'} - {dateRange?.end_date ? moment(new Date(dateRange.end_date)).format('MMM DD, YYYY') : '--/--/--'}</p>
          <hr className="mt-0" />
          <div className="row">
            <div className="col-6">
              <div className="mb-3">
                <h4 className="text-uppercase"> <i className="fa fa-info-circle" aria-hidden="true"></i> {t('dashboard.gross_revenue')}</h4>
                <h4 className="ml-4">$ {parseFloat(summaryData?.current?.total_payment).toFixed(2)}</h4>
              </div>
              <div className="mb-3">
                <h4 className="text-uppercase"> <i className="fa fa-info-circle" aria-hidden="true"></i> {t('dashboard.cost')}</h4>
                <h4 className="ml-4">$ {parseFloat(summaryData?.current?.total_cost).toFixed(2)}</h4>
              </div>
              <div className="mb-3">
                <h4 className="text-uppercase"> <i className="fa fa-info-circle" aria-hidden="true"></i> {t('dashboard.net_profit')}</h4>
                <h4 className="ml-4">$ {parseFloat(summaryData?.current?.net_profit).toFixed(2)}</h4>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex justify-content-between">
                <p className="text-uppercase font-w400 mb-2">{t('dashboard.orders')}</p>
                <p className="font-w400 mb-2">{summaryData?.current?.order_count}</p>
              </div>
              <hr className="mt-0" />
              <div className="d-flex justify-content-between">
                <p className="text-uppercase font-w400 mb-2">{t('dashboard.margin')}</p>
                <p className="font-w400 mb-2">{summaryData?.current?.total_payment && summaryData?.current?.total_payment != 0 ? ((parseFloat(summaryData?.current?.profit_loss) / parseFloat(summaryData.current.total_payment)) * 100).toFixed(2) + ' %' : '-'}</p>
              </div>
              <hr className="mt-0" />
              <div className="d-flex justify-content-between">
                <p className="text-uppercase font-w400 mb-2">{t('dashboard.units_sold')}</p>
                <p className="font-w400 mb-2">{summaryData?.current?.total_quantity_sold}</p>
              </div>
              <hr className="mt-0" />
              <div className="text-end">
                <OverlayTrigger
                  key="right"
                  trigger={["click"]}
                  placement="bottom"
                  rootClose
                  overlay={
                    <Popover>
                      <Popover.Body>
                        <div className='row'>
                          <Col lg="8">
                            <p className='mb-0'>{t('dashboard.gross_revenue')}:</p>
                          </Col>
                          <Col lg="4" className='text-right'>
                            <p className='mb-0'>${parseFloat(summaryData?.current?.total_payment).toFixed(2)}</p>
                          </Col>
                          <Col lg="12" className='text-right'><hr className='m-0' /></Col>
                          <Col lg="8">
                            <p className='mb-0'>{t('dashboard.tiktok_expenses')}: <i className="fa fa-arrow-right"
                              onClick={() => setOpen(!open)}
                              aria-controls="example-collapse-text"
                              aria-expanded={open} aria-hidden="true"></i></p>
                          </Col>
                          <Col lg="4" className='text-right'>
                            <p className='mb-0'>- ${parseFloat(summaryData?.current?.total_charges).toFixed(2)}</p>
                          </Col>
                          <Col lg="12" className='text-right'><hr className='m-0' /></Col>
                          <Collapse in={open}>
                            <div id="example-collapse-text">
                              <div className='row ml-1'>
                                <Col lg="8">
                                  <p className='mb-0'>{t('dashboard.commission')}:</p>
                                </Col>
                                <Col lg="4" className='text-right'>
                                  <p className='mb-0'>- ${parseFloat(summaryData?.current?.total_commision).toFixed(2)}</p>
                                </Col>
                                <Col lg="12" className='text-right'><hr className='m-0' /></Col>
                                <Col lg="8">
                                  <p className='mb-0'>{t('dashboard.referral_fee')}:</p>
                                </Col>
                                <Col lg="4" className='text-right'>
                                  <p className='mb-0'>- ${parseFloat(summaryData?.current?.total_referral_fee_amount).toFixed(2)}</p>
                                </Col>
                                <Col lg="12" className='text-right'><hr className='m-0' /></Col>
                                {parseFloat(summaryData?.current?.total_shipping_price) > 0 &&
                                  <>
                                    <Col lg="8">
                                      <p className='mb-0'>{t('dashboard.shipping_fee')}:</p>
                                    </Col>
                                    <Col lg="4" className='text-right'>
                                      <p className='mb-0'>- ${parseFloat(summaryData.current.total_shipping_price).toFixed(2)}</p>
                                    </Col>
                                    <Col lg="12" className='text-right'><hr className='m-0' /></Col>
                                  </>
                                }
                              </div>
                            </div>
                          </Collapse>
                          <Col lg="8">
                            <p className='mb-0'>COGs:</p>
                          </Col>
                          <Col lg="4" className='text-right'>
                            <p className='mb-0'>- ${parseFloat(summaryData?.current?.total_cost).toFixed(2)}</p>
                          </Col>
                          {parseFloat(summaryData?.current?.total_our_shipping_charges) > 0 &&
                            <>
                              <Col lg="12" className='text-right'><hr className='m-0' /></Col>
                              <Col lg="8">
                                <p className='mb-0'>{t('dashboard.seller_shipping')}:</p>
                              </Col>
                              <Col lg="4" className='text-right'>
                                <p className='mb-0'>- ${parseFloat(summaryData.current.total_our_shipping_charges).toFixed(2)}</p>
                              </Col>
                            </>
                          }
                          <Col lg="12" className='text-right'><hr className='m-0' /></Col>
                          <Col lg="8">
                            <p className='mb-0'>{t('dashboard.net_profit')}:</p>
                          </Col>
                          <Col lg="4" className='text-right'>
                            <p className='mb-0'> ${parseFloat(summaryData?.current?.net_profit).toFixed(2)}</p>
                          </Col>
                        </div>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Button variant="link" className='p-0' size="sm">
                    {t('dashboard.profit_breakdown')}
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default StorePerformance;