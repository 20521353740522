// index.js
import Login from './Login.json';
import Registration from './registration.json';
import PreRegister from './PreRegister.json';
import PageName from './PageName.json';
import SideBar from './Sidebar.json';
import Footer from './Footer.json';
import Dashboard from './Dashboard.json';
import ProductsResearch from './ProductsResearch.json';
import Common from './Common.json';
import PriceCalculaor from './PriceCalculator.json';
import Influencers from './Influencers.json';
import Storelisting from './Storelisting.json';
import Home from './Home.json';
import Pricing from './Pricing.json';
import Affiliate from './Affiliate.json';
import Ourstory from './Ourstory.json';
import ProductsresearchFront from './ProductsresearchFront.json';
import InfluencersFront from './InfluencersFront.json';
import MyTiktokshop from './MyTiktokshop.json';
import ProductListing from './ProductListing.json';
import Header from './Header.json';
import Profile from './Profile.json';
import Coupon from './Coupon.json';
import UserManagement from './UserManagement.json';
import AdminDashboard from './AdminDashboard.json';
import Shops from './Shops.json';
import RegisterFinanceReport from './RegisterFinanceReport.json'
import CreatorCenter from './creatorCenter.json'
import EventLog from './EventLog.json'
import MasterLayout from './MasterLayout.json'
import live from './Live.json';
import InfluencerVideo from './InfluencerVideo.json';
import OneoffQuestion from './OneoffQuestion.json';
import PrintifyProducts from './PrintifyProducts.json'
import UserAnswers from './UserAnswers.json'
import ProductReview from './ProductReviews.json'
import DownloadCenter from './DownloadCenter.json'


const indexTranslations = {
  ...Login,
  ...Registration,
  ...PreRegister,
  ...PageName,
  ...SideBar,
  ...Footer,
  ...Dashboard,
  ...ProductsResearch,
  ...Common,
  ...PriceCalculaor,
  ...Influencers,
  ...Storelisting,
  ...Home,
  ...Pricing,
  ...Affiliate,
  ...Ourstory,
  ...ProductsresearchFront,
  ...InfluencersFront,
  ...MyTiktokshop,
  ...ProductListing,
  ...Header,
  ...Profile,
  ...Coupon,
  ...UserManagement,
  ...AdminDashboard,
  ...Shops,
  ...RegisterFinanceReport,
  ...CreatorCenter,
  ...EventLog,
  ...MasterLayout,
  ...live,
  ...InfluencerVideo,
  ...OneoffQuestion,
  ...PrintifyProducts,
  ...UserAnswers,
  ...ProductReview,
  ...DownloadCenter
};

export default indexTranslations;