import Axios from '../../../plugins/axios';
import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import InfiniteScrollWrapper from "../../Products/InfiniteScrollWrapper";
import { useTranslation } from 'react-i18next';
import Conversion from '../../CommonComponents/Conversion';
import { TikTokEmbed } from 'react-social-media-embed';

const FavoriteSongs = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [favourite, setFavourite] = useState([]);
    const [tikTokEmbedID, setTikTokEmbedID] = useState('');
    const [contentModal, setContentModal] = useState(false);

    const tableHead = [
        { title: t('creator_center.songs_ranking.table.ranking'), className: 'text-left' },
        { title: t('creator_center.songs_ranking.table.songs'), className: 'text-left' },
        { title: t('creator_center.songs_ranking.table.title'), className: 'text-left' },
        { title: t('creator_center.songs_ranking.table.duration'), className: 'text-left' },
        { title: t('creator_center.songs_ranking.table.videos'), className: 'text-left' },
        { title: t('creator_center.songs_ranking.table.hotVideos'), className: 'text-left' },
        { title: t('creator_center.songs_ranking.table.favourite'), className: 'text-left' }
    ];

    const getFavoriteList = async (page) => {
        let params = {
            page: page,
            limit: 20
        }
        let response = await Axios.get('/api/favorite-songs', { params });

        let array = await response.data.result ? response.data.result.map((value, index) => value.song_id) : favourite;
        if (page === 0) {
            setItems(response.data.result);
            setFavourite(array);
        } else {
            setItems(items.concat(response.data.result));
            setFavourite(favourite.concat(array));
        }
        if (response.data.result.length === 0 || response.data.result.length < 20) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    };

    useEffect(() => {
        getFavoriteList(page);
    }, []);

    const functionNext = async () => {
        if (items.length > 0) {
            let number = page + 1;
            setPage(number);
            getFavoriteList(number)
        }
    }

    const markFavourite = async (data) => {
        try {
            await Axios.post('/api/song/favourite', data);
            const index = favourite.indexOf(data.music_id);
            if (index > -1) {
                favourite.splice(index, 1);
            } else {
                favourite.push(data.music_id);
            }
            setFavourite(favourite.concat([]));
        } catch (error) {
            console.error(error.message)
        }
    };
    const formatDuration = (durationInSeconds) => {
        if (!durationInSeconds || isNaN(durationInSeconds)) return '-';
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = durationInSeconds % 60;
        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    };

    const pad = (number) => {
        return number.toString().padStart(2, '0');
    };

    return (
        <>
            <InfiniteScrollWrapper
                isInfiniteScrollOn={true}
                lengthData={items.length}
                functionNext={functionNext}
                hasMore={hasMore}
            >
                <table id="example" className="display w-100 dataTable">
                    <thead>
                        <tr role="row">
                            {tableHead.map((value, index) => (
                                <th key={index} className={(value.className || '')} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((song, index) => (
                            <tr key={index}>
                                <td><p className="ml-4">{index + 1}</p></td>
                                <td>
                                    <div className="d-flex">
                                        <div className="prd-img mr-3">
                                            {song.cover_large_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + song.cover_large_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />}
                                            <Button variant="primary" className="p-0 video-popup-btn" onClick={() => { setTikTokEmbedID(song.id); setContentModal(!contentModal); }}>
                                                <i className="fa fa-play-circle" aria-hidden="true"></i>
                                            </Button>
                                        </div>
                                    </div>
                                </td>
                                <td className="text-left">
                                    <div className="prd-title">
                                        <p>
                                            <OverlayTrigger overlay={<Tooltip>{song.title}</Tooltip>}>
                                                <Link to={'/song/' + song.id + '/details'}>
                                                    <span className='font-weight-normal text-dark'>{song.title.length > 40 ? song.title.substring(0, 40) + '...' : song.title}</span>
                                                </Link>
                                            </OverlayTrigger>
                                        </p>
                                    </div>
                                </td>
                                <td className="text-left duration-cell">{formatDuration(song.duration) || '-'}</td>
                                <td className="text-left">{song?.video_count ? <Conversion value={song?.video_count} /> : '--'}</td>
                                <td className="text-left">
                                    <div className="d-flex justify-content-left align-items-left">
                                        {song.top_video_list && song.top_video_list.map(video => (
                                            <div className="prd-img mx-1">
                                                {video.origin_cover_privatization &&
                                                    <img src={"https://t-img.picturehaven.net/tikmeta/" + video.origin_cover_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />
                                                }
                                                <Button variant="primary" className="p-0 video-popup-btn" onClick={() => { setTikTokEmbedID(video.id); setContentModal(!contentModal); }}>
                                                    <i className="fa fa-play-circle" aria-hidden="true"></i>
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td className="text-left">
                                    <div className="d-flex justify-content-left">
                                        {<Button
                                            variant="outline-dark" size="sm"
                                            className="px-2 py-1"
                                            onClick={() => { markFavourite({ music_id: song.song_id, nickname: song?.nickname }); }}>
                                            <i className={"fa " + (favourite.indexOf(song.song_id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                        </Button>}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </InfiniteScrollWrapper>
            <Modal className="fade video-popup" show={contentModal}>
                <Modal.Body className='p-0'>
                    <div className='d-flex'>
                        <div className='text-center'>
                            <TikTokEmbed url={`https://www.tiktok.com/embed/${tikTokEmbedID}`} width={325} />
                        </div>
                        <div className='px-3'>
                            <div className='d-flex flex-column'>
                                <Button
                                    variant="dark"
                                    className="mb-3"
                                    onClick={() => { setTikTokEmbedID(''); setContentModal(!contentModal); }}
                                >
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default FavoriteSongs;