import Axios from "../../../plugins/axios";
import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal } from "react-bootstrap";
import '../../../../css/storeListing/storeListing.css';
import { Context } from '../../../../MasterLayout';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const InfluancerVideoModal = ({ getInfluencerVideo, update, modalShow, setModalShow, itemToUpdate, setItemToUpdate }) => {
    const { dispatch } = useContext(Context);
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [nameMessage, setNameMessage] = useState('');
    const [imageMessage, setImageMessage] = useState('');
    const [url, setUrl] = useState('');
    const [urlMessage, setUrlMessage] = useState('');
    const [follower, setfollower] = useState('');
    const [followersMessage, setFollowersMessage] = useState('');
    const [priceMessage, setPriceMessage] = useState('');
    const [descriptionMessage, setDescriptionMessage] = useState('');
    const [image, setImage] = useState('');
    const [price, setPrice] = useState([
        {
            price: 1099.00,
            description: '1 x Day / 7 Tiktok Videos x\nweek = $31 each\n\nTotal of 28 videos x month'
        },
        {
            price: 2049.00,
            description: '2 x Day / 7 Tiktok Videos x\nweek = $29 each\n\nTotal of 56 videos x month'
        },
        {
            price: 2319.00,
            description: '3 x Day / 7 Tiktok Videos x\nweek = $22 each\n\nTotal of 84 videos x month'
        },
        {
            price: 2799.00,
            description: '4 x Day / 7 Tiktok Videos x\nweek = $20 each\n\nTotal of 112 videos x month'
        }
    ]);

    useEffect(() => {
        handleSetItems()
    }, [modalShow, itemToUpdate, update])
    const handleSetItems = () => {
        if (itemToUpdate) {
            if (itemToUpdate.name) {
                setName(itemToUpdate.name)
            }
            if (itemToUpdate.tiktok_url) {
                setUrl(itemToUpdate.tiktok_url)
            }
            if (itemToUpdate.price) {
                setPrice(itemToUpdate.price)
            }
            if (itemToUpdate.followers) {
                setfollower(itemToUpdate.followers)
            }
        } else {
            setName('')
            setUrl('');
            setImage('');
            setfollower('')
            setNameMessage('')
            setPriceMessage('')
            setDescriptionMessage('')
            setImageMessage('')
            setPrice([
                {
                    price: 60.00,
                    description: '1 x Tiktok Video'
                },
                {
                    price: 1099.00,
                    description: '1 x Day / 7 Tiktok Videos x\nweek = $31 each\n\nTotal of 28 vids x mo'
                },
                {
                    price: 2049.00,
                    description: '2 x Day / 7 Tiktok Videos x\nweek = $29 each\n\nTotal of 56 vids x mo'
                },
                {
                    price: 2319.00,
                    description: '3 x Day / 7 Tiktok Videos x\nweek = $22 each\n\nTotal of 84 vids x mo'
                },
                {
                    price: 2799.00,
                    description: '4 x Day / 7 Tiktok Videos x\nweek = $20 each\n\nTotal of 112 vids x mo'
                }
            ])
        }
    }

    const createOrUpdate = async () => {
        try {
            if (update && itemToUpdate) {
                const arraysAreEqual = (arr1, arr2) => {
                    if (arr1.length !== arr2.length) {
                        return false;
                    }

                    for (let i = 0; i < arr1.length; i++) {
                        if (arr1[i].price !== arr2[i].price || arr1[i].description !== arr2[i].description) {
                            return false;
                        }
                    }

                    return true;
                };
                if (itemToUpdate.name === name && itemToUpdate.tiktok_url === url && itemToUpdate.followers === follower && !image && arraysAreEqual(itemToUpdate.price_list, price)) {
                    editSomethingToast();
                    return;
                }
            }
            let indices = [];
            for (let i = 0; i < price.length; i++) {
                if (price[i].price === null || price[i].description === "") {
                    indices.push(i);
                }
            }
            if (!name || (!update && !image) || !price[0].price || !price[0].description || indices.length > 0) {
                if (!name) {
                    setNameMessage(t('common.name_message'));
                };
                if (!update && !image) {
                    setImageMessage(t('common.image_message'));
                };
                if (!price[0].price) {
                    setPriceMessage(t('common.price_message'))
                }
                if (!price[0].description) {
                    setDescriptionMessage(t('common.description_message'))
                }
                if (indices.length > 0) {
                    setPriceMessage(t('common.price_message'))
                    setDescriptionMessage(t('common.description_message'))
                }
            } else {
                dispatch({ type: 'loader_show', response: true });

                let formData = new FormData();
                if (image) formData.append("image", image);
                formData.append("objData", JSON.stringify({ name, url, price, follower }));
                let config = { headers: { "Content-Type": "multipart/form-data" } };
                let response;
                if (update) {
                    response = await Axios.patch(`/api/influencer-videos/${itemToUpdate._id}`, formData, config)
                        .catch((error) => {
                            console.error('Axios-Catch: ' + error.message);
                        });
                } else {
                    response = await Axios.post('/api/influencer-videos', formData, config).catch((e) => console.error('Axios-Catch: ' + e.message));
                }
                if (response && response.status == 200) {
                    if (update) {
                        successUpdateToast()
                    } else {
                        successCreateToast()
                    }
                    getInfluencerVideo(1);
                    setName('')
                    setUrl('');
                    setImage('');
                    setfollower('')
                    setModalShow(!modalShow);
                };
            };
        } catch (error) {
            console.error('Error-Catch: ' + error.message);
        };
        dispatch({ type: 'loader_hide', response: true });
    };
    const handleModalClose = () => {
        setName('')
        setUrl('');
        setImage('');
        setNameMessage('')
        setPriceMessage('')
        setDescriptionMessage('')
        setImageMessage('')
        setfollower('')
        setPrice([{
            price: null,
            description: ''
        }])
        setModalShow(!modalShow);
        setItemToUpdate()
    };
    const editSomethingToast = () => {
        toast.warn("Edit something to change", {
            position: 'top-center',
            autoClose: 5000
        });
    };
    const successUpdateToast = () => {
        toast.success("Successfully updated", {
            position: 'top-center',
            autoClose: 5000
        });
    };
    const successCreateToast = () => {
        toast.success("Successfully created", {
            position: 'top-center',
            autoClose: 5000
        });
    };
    const updatePriceAtIndex = (index, newPrice) => {
        setPrice(prevPrice => {
            const updatedPrice = [...prevPrice];
            if (newPrice.hasOwnProperty('price')) {
                updatedPrice[index] = {
                    ...updatedPrice[index],
                    price: newPrice.price
                };
            }
            if (newPrice.hasOwnProperty('description')) {
                updatedPrice[index] = {
                    ...updatedPrice[index],
                    description: newPrice.description
                };
            }
            return updatedPrice;
        });
    };
    const addNewPrice = () => {
        setPrice(prevPrice => [
            ...prevPrice,
            { price: null, description: '' }
        ]);
    };
    const removePriceAtIndex = (indexToRemove) => {
        setPrice(prevPrice => prevPrice.filter((_, index) => index !== indexToRemove));
    };

    return (
        <Modal className="fade bd-example-modal-lg" show={modalShow} size="lg">
            <Modal.Header>
                <Modal.Title>{update ? t('common.update_influencer') : t('common.add_influencer')}</Modal.Title>
                <Button variant="danger" className="close p-3" size="sm" onClick={() => setModalShow(!modalShow)}><i className="fa fa-times" aria-hidden="true"></i></Button>
            </Modal.Header>
            <Modal.Body>
                <div className="input-group mb-3">
                    <div className="col-sm-6">
                        <label htmlFor="name" className="form-label text-sm mb-0">
                            {t('common.name')} <span className="text-danger">*</span>
                        </label>
                        <input
                            id="name"
                            type="text"
                            className={`form-control form-control-sm ${nameMessage ? 'is-invalid' : ''}`}
                            placeholder={t('common.name')}
                            value={name}
                            onChange={(e) => { setName(e.target.value); setNameMessage(''); }}
                            onBlur={() => !name ? setNameMessage(t('common.name_message')) : ''}
                            required
                        />
                        {nameMessage && <div className="invalid-feedback">{nameMessage}</div>}
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="url" className="form-label text-sm mb-0">
                            {t('common.tiktok_url')}
                        </label>
                        <input
                            id="url"
                            type="text"
                            className={`form-control form-control-sm`}
                            placeholder={t('common.tiktok_url')}
                            value={url}
                            onChange={(e) => { setUrl(e.target.value) }}
                        />
                    </div>
                </div>
                <div className="input-group mb-3">
                    <div className="col-sm-6">
                        <label htmlFor="image" className="form-label text-sm mb-0">
                            {t('common.image')} <span className="text-danger">*</span>
                        </label>
                        <input
                            id="image"
                            type="file"
                            accept="image/*"
                            className={`form-control form-control-sm ${imageMessage ? 'is-invalid' : ''}`}
                            placeholder={t('common.image')}
                            onChange={(e) => { setImage(e.target.files[0]); setImageMessage('') }}
                            onBlur={() => !image ? setImageMessage(t('common.image_message')) : ''}
                            required
                        />
                        {imageMessage && <div className="invalid-feedback">{imageMessage}</div>}
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="followers" className="form-label text-sm mb-0">
                            {t('common.followers')}
                        </label>
                        <input
                            id="followers"
                            type="text"
                            className={`form-control form-control-sm`}
                            placeholder={t('common.followers')}
                            value={follower}
                            onChange={(e) => { setfollower(e.target.value) }}
                        />
                    </div>
                </div>
                {price.map((data, index) => (
                    <div className="input-group mb-3" key="0">
                        <div className="col-sm-6">
                            <label htmlFor="price" className="form-label text-sm mb-0">
                                {t('common.price')} <span className="text-danger">*</span>
                            </label>
                            <input
                                id="price"
                                type="number"
                                className={`form-control form-control-sm ${(!price[index].price && priceMessage) ? 'is-invalid' : ''}`}
                                placeholder={`${t('common.price')}`}
                                value={data.price}
                                onChange={(e) => {
                                    updatePriceAtIndex(index, { price: Number(e.target.value) });
                                }}
                                onBlur={() => !data.price ? setPriceMessage(t('common.price_message')) : ''}
                                required
                            />
                            {(!price[index].price && priceMessage) && <div className="invalid-feedback">{priceMessage}</div>}
                        </div>
                        <div className="col-sm-5">
                            <label htmlFor="description" className="form-label text-sm mb-0">
                                {t('common.description')} <span className="text-danger">*</span>
                            </label>
                            <textarea
                                id="description"
                                className={`form-control ${(!price[index].description && descriptionMessage) ? 'is-invalid' : ''}`}
                                placeholder={`${t('common.description')}`}
                                value={data.description}
                                onChange={(e) => {
                                    updatePriceAtIndex(index, { description: e.target.value });
                                }}
                                onBlur={() => !data.description ? setDescriptionMessage(t('common.description_message')) : ''}
                                rows={3}
                                cols={50}
                                required
                            />
                            {(!price[index].description && descriptionMessage) && <div className="invalid-feedback">{descriptionMessage}</div>}
                        </div>

                        {index >= 1 &&
                            <div className="col-sm-1 pt-4">
                                <Button
                                    className="mr-1 px-2 py-1 btn btn-outline-danger btn-sm"
                                    variant=""
                                    size="sm"
                                    onClick={() => removePriceAtIndex(index)}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                            </div>
                        }
                    </div>
                ))}
                <Button className="pull-right" variant="success" size="sm" onClick={addNewPrice}>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                </Button>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-danger" size="sm" onClick={() => handleModalClose()}>{t('common.cancel')}</Button>
                <Button variant="primary" type="button" size="sm" className="btn btn-primary" onClick={() => createOrUpdate()}>{update ? t('common.update') : t('common.create')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default InfluancerVideoModal;