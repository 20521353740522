import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Form, Table } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useTranslation } from 'react-i18next';
import Axios from '../../plugins/axios';
import { Context } from '../../../MasterLayout';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';

function UserAnswers() {
    const { t } = useTranslation();
    const { dispatch } = useContext(Context);

    const [limit, setlimit] = useState(10);
    const [page, setPage] = useState(1);
    const [SearchValue, setSearchValue] = useState('');
    const navigate = useNavigate();
    const [UserAnswersList, setUserAnswersList] = useState([]);

    const [totalEntries, setTotalEntries] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const [sort, setsort] = useState({ createdAt: -1, email: 0, tool: 0 })


    const [userAnswersDaterange, setUserAnswersDaterange] = useState({
        'yesterday': {
            start_date: new Date(new Date().setDate(new Date().getDate() - 1)),
            end_date: new Date()
        },
        'last_7_days': {
            start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
            end_date: new Date()
        },
        'last_30_days': {
            start_date: new Date(new Date().setDate(new Date().getDate() - 30)),
            end_date: new Date()
        }
    });
    const [dateRange, setDateRange] = useState({ start_date: new Date(userAnswersDaterange['last_30_days'].start_date), end_date: new Date(userAnswersDaterange['last_30_days'].end_date) });
    function currentSortValue() {
        let soryBy
        let sortValue

        if (sort.email) {
            soryBy = "email"
            sortValue = sort.email
        } else if (sort.tool) {
            soryBy = "tool"
            sortValue = sort.tool
        }
        else if (sort.createdAt) {
            soryBy = "createdAt"
            sortValue = sort.createdAt
        }


        return { soryBy, sortValue }
    }
    const fetchdata = async (sortid = 'createdAt', sortascdsc, searchvalue = null) => {
        let response = await Axios.get(`/api/useranswers?page=${page}&limit=${limit}&sortby=${sortid}&sort=${sortascdsc || 1}&search=${SearchValue}&startDate=${new Date(userAnswersDaterange.last_30_days.start_date)}&endDate=${new Date(userAnswersDaterange.last_30_days.end_date)}`);
        dispatch({ type: 'subscription_expired', response: response.data.statusCode });

        if (response && response.data && response.data.result) {
            const { results, totalCount } = response?.data?.result
            setUserAnswersList(results ? results : [])
            setTotalEntries(totalCount ? totalCount : 0);
            setPageCount(Math.ceil(totalCount / limit));
        } else {
            console.error("Failed to fetch data");
        }
    }

    useEffect(() => {
        const { soryBy, sortValue } = currentSortValue()
        fetchdata(soryBy, sortValue, SearchValue);
    }, [page, limit, userAnswersDaterange]);

    //PAGINATION AND LIMIT 
    const handlePageClick = (event) => {
        setPage(event.selected + 1);
    };
    const onClickSetLimit = (e) => {
        if (limit !== e.target.getAttribute('value')) {
            setlimit(e.target.getAttribute('value'));
            setPage(1);
        }
    };

    const handlsearchEmailvalue = (e) => {
        const value = e.target.value
        setSearchValue(value)
    }
    const handleEnterKeyPress = async (e) => {
        if (e.key === 'Enter') {
            setPage(1);
            let response = await Axios.get(`/api/useranswers?page=${page}&limit=${limit}&sortby=${"email"}&sort=${1}&search=${SearchValue}&startDate=${new Date(userAnswersDaterange.last_30_days.start_date)}&endDate=${new Date(userAnswersDaterange.last_30_days.end_date)}`);

            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.data && response.data.result) {
                const { results, totalCount } = response?.data?.result
                setUserAnswersList(results ? results : [])
                setTotalEntries(totalCount ? totalCount : 0);
                setPageCount(Math.ceil(totalCount / limit));
            } else {
                setUserAnswersList([]);
                console.error("Failed to fetch data");
            }
        }
    };

    const handleDateRange = (name, filter) => {
        let dateRangeFilter = { ...userAnswersDaterange };
        dateRangeFilter[name] = filter;
        setUserAnswersDaterange(dateRangeFilter);
    };

    const handleApply = (event, picker) => {
        picker.element.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        setDateRange({ start_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
        handleDateRange("last_30_days", { start_date: picker.startDate.toISOString(), end_date: picker.endDate.toISOString() })
    };

    const handleSort = (e) => {
        const sortid = e.target.id;
        setPage(1);
        if (sortid === "email") {
            if (sort.email === 1) {
                setsort({ email: -1, createdAt: 0, tool: 0 })
            } else {
                setsort({ email: 1, createdAt: 0, tool: 0 })
            }
            fetchdata(sortid, sort.email === 1 ? -1 : 1, SearchValue)
        }
        else if (sortid === "createdAt") {
            if (sort.createdAt === 1) {
                setsort({ email: 0, createdAt: -1, tool: 0 })
            } else {
                setsort({ email: 0, createdAt: 1, tool: 0 })
            }
            fetchdata(sortid, sort.createdAt === 1 ? -1 : 1, SearchValue)
        }
        else if (sortid === "tool") {
            if (sort.tool === 1) {
                setsort({ email: 0, createdAt: 0, tool: -1 })
            } else {
                setsort({ email: 0, createdAt: 0, tool: 1 })
            }
            fetchdata(sortid, sort.tool === 1 ? -1 : 1, SearchValue)
        }

    }
    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    <div className="d-flex">
                        <div className="dropdown ml-2">
                            <button className="btn btn-outline-dark dropdown-toggle btn-sm" type="button" data-toggle="dropdown" aria-expanded="false">
                                {limit}
                            </button>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" href="#" value='10' onClick={onClickSetLimit}>10</a>
                                <a className="dropdown-item" href="#" value='25' onClick={onClickSetLimit}>25</a>
                                <a className="dropdown-item" href="#" value='50' onClick={onClickSetLimit}>50</a>
                                <a className="dropdown-item" href="#" value='100' onClick={onClickSetLimit}>100</a>
                            </div>
                        </div>
                        <Col className="text-right">
                            <DateRangePicker
                                initialSettings={
                                    {
                                        startDate: moment(new Date(dateRange.start_date)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_date)).format('MM/DD/YYYY'),
                                        ranges: {
                                            Today: [moment().toDate(), moment().toDate()],
                                            Yesterday: [
                                                moment().subtract(1, 'days').toDate(),
                                                moment().subtract(1, 'days').toDate(),
                                            ],
                                            'Last 7 Days': [
                                                moment().subtract(6, 'days').toDate(),
                                                moment().toDate(),
                                            ],
                                            'Last 30 Days': [
                                                moment().subtract(29, 'days').toDate(),
                                                moment().toDate(),
                                            ],
                                            'This Month': [
                                                moment().startOf('month').toDate(),
                                                moment().endOf('month').toDate(),
                                            ],
                                            'Last Month': [
                                                moment().subtract(1, 'month').startOf('month').toDate(),
                                                moment().subtract(1, 'month').endOf('month').toDate(),
                                            ],
                                        },
                                    }}
                                onApply={handleApply}
                            >
                                <input type="text" className="form-control w-auto form-control-sm input-daterange-timepicker pull-right" />
                            </DateRangePicker>
                        </Col>
                        <Form.Control className="w-auto form-control-sm" onKeyPress={handleEnterKeyPress} value={SearchValue} onChange={(e) => handlsearchEmailvalue(e)} placeholder="Search" />
                    </div>
                </Card.Title>

                <Table responsive className="display w-100 dataTable">
                    <thead>
                        <tr role="row">
                            <th className={`text-center sorting ${(sort.createdAt === 1 ? "sorting-asc" : '') || (sort.createdAt === -1 ? "sorting-desc" : '')}`} onClick={(e) => { handleSort(e) }} id="createdAt">{t('UserAnswers.date')} &nbsp;<span id="createdAt" /></th>
                            <th className={`text-center sorting ${(sort.email === 1 ? "sorting-asc" : '') || (sort.email === -1 ? "sorting-desc" : '')}`} onClick={(e) => { handleSort(e) }} id="email">{t('UserAnswers.email')} &nbsp;<span id="email" /></th>
                            <th className={`text-center sorting ${(sort.tool === 1 ? "sorting-asc" : '') || (sort.tool === -1 ? "sorting-desc" : '')}`} onClick={(e) => { handleSort(e) }} id="tool">{t('UserAnswers.tool')} &nbsp;<span id="tool" /></th>
                            <th >{t('UserAnswers.assigned')}</th>
                            <th >{t('UserAnswers.actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {UserAnswersList.map((data, i) => (
                            <tr style={{ height: "3.5rem" }} key={i}>
                                <td>{data?.createdAt}</td>
                                <td>{data?.user?.email}</td>
                                <td>{data?.influancer_video}</td>
                                <td>--</td>
                                <td><Button
                                    onClick={() => navigate('/oneoffuser/question-answer', { state: data })}
                                >See</Button></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                        {t('common.showing')} {totalEntries ? (((page - 1) * limit) + 1) : 0} {t('common.to')}{" "}
                        {Math.min(page * limit, totalEntries) || 0} {t('common.of')} {totalEntries} {t('common.entries')}
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers">
                        <ReactPaginate
                            nextLabel="Next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={1}
                            marginPagesDisplayed={1}
                            pageCount={pageCount}
                            previousLabel="< Previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                            forcePage={page - 1}
                        />
                    </div>
                </div>
            </Card.Body>
        </Card>

    )
}

export default UserAnswers
