import React, { useEffect, useState, useContext } from 'react'
import InfiniteScrollWrapper from './InfiniteScrollWrapper'
import { Button, Card, Modal, OverlayTrigger, Popover, Table, Tooltip } from 'react-bootstrap'
import Axios from '../../plugins/axios';
import { TikTokEmbed } from 'react-social-media-embed';
import Conversion from '../CommonComponents/Conversion';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import { Context } from '../../../MasterLayout';
import { useTranslation } from 'react-i18next';


function FavoriteProductVideo() {
    const { t } = useTranslation();
    const { dispatch } = useContext(Context);
    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [favourite, setFavourite] = useState([]);

    const [contentModal, setContentModal] = useState(false);
    const [tikTokEmbedID, setTikTokEmbedID] = useState('');

    //COLOUMNS
    const tableHead = [
        {
            title: t('product_research.product_video.favorite.video'),
        },
        {
            title: t('product_research.product_video.favorite.influencer'),
            className: 'text-left',
        },
        {
            title: t('product_research.product_video.favorite.products'),
            className: 'text-left',
        },
        {
            title: t('product_research.product_video.favorite.sales'),
            className: 'text-left',
        },
        {
            title: t('product_research.product_video.favorite.revenue'),
            className: 'text-left',
        },
        {
            title: t('product_research.product_video.favorite.last_period_sales'),
            className: 'text-left',
        },
        {
            title: t('product_research.product_video.favorite.views'),
            className: 'text-left',
        },
        {
            title: t('product_research.product_video.favorite.Favourite'),
            className: 'text-left',
        },
    ]


    const getFavoriteList = async (page) => {
        let params = {
            page: page,
            limit: 20
        }
        let response = await Axios.get('/api/favorite-products-video', { params });
        dispatch({ type: 'subscription_expired', response: response.data.statusCode });
        if (response && response.status === 200 && response.data.result) {
            let array = await response.data.result ? response.data.result.map((value, index) => value.video_id) : favourite;
            if (page == 0) {
                setItems(response.data.result);
                setFavourite(array);
            } else {
                setItems(items.concat(response.data.result));
                setFavourite(favourite.concat(array));
            }
            if (response?.data?.result?.length === 0 || response?.data?.result?.length < 20) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
        }
    };
    useEffect(() => {
        setItems([]);
        setPage(0);
        getFavoriteList(0);
    }, []);

    const functionNext = async () => {
        if (items.length > 0) {
            let number = page + 1;
            setPage(number);
            getFavoriteList(number)
        }
    }

    const markFavourite = async (data) => {
        await Axios.post('/api/favorite-product-video', data);
        const index = favourite.indexOf(data.id);
        if (index > -1) {
            favourite.splice(index, 1);
        } else {
            favourite.push(data.id);
        }

        setFavourite(favourite.concat([]));
    };

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1
    };


    return (
        <div>
            <Card>
                <Card.Body className='p-2'>
                    <Table responsive className="w-100">
                        <div id="example_wrapper" className="dataTables_wrapper">
                            <InfiniteScrollWrapper
                                isInfiniteScrollOn={true}
                                lengthData={items?.length}
                                functionNext={functionNext}
                                hasMore={hasMore}
                            >
                                <table id="example" className="display w-100 dataTable">
                                    <thead>
                                        <tr role="row">
                                            {tableHead.map((value, index) => (
                                                <th className={value.className || ''} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items?.length ? items.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className="d-flex">
                                                        <div className="prd-img mr-3">
                                                            <Link to={'/products/' + product.video_id + "/video-details"} target="_blank">
                                                                {product.origin_cover_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + product.origin_cover_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />}
                                                            </Link>
                                                            <Button
                                                                variant="primary"
                                                                className="p-0 video-popup-btn"
                                                                onClick={() => { setTikTokEmbedID(product.id); setContentModal(!contentModal); }}
                                                            >
                                                                <i className="fa fa-play-circle" aria-hidden="true"></i>
                                                            </Button></div>
                                                        <div className="prd-title">
                                                            <p>
                                                                <OverlayTrigger overlay={<Tooltip>{product.desc}</Tooltip>}>
                                                                    <Link to={'/products/' + product?.video_id + '/video-details'} target="_blank">
                                                                        <span>{product.desc.length > 40 ? product.desc.substring(0, 40) + '...' : product.desc}</span>
                                                                    </Link>
                                                                </OverlayTrigger>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex">
                                                        <div className="prd-img mr-3">
                                                            {/* {product.avatar_larger_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + product.avatar_larger_privatization + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />} */}
                                                            <OverlayTrigger
                                                                key="right"
                                                                trigger={["hover", "focus"]}
                                                                placement="right"
                                                                rootClose
                                                                overlay={
                                                                    <Popover>
                                                                        <Popover.Body>

                                                                            {product.avatar_larger_privatization &&
                                                                                <img alt="" width={500} src={`https://t-img.picturehaven.net/tikmeta/${product.avatar_larger_privatization}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                                            }
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <Button variant="link" className='p-0' size="sm">
                                                                    <Link to={'/influencers/details/' + product.author_id + "/breakout"} target="_blank">
                                                                        {product.avatar_larger_privatization &&
                                                                            <img alt="" width={70} className="prod-thum-img" src={`https://t-img.picturehaven.net/tikmeta/${product.avatar_larger_privatization}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                                        }
                                                                    </Link>
                                                                </Button>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className="prd-title">
                                                            <Link to={'/influencers/details/' + product.author_id + "/breakout"} target="_blank">
                                                                <p className="mb-0">{product.author_nickname}</p>
                                                            </Link>
                                                            <p className="mb-0 text-muted"><small>{product.sys_country?.country}</small></p>
                                                            <p className="mb-0 text-muted"><small>Followers: <Conversion value={product.author_follower_count || 0} /></small></p>
                                                            <span className={`fi fi-${product?.country_code.toLowerCase()} mr-1`}></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <Slider className='table-sl-slider' {...settings}>
                                                        {product?.product_list?.map((carousel, i) => (
                                                            <div className='product-slider'>
                                                                <div className='d-flex align-items-start'>
                                                                    <OverlayTrigger
                                                                        key="right"
                                                                        trigger={["hover", "focus"]}
                                                                        placement="right"
                                                                        rootClose
                                                                        overlay={
                                                                            <Popover>
                                                                                <Popover.Body>
                                                                                    {carousel?.images_privatization[0] && <img src={"https://t-img.picturehaven.net/product/" + carousel.images_privatization[0] + "?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1"} className="d-block" width={500} alt={`slide`} />}
                                                                                </Popover.Body>
                                                                            </Popover>
                                                                        }
                                                                    >
                                                                        <Link to={'/products/' + carousel.product_id + "/details"} target="_blank">
                                                                            {carousel?.images_privatization[0] && <img src={"https://t-img.picturehaven.net/product/" + carousel.images_privatization[0] + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />}
                                                                        </Link>
                                                                    </OverlayTrigger>
                                                                    <div className="carousel-caption">
                                                                        <Link to={'/products/' + carousel.product_id + "/details"} target="_blank">
                                                                            <p className='mb-0'>{carousel.title}</p>
                                                                        </Link>
                                                                        <p className='mb-0'><small>{carousel.min_price_usd}</small></p>
                                                                        <p className='mb-0'><small>Revenue: {carousel.estimated_sales_amount_usd}</small></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </Slider>
                                                </td>
                                                <td className="text-right">
                                                    {product.total_estimated_sales_volume_str}
                                                </td>
                                                <td className="text-right">
                                                    {product.total_estimated_sales_amount_usd}<br />
                                                    <span className="text-muted">({product.total_estimated_sales_amount_str})</span>
                                                </td>
                                                <td className="text-right">
                                                    <Conversion value={product.last_sold_count || 0} />
                                                </td>
                                                <td className="text-right">
                                                    <Conversion value={product.play_count || 0} />
                                                </td>

                                                <td className="text-right">
                                                    <div className="d-flex justify-content-end">
                                                        <div className="mr-1">
                                                            {<Button
                                                                variant="outline-dark" size="sm"
                                                                className="px-2 py-1"
                                                                onClick={() => { markFavourite({ id: product.video_id, country_code: product.country_code }); }}>
                                                                <i className={"fa " + (favourite.indexOf(product.video_id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                                            </Button>}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>)) : 'No Favorite data Found'}
                                    </tbody>
                                </table>
                            </InfiniteScrollWrapper>
                        </div>
                    </Table>
                </Card.Body>
            </Card>

            {/* <!-- Video Modal --> */}
            <Modal className="fade video-popup" show={contentModal}>
                <Modal.Body className='p-0'>
                    <div className='d-flex'>
                        <div className='text-center'>
                            <TikTokEmbed url={`https://www.tiktok.com/embed/${tikTokEmbedID}`} width={325} />
                        </div>
                        <div className='px-3'>
                            <div className='d-flex flex-column'>
                                <Button
                                    variant="dark"
                                    className="mb-3"
                                    onClick={() => { setTikTokEmbedID(''); setContentModal(!contentModal); }}
                                >
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                                {/* <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-line-chart" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-download" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-file-code-o" aria-hidden="true"></i>
                        </Button> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )

}

export default FavoriteProductVideo