import React, { useEffect, useState, useContext } from 'react'
import { Button, ButtonGroup, Card, Form, Table } from 'react-bootstrap';
import RangeSlider from '../../Products/RangeSlider';
import Category from '../../Products/Category';
import Axios from '../../../plugins/axios';
import BreakoutInfluencers from './BreakoutInfluencers';
import TopVideoCreators from './TopVideoCreators';
import TopLiveStreamers from './TopLiveStreamers';
import { Context } from '../../../../MasterLayout';
import { useTranslation } from 'react-i18next';
import Export from '../../CommonComponents/export';


function InfluencerFilter({ tab, defaultCycle, rankType }) {
    const { t } = useTranslation();

    const { dispatch } = useContext(Context);
    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [countryCode, setCountryCode] = useState('US');
    const [category1, setCategory1] = useState('');
    const [category2, setCategory2] = useState('');
    const [category3] = useState('');
    const [categoryId1, setCategoryId1] = useState('');
    const [categoryId2, setCategoryId2] = useState('');
    const [categoryId3] = useState('');
    const [cycle, setCycle] = useState(defaultCycle);
    const [version, setVersion] = useState('');
    const [versionList, setVersionList] = useState([]);
    const [typeList] = useState([
        { name: "Art", namecn: "文学艺术" },
        { name: "Beauty Care", namecn: "美妆护理" },
        { name: "Brand", namecn: "品牌" },
        { name: "Celebrities", namecn: "网红名人" },
        { name: "Entertainment", namecn: "chin" },
        { name: "Effects & Montage", namecn: "chin" },
        { name: "Food", namecn: "chin" },
        { name: "Fashion", namecn: "chin" },
        { name: "Fashion", namecn: "chin" },
        { name: "Finance", namecn: "chin" },
        { name: "Games", namecn: "chin" },
        { name: "Government", namecn: "chin" },
        { name: "Health", namecn: "chin" },
        { name: "Key Option Leader", namecn: "chin" },
    ]);
    const [typeValue, settypeValue] = useState("Type")
    const [favourite, setFavourite] = useState([]);
    const [revenueRange, setRevenueRange] = useState([]);
    const [AtvRange, setAtvRange] = useState([]);
    const [salesRange, setSalesRange] = useState([]);
    const [followersRange, setFollowersRange] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [payload, setPayload] = useState({});
    let sortbytab
    if (tab === 'breakout-Influencers') {
        sortbytab = 23;
    } else if (tab === "live-video-Influencers") {
        sortbytab = 19;
    } else if (tab === "live-stream-Influencers") {
        sortbytab = 60;
    }
    const [sorting, setSorting] = useState({ sort: sortbytab, sort_type: 'DESC' });
    const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';
    const sortType = sorting.sort_type == 'DESC' ? 'ASC' : 'DESC';

    const handleSort = async (sort, sortType) => {
        if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
            setSorting({ sort, sort_type: sortType });
        }
    };


    const cycleList = {
        'breakout-Influencers': {
            'H24': '1 Day',
            'W1': 'Weekly',
            'M1': 'Monthly',
        },
        'live-video-Influencers': {
            'H24': '1 Day',
            'W1': 'Weekly',
            'M1': 'Monthly',
        },
        'live-stream-Influencers': {
            'H24': '1 Day',
            'W1': 'Weekly',
            'M1': 'Monthly',
        },
    };

    const endPointURL = {
        'breakout-Influencers': '/api/influencers/top-sales-breakout',
        'live-video-Influencers': '/api/influencers/top-video-creators',
        'live-stream-Influencers': '/api/influencers/top-live-streamers',
    }


    const priceRangeOption = {
        '0-1': '≤$1',
        '1-5': '$1-$5',
        '5-10': '$5-$10',
        '10-20': '$10-$20',
        '20-50': '$20-$50',
        '50-100': '$50-$100',
        "100-": '≥$100',
    }

    useEffect(() => {
        //do operation on state change
        setItems([]);
        setPage(0);
        if (version == '') {
            getVersion(cycle);
        } else {
            influencerSearch(0);
        }
    }, [sorting, category1, category2, category3, countryCode, typeValue, revenueRange, AtvRange, salesRange, followersRange, selectedOptions, version]);


    const markFavourite = async (data) => {
        try {
            await Axios.post('/api/favorite-influencer', data);
            const index = favourite.indexOf(data.influencer_id);
            if (index > -1) {
                favourite.splice(index, 1);
            } else {
                favourite.push(data.influencer_id);
            }
            setFavourite(favourite.concat([]));
        } catch (error) {
            console.error(error.message)
        }
    };

    const handleCategory = async (categoryIds, categoryNames) => {
        setCategory1(categoryNames[0] ? categoryNames[0] : '');
        setCategory2(categoryNames[1] ? categoryNames[1] : '');
        setCategoryId1(categoryIds[0] ? categoryIds[0] : '');
        setCategoryId2(categoryIds[1] ? categoryIds[1] : '');
    };

    const selectCountry = async (countryCode) => {
        setCountryCode(countryCode);
    };

    const getVersion = async (cycle) => {
        let params = {
            cycle
        }
        if (rankType) {
            params['rank_type'] = rankType;
        }
        let response = await Axios.get('/api/version', { params });
        if (response.data.data) {
            setVersion(response.data.data[0].version);
        }
        setVersionList(response.data.data ? response.data.data : []);
    };

    const influencerSearch = async (page) => {
        try {
            let params = {
                sort: sorting.sort,
                sort_type: sorting.sort_type,
                page,
                cycle,
                version
            };
            if (countryCode != '') {
                params['country_code'] = countryCode;
            }
            if (category1 != '') {
                params['category1'] = category1;
            }
            if (category2 != '') {
                params['category2'] = category2;
            }
            if (category3 != '') {
                params['category3'] = category3;
            }
            if (categoryId1 != '') {
                params['category_id1'] = categoryId1;
            }
            if (categoryId2 != '') {
                params['category_id2'] = categoryId2;
            }
            if (categoryId3 != '') {
                params['category_id3'] = categoryId3;
            }

            //Revenue Range 
            if (revenueRange.to || revenueRange.from) {
                params['sales_amount_start'] = revenueRange.from;
            }
            if (revenueRange.to && revenueRange.to != '') {
                params['sales_amount_end'] = revenueRange.to;
            }
            //Revenue Range end

            //ATV Range 
            if (AtvRange.to || AtvRange.from) {
                params['estimated_avg_price_start'] = AtvRange.from;
            }
            if (AtvRange.to && AtvRange.to != '') {
                params['estimated_avg_price_end'] = AtvRange.to;
            }
            //ATV Range end

            //Sales Range
            if (salesRange.to || salesRange.from) {
                params['sold_count_start'] = salesRange.from;
            }
            if (salesRange.to && salesRange.to != '') {
                params['sold_count_end'] = salesRange.to;
            }
            //Sales Range end

            //Followers Range
            if (followersRange.to || followersRange.from) {
                params['fan_start'] = followersRange.from;
            }
            if (followersRange.to && followersRange.to != '') {
                params['fan_end'] = followersRange.to;
            }
            //Followers Range end

            //Contact Information
            if (selectedOptions.length) {
                let contactType = selectedOptions.join(',');
                params['contact_type'] = contactType;
            }
            //Contact Information end
            if (typeValue) {
                params['category'] = typeList.find(e => e.name === typeValue)?.namecn;
            }
            setPayload(params)
            let response = await Axios.post(endPointURL[tab], params);

            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            if (response && response.status === 200 && response.data.data) {
                let array = await response.data.favorite ? response.data.favorite.map((value, index) => value.influencer_id) : favourite;
                if (page == 0) {
                    setItems(response.data.data);
                    setFavourite(array);
                } else {
                    setItems(items.concat(response.data.data));
                    setFavourite(favourite.concat(array));
                }
                if (response.data.data.length === 0 || response.data.data.length < 20) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        };
    }


    const functionNext = async () => {
        let number = page + 1;
        setPage(number);
        influencerSearch(number)
    }
    const setSingleCategory = async (e) => {
        setCategory1(e.category);
        setCategoryId1(e.id);
    }

    const regionList = Object.keys(global.config.region).map(function (key) {
        return <a key={key} className={"btn my-1 " + (countryCode == key ? 'btn-primary' : 'btn-outline-dark') + " btn-sm mr-2 d-flex align-items-center mb-2"} onClick={() => { selectCountry(key); }}><span className={`fi fi-${key.toLocaleLowerCase()} mr-1`}></span>{global.config.region[key]}</a>
    });

    const cycleHtml = Object.keys(cycleList[tab]).map(function (key) {
        return <Button key={key} variant={"outline-primary " + (cycle == key ? 'btn-primary' : '')} size="sm" onClick={() => { setCycle(key); getVersion(key); }}>{cycleList[tab][key]}</Button>
    });

    // 0,3,4,2,5,1
    const options = [
        { id: 1, contactValue: 0, label: 'Email' },
        { id: 2, contactValue: 3, label: 'Instagram' },
        { id: 3, contactValue: 4, label: 'Twitter' },
        { id: 4, contactValue: 2, label: 'WhatsApp' },
        { id: 5, contactValue: 5, label: 'Youtube' },
        { id: 6, contactValue: 1, label: 'Zalo' }
    ];

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionChange = (event) => {
        const optionId = parseInt(event.target.value);
        const isChecked = event.target.checked;
        if (isChecked) {
            setSelectedOptions([...selectedOptions, optionId]);
        } else {
            setSelectedOptions(selectedOptions.filter((id) => id !== optionId));
        }
    };
    const longText = {
        "breakout-Influencers": t('common.long_text_1'),
        "live-video-Influencers": t('common.long_text_3'),
        "live-stream-Influencers": t('common.long_text_4')
    }

    return (
        <div>
            <Card className='rounded-0'>
                <Card.Body>
                    <div className='topfilter'>
                        <p><small>{longText[tab]}</small></p>
                        <div className="d-flex align-items-center flex-wrap mb-2">
                            <p className="m-0 mr-3"><strong>{t('common.country')}</strong></p>
                            {regionList}
                        </div>
                        <Category singleCategory={tab == "live-video-Influencers" ? false : true} setSingleCategory={setSingleCategory} handleCategory={handleCategory} categoryId1={categoryId1} />
                        <div className="d-flex align-items-center flex-wrap mb-3">
                            <p className="m-0 mr-3"><strong>{t('common.filters')}</strong></p>
                            {tab != "live-video-Influencers" && <div className="dropdown product-rating-menu">
                                <a className="btn btn-outline-dark btn-sm mr-2 dropdown-toggle"
                                    role="button"
                                    id="dropdownMenuLink"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('common.revenue')}
                                </a>
                                <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                    <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={10000000} rangeOption={global.config.rangeOption} rangeOptionName={'views'} step={10} handleUpdate={(e) => { setRevenueRange(e) }} />
                                </div>
                            </div>}
                            {tab == "live-video-Influencers" && <div className="dropdown product-rating-menu">
                                <a className="btn btn-outline-dark btn-sm mr-2 dropdown-toggle"
                                    role="button"
                                    id="dropdownMenuLink"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('common.atv')}
                                </a>
                                <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                    <RangeSlider min={0} max={50000000} defaultMin={0} defaultMax={50000000} rangeOption={priceRangeOption} rangeOptionName={'price'} step={10} handleUpdate={(e) => { setAtvRange(e) }} />
                                </div>
                            </div>}
                            {tab != 'live-video-Influencers' && <div className="dropdown product-rating-menu">
                                <a className="btn btn-outline-dark btn-sm mr-2 dropdown-toggle"
                                    role="button"
                                    id="dropdownMenuLink"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('common.sales')}
                                </a>
                                <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                    <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={10000000} rangeOption={global.config.rangeOption} rangeOptionName={'views'} step={10} handleUpdate={(e) => { setSalesRange(e) }} />
                                </div>
                            </div>}
                            {tab == 'live-video-Influencers' &&
                                <div className="dropdown product-rating-menu">
                                    <div className="form-group mb-0 mr-3">
                                        <select className="form-control form-control-sm" onChange={(e) => { settypeValue(e.target.value); }}>
                                            {typeList.map((value, index) => (
                                                <option key={index} value={value.name}>{value.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            }
                            <div className="dropdown product-rating-menu">
                                <a className="btn btn-outline-dark btn-sm mr-2 dropdown-toggle"
                                    role="button"
                                    id="dropdownMenuLink"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('common.followers')}
                                </a>
                                <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                    <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={10000000} rangeOption={global.config.rangeOption} rangeOptionName={'views'} step={10} handleUpdate={(e) => { setFollowersRange(e) }} />
                                </div>
                            </div>

                            <div className={`dropdown ${isOpen ? 'show' : ''}`}>
                                <button
                                    className="btn btn-outline-dark btn-sm dropdown-toggle d-flex align-items-center "
                                    type="button"
                                    id="multiSelectDropdown"
                                    onClick={toggleDropdown}
                                >
                                    {t('common.contact_information')}
                                </button>
                                <div style={{ width: "100%" }} className={`dropdown-menu ${isOpen ? 'show' : ''}`} aria-labelledby="multiSelectDropdown">
                                    {options.map((option, i) => (
                                        <Form.Check
                                            style={{ marginLeft: "10%" }}
                                            key={option.id}
                                            type="checkbox"
                                            id={`option_${option.id}`}
                                            label={option.label}
                                            checked={selectedOptions.includes(option.contactValue)}
                                            onChange={handleOptionChange}
                                            value={option.contactValue}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center mb-2">
                            <ButtonGroup aria-label="Basic example" className='mr-2'>
                                {cycleHtml}
                            </ButtonGroup>
                            <div className="dropdown product-rating-menu">
                                <div className="form-group mb-0 mr-3">
                                    <select className="form-control form-control-sm" onChange={(e) => { setVersion(e.target.value); }} value={version}>
                                        {versionList.map((value, index) => (
                                            <option key={index} value={value.version}>{value.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <p className='text-muted mb-0'><small>{t('common.long_text_2')}</small></p>
                        </div>
                        <div className="d-flex justify-content-end align-items-center mx-2 my-3">
                  <Export payload={payload} data_source={tab === 'breakout-Influencers' ? "Breakout Influencers" : tab === 'live-video-Influencers' ? "Top Video Creators" : tab === 'live-stream-Influencers' ? "Top Live Streamers" : ""} type="Influencer"/>
                  </div>
                    </div>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body className='p-2'>
                    <Table responsive className="w-100">
                        <div id="example_wrapper" className="dataTables_wrapper">
                            {tab == 'breakout-Influencers' && <BreakoutInfluencers sortType={sortType} handleSort={handleSort} sortClass={sortClass} sorting={sorting} functionNext={functionNext} items={items} favourite={favourite} markFavourite={markFavourite} hasMore={hasMore} />}
                            {tab == 'live-video-Influencers' && <TopVideoCreators sortType={sortType} handleSort={handleSort} sortClass={sortClass} sorting={sorting} functionNext={functionNext} items={items} favourite={favourite} markFavourite={markFavourite} hasMore={hasMore} />}
                            {tab == 'live-stream-Influencers' && <TopLiveStreamers sortType={sortType} handleSort={handleSort} sortClass={sortClass} sorting={sorting} functionNext={functionNext} items={items} favourite={favourite} markFavourite={markFavourite} hasMore={hasMore} />}
                        </div>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    )
}

export default InfluencerFilter