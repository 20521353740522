import React from "react";
import "../../css/landing/landing.css";
import "../../css/landing/bootstrap.min.css";
import "../../css/landing/glightbox.min.css";
import "../../css/landing/main.css";
import "../../css/landing/tiny-slider.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useTranslation } from 'react-i18next';

const Pricing = () => {
   const { t } = useTranslation();
   return (
      <>

         <Header />
         <section id="home" className="hero-area hero-inner-area py-5">
            <div className="container">
               <div className="row align-items-center">

               </div>
            </div>
         </section>

         <section id="pricing" className="pricing-table section">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="section-title mb-0 px-0">
                        <h2 className="wow fadeInUp">{t('pricing.choose_your_tikshop_plan')}</h2>
                        <p className="wow fadeInUp">{t('pricing.experience_the_full_potential')}</p>
                     </div>
                  </div>
               </div>
               <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6 col-12">
                     <div className="single-table wow fadeInUp">
                        <div className="table-head">
                           <h3>{t('pricing.standard_monthly')}</h3>
                           <div className="price p-0">
                              <h2 className="amount text-primary">$67<span className="duration">/{t('pricing.monthly')}</span></h2>
                           </div>
                           <div className="button mb-3">
                              <a href="/pre-register" className="btn">{t('pricing.start_free_trial')}</a>
                           </div>
                           <p className="mb-0"><strong>{t('pricing.then_just_per_month')}</strong></p>
                           <p className="mb-0">{t('pricing.no_contract')}</p>
                        </div>
                        <div className="table-content">
                           <h4 className="middle-title mb-2">{t('pricing.whats_included')}</h4>
                           <ul className="table-list">
                              <li className="mb-1"><i className="fa fa-check text-success"></i> {t('pricing.access_to_product_research')}</li>
                              <li className="mb-1"><i className="fa fa-check text-success"></i> {t('pricing.access_to_influencer_research')}</li>
                              <li className="mb-1"><i className="fa fa-check text-success"></i> {t('pricing.access_to_profit_loss_dashboard')}</li>
                              <li className="mb-1"><i className="fa fa-check text-success"></i> {t('pricing.access_to_profitability_calculator')}</li>
                              <li className="mb-1"><i className="fa fa-check text-success"></i> {t('pricing.weekly_email_broadcast')}</li>
                              <li className="mb-1"><i className="fa fa-check text-success"></i> {t('pricing.tiktok_shop_cheat_sheet')}</li>
                              <li className="mb-1"><i className="fa fa-check text-success"></i> {t('pricing.comprehensive_tool_tutorials')}</li>
                           </ul>
                           <hr />
                           <p className="mb-0">{t('pricing.day_money_back_guarantee')}</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                     <div className="single-table wow fadeInUp">
                        <div className="table-head">
                           <h3>{t('pricing.standard_annually')}</h3>
                           <div className="price p-0">
                              <h2 className="amount text-primary">$697<span className="duration">/{t('pricing.annually')}</span></h2>
                           </div>
                           <div className="button mb-3">
                              <a href="/pre-register" className="btn">{t('pricing.start_free_trial')}</a>
                           </div>
                           <p className="mb-0"><strong>{t('pricing.then_just_per_month_2')}</strong></p>
                           <p className="mb-0">{t('pricing.no_contract')}</p>
                        </div>
                        <div className="table-content">
                           <h4 className="middle-title mb-2">{t('pricing.whats_included')}</h4>
                           <ul className="table-list">
                           <li className="mb-1"><i className="fa fa-check text-success"></i> {t('pricing.access_to_product_research')}</li>
                              <li className="mb-1"><i className="fa fa-check text-success"></i> {t('pricing.access_to_influencer_research')}</li>
                              <li className="mb-1"><i className="fa fa-check text-success"></i> {t('pricing.access_to_profit_loss_dashboard')}</li>
                              <li className="mb-1"><i className="fa fa-check text-success"></i> {t('pricing.access_to_profitability_calculator')}</li>
                              <li className="mb-1"><i className="fa fa-check text-success"></i> {t('pricing.weekly_email_broadcast')}</li>
                              <li className="mb-1"><i className="fa fa-check text-success"></i> {t('pricing.tiktok_shop_cheat_sheet')}</li>
                              <li className="mb-1"><i className="fa fa-check text-success"></i> {t('pricing.comprehensive_tool_tutorials')}</li>
                           </ul>
                           <hr />
                           <p className="mb-0">{t('pricing.day_money_back_guarantee')}</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>


         <section className="section call-action">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                     <div className="cta-content">
                        <h2 className="wow fadeInUp">{t('pricing.focus_on_tiktok_analytics')}</h2>
                        <div className="button wow fadeInUp">
                           <a href="/pre-register" className="btn">{t('pricing.start_free_trial')}</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <Footer />
      </>
   );
};

export default Pricing;