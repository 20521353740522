import React from 'react'
import InfiniteScrollWrapper from '../../Products/InfiniteScrollWrapper';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import Conversion from '../../CommonComponents/Conversion';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function VideoCreatorsOnly({ sorting, sortClass, handleSort, functionNext, items, favourite, markFavourite, hasMore }) {
    const { t } = useTranslation();

    const tableHead = [
        {
            title: t('common.influencers'),
        },
        {
            title: t('common.revenue'),
            className: 'text-right sorting',
            sort: 18
        },
        {
            title: t('influencers.current_sales'),
            className: 'text-right sorting',
            sort: 19,
        },
        {
            title: t('influencers.Sales_growth_ratio_last_7_days'),
            className: 'text-right sorting',
            sort: 23,
        },
        {
            title: t('influencers.atv'),
            className: 'text-right sorting',
            sort: 20,
        },
        {
            title:  t('common.followers'),
            className: 'text-right sorting',
            sort: 1
        },
        {
            title:  t('common.favorite'),
            className: 'text-right ',

        },
    ];
    const countryicon = (e) => {
        const data = Object.keys(global?.config?.region).find(key => global?.config?.region[key] === e);
        return data ? data.toLowerCase() : "";
    }
    return (
        <div>
            <InfiniteScrollWrapper
                isInfiniteScrollOn={true}
                lengthData={items.length}
                functionNext={functionNext}
                hasMore={hasMore}
            >
                <table id="example" className="display w-100 dataTable">
                    <thead>
                        <tr role="row">
                            {tableHead.map((value, index) => (
                                <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort) }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {items?.map((influencer, index) => (
                            <tr key={index}>
                                <td>
                                    <div className='d-flex'>
                                        <div className="prd-img mr-3">
                                            <OverlayTrigger
                                                key="right"
                                                trigger={["hover", "focus"]}
                                                placement="right"
                                                rootClose
                                                overlay={
                                                    <Popover>
                                                        <Popover.Body>
                                                            <img alt="" width={500} src={`https://t-img.picturehaven.net/tikmeta/${influencer?.avatar_larger_privatization ? influencer?.avatar_larger_privatization : influencer?.author?.author_avatar}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <Button variant="link" className='p-0' size="sm">
                                                    <Link to={'/influencers-search/details/' + influencer.id + '/discoveryInfluencers'} target="_blank">
                                                        <img alt="" width={70} src={`https://t-img.picturehaven.net/tikmeta/${influencer?.avatar_larger_privatization ? influencer?.avatar_larger_privatization : influencer?.author?.author_avatar}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                    </Link>
                                                </Button>
                                            </OverlayTrigger>
                                        </div>

                                        <div className='prd-title'>
                                            <Link to={'/influencers-search/details/' + influencer.id + '/discoveryInfluencers'} target="_blank">
                                                <p>{influencer?.nickname || '--'}</p>
                                            </Link>
                                            <p><span className={`fi fi-${countryicon(influencer?.sys_country?.country)} mr-1`}></span>{influencer?.sys_country?.country || '--'}</p>
                                            {influencer?.categories && influencer?.categories.length != 0 && <div className="mb-1">
                                                <span className="btn btn-outline-dark btn-sm px-2 py-1 mr-1 mb-1">{influencer?.categories[0]?.name}</span> <br />
                                                {influencer?.categories[1]?.name && <><span className="btn btn-outline-dark btn-sm px-2 py-1 mr-1 mb-1">{influencer?.categories[1]?.name}</span> <br /></>}
                                                {influencer?.categories.length > 2 && <OverlayTrigger
                                                    key="right"
                                                    trigger="click"
                                                    placement="bottom"
                                                    rootClose
                                                    overlay={
                                                        <Popover className="variants-tooltip">
                                                            <Popover.Body>
                                                                <div className="m-2">
                                                                {influencer.categories && influencer.categories.slice(2).map((v, i) => (
                                                                    <span key={i} className="btn btn-outline-dark btn-sm px-2 py-1 mr-1">{v.name}</span>
                                                                ))}
                                                                </div>
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                    <Button variant="outline-dark" size="sm" className='px-2 py-1'>{influencer.categories.length > 1 ? ('+' + (influencer.categories.length - 2)) : ''}  <i className="fa fa-chevron-down" aria-hidden="true"></i></Button>
                                                </OverlayTrigger>}
                                            </div>}

                                        </div>
                                    </div>
                                </td>
                                <td className='text-right'>
                                    <h4>{influencer?.estimated_sales_amount_usd}</h4>
                                    <p>({influencer?.estimated_sales_amount})</p>
                                </td>
                                <td className='text-right'>{influencer?.estimated_sales_volume}</td>
                                <td className='text-right'>{((influencer?.sold_count_incr_rate * 1000) / 10).toFixed(2)}%</td>
                                <td className='text-right'>
                                    <h4>{influencer?.estimated_avg_price_usd}</h4>
                                    <p>({influencer?.estimated_avg_price})</p>
                                </td>
                                <td className='text-right'>
                                    <Conversion value={influencer?.follower_count} />
                                </td>
                                <td className="text-right">
                                    <div className="d-flex justify-content-end">

                                        <div className="mr-1">
                                            {<Button
                                                variant="outline-dark" size="sm"
                                                className="px-2 py-1"
                                                onClick={() => { markFavourite({ influencer_id: influencer.id, nickname: influencer?.nickname }); }}>
                                                <i className={"fa " + (favourite.indexOf(influencer.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                            </Button>}
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </InfiniteScrollWrapper>
        </div>
    )
}

export default VideoCreatorsOnly