import React from "react";
import { Card } from "react-bootstrap";

import ProfitDashboard from "../../../../src/images/video/Viralytic-Profit-Dashboard.mp4";
import Calculator from "../../../../src/images/video/Viralytic-Product-Profitability-Calculator.mp4";
import ResearchTool from "../../../../src/images/video/Viralytic-Influencer-Research-Tool.mp4";
import ProductResearch from "../../../../src/images/video/Viralytic-Product-Research-Tool.mp4";
import StoreResearch from "../../../../src/images/video/Viralytic-Store-Research-Tool.mp4";

const Tutorials = () => {

   return (

      <div className="row">
         <div className="col-lg-4">
            <Card>
               <Card.Body>
                  <video className="embed-responsive-item w-100 mb-3" controls loop muted>
                     <source src={ProfitDashboard} type="video/mp4" />
                     Your browser does not support the video tag.
                  </video>
                  <h4 className="mb-0">Profit Dashboard</h4>
               </Card.Body>
            </Card>
         </div>
         <div className="col-lg-4">
            <Card>
               <Card.Body>
                  <video className="embed-responsive-item w-100 mb-3" controls loop muted>
                     <source src={Calculator} type="video/mp4" />
                     Your browser does not support the video tag.
                  </video>
                  <h4 className="mb-0">Product Profitability Calculator</h4>
               </Card.Body>
            </Card>
         </div>
         <div className="col-lg-4">
            <Card>
               <Card.Body>
                  <video className="embed-responsive-item w-100 mb-3" controls loop muted>
                     <source src={ResearchTool} type="video/mp4" />
                     Your browser does not support the video tag.
                  </video>
                  <h4 className="mb-0">Influencer Research Tool</h4>
               </Card.Body>
            </Card>
         </div>
         <div className="col-lg-4">
            <Card>
               <Card.Body>
                  <video className="embed-responsive-item w-100 mb-3" controls loop muted>
                     <source src={ProductResearch} type="video/mp4" />
                     Your browser does not support the video tag.
                  </video>
                  <h4 className="mb-0">Product Research Tool</h4>
               </Card.Body>
            </Card>
         </div>
         <div className="col-lg-4">
            <Card>
               <Card.Body>
                  <video className="embed-responsive-item w-100 mb-3" controls loop muted>
                     <source src={StoreResearch} type="video/mp4" />
                     Your browser does not support the video tag.
                  </video>
                  <h4 className="mb-0">Store Research Tool</h4>
               </Card.Body>
            </Card>
         </div>
      </div>


   );
};

export default Tutorials;