// import React from "react";

import React, { useState } from "react";
/// React router dom
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "/node_modules/acumen-flag-icons/css/flag-icons.min.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./pages/ScrollToTop";

/// Pages
import Login from "./pages/Login";
import LockScreen from "./pages/LockScreen";
import Error404 from "./pages/Error404";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import PageOne from "./pages/PageOne";
import ProductsResearch from "./pages/ProductsResearch";
import Influencers from "./pages/Influencers";
import MytiktokShop from "./pages/MytiktokShop";
import OurStory from "./pages/OurStory";
import Affiliate from "./pages/Affiliate";
import Pricing from "./pages/Pricing";
import ProductReview from "./pages/ProductReview";
import InfluencersDetails from "./pages/InfluencersDetails";

/// Deshboard
import Dashboard from "./components/Dashboard/Home/Home";
import TopSales from "./components/Products/TopSales";
import TopsalesDetail from "./components/Products/TopsalesDetail";
import ProductsSearch from "./components/Products/ProductsSearch";
import ProductVideos from "./components/Products/ProductsVideos";
import StoreManager from "./components/TiktokStore/StoreManager";
import ProfitDashboard from "./components/TiktokStore/ProfitDashboard";
import ProductMasterList from "./components/TiktokStore/ProductMasterList";
import Reports from "./components/TiktokStore/Reports";
import Tutorials from "./components/NeedHelp/Tutorials";
import ContactSupport from "./components/NeedHelp/ContactSupport";
import Profile from "./components/Profile/Profile";
import OneoffForm from "./pages/OneoffForm";

//User Type Admin
import UserManagement from "./components/AdminTools/UserManagement";

/// Chart
import StoreListing from "./components/TiktokStore/Storelisting";
import Preregister from "./pages/PreRegister";
import PreRegisterStart from "./pages/PreRegisterStart";
import PreRegisterJoin from "./pages/PreRegisterJoin";
import ResetPassword from "./pages/ResetPassword";
import PriceCalculator from "./pages/PriceCalculator";
import Redirect from "./components/TiktokStore/Redirect";
import "./components/charts/Chartjs";
import TopSalesInfluencers from "./components/Influencers/TopSales/TopSalesInfluencers";
import InfluencersSearch from "./components/Influencers/InfluencerSearch/InfluencersSearch";
import CouponsList from "./components/Coupons/CouponsList";
import AddUpdateCoupon from "./components/Coupons/AddUpdateCoupon";
import InfluencerTopSales from "./components/Influencers/Details-Influencers/InfluencerTopSales";
import InfluencerSearchDetails from "./components/Influencers/Details-Influencers/InfluencerSearchDetails";
import ProductVideo from "./components/Products/detailstab/ProductVideoDetails/ProductVideodetail";
//import Logout from "./pages/Logout";
import MasterLayout from '../MasterLayout';
import GeneralLayout from '../GeneralLayout';
import ShopRankingList from "./components/Shops/ShopRankingList";
import Shopsearchfilter from "./components/Shops/Shopsearchfilter";
import ShopDetails from "./components/Shops/ShopDetails";
import AdminDashboard from "./components/AdminTools/AdminDashboard";
import SongDetail from "./components/CreatorCenter/Songs/SongDetail";
import RegisterFinanceReport from "./components/AdminTools/RegisterFinanceReport/RegisterFinanceReport";
import EventLog from "./components/TiktokStore/EventLog";
import SongsRanking from './components/CreatorCenter/Songs/SongsRanking'
import SongSearchFilters from "./components/CreatorCenter/Songs/SongsSearch/SongSearchFilter";
import Hashtage from "./components/CreatorCenter/Hashtage/Hashtage";
import HashtageDetail from "./components/CreatorCenter/Hashtage/HashtageDetail";
import HashtageSearchFilters from "./components/CreatorCenter/Hashtage/HashtageSearch/HashtageSearchFilter";
import HotLiveChannelFilter from "./components/Live/HotLiveChannels/HotLiveChannelFilter";
import LiveSearchFilter from "./components/Live/LiveSearch/LiveSearchFilter";
import ViralVideosFilter from "./components/CreatorCenter/Video/VideoFilter";
import InfluancerVideoModule from "./components/AdminTools/InfluancerVideoModule";
import OneoffQuestion from "./components/AdminTools/Oneofffquestion";
import LiveDetails from "./components/Live/liveDetail/LiveDetail";
import PrintifyProducts from "./components/Printify/PrintifyProducts";
import UserAnswers from "./components/AdminTools/UserAnswers";
import UserAnswerList from "./components/AdminTools/UserAnswerList";
import ExportModule from "./components/DownloadCenter/Export";

const Markup = () => {
   let isTokenExists, user_type;
   try {
      isTokenExists = localStorage.getItem('token');
      user_type = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user_type : '';
   } catch (error) {
      localStorage.removeItem('user');
      localStorage.removeItem('userName');
      localStorage.removeItem('email');
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      window.location.href = '/login';
   }
   let path = window.location.pathname;
   path = path.split("/");
   path = path[path.length - 1];

   let pagePath = false;

   let excludeTemplate = [
      '',
      'login',
      'forgot-password',
      'pre-register',
      'start',
      'join',
      'products-research',
      'affiliate',
      'pricing',
      'our-story',
      'influencers',
      'my-tiktok-shop',
      isTokenExists && user_type ? '' : 'influencers-review-videos',
      isTokenExists && user_type ? '' : 'content-creation',
      isTokenExists && user_type ? '' : 'thank-you',
      isTokenExists && user_type ? '' : 'influencers-details',
   ];

   if (excludeTemplate.includes(path)) {
      pagePath = true;
   }

   const [activeEvent, setActiveEvent] = useState(!path);

   const routesWithLogin = [
      /// Dashboard
      { url: "dashboard", component: Dashboard },
      //pages
      { url: "profile", component: Profile },
      { url: "error-404", component: Error404 },
      { url: "products/top-sales", component: TopSales },
      { url: "tutorials", component: Tutorials },
      { url: "products/:productId/details", component: TopsalesDetail },
      { url: "products/products-search", component: ProductsSearch },
      { url: "products/products-videos", component: ProductVideos },
      { url: "products/:productId/video-details", component: ProductVideo },
      { url: "tiktok-store/store-manager", component: StoreManager },
      { url: "tiktok-store/profit-dashboard", component: ProfitDashboard },
      { url: "tiktok-store/product-master-list", component: ProductMasterList },
      { url: "tiktok-store/reports", component: Reports },
      { url: "tiktok-store/store-listing", component: StoreListing },
      { url: "tiktok-store/redirect", component: Redirect },
      { url: "price-calculator", component: PriceCalculator },
      //INFLUENCER TOPSALES
      { url: "influencers/top-sales-influencers", component: TopSalesInfluencers },
      //INFLUENCER TOPSALES BREAKOUT INFLUENCERS DETAILS
      { url: "influencers/details/:influencerId/:name", component: InfluencerTopSales },
      //INFLUENCER TOPSALES TOP VIDEO INFLUENCERS DETAILS
      { url: "influencers/top-sales-influencers/video-details/:influencerId/:name", component: InfluencerTopSales },
      //INFLUENCER TOPSALES TOP LIVE INFLUENCERS DETAILS
      { url: "influencers/top-sales-influencers/live-details/:influencerId/:name", component: InfluencerTopSales },

      //INFLUENCER SEARCH
      { url: "influencers/influencers-search", component: InfluencersSearch },
      //INFLUENCER SEARCH DETAILS
      { url: "influencers-search/details/:influencerId/:name", component: InfluencerSearchDetails },

      // CREATOR CENTER
      { url: "ranking/songs", component: SongsRanking },
      { url: "viral/video", component: ViralVideosFilter },
      { url: "song/:musicId/:name", component: SongDetail },
      { url: "search/song", component: SongSearchFilters },
      { url: "ranking/hashtages", component: Hashtage },
      { url: "hashtage/:tagId/:name", component: HashtageDetail },
      { url: "search/hashtage", component: HashtageSearchFilters },

      //SHOPS
      { url: "shops/shop-ranking-list", component: ShopRankingList },
      { url: "shops/shop-search", component: Shopsearchfilter },
      { url: "shops/:shop_id/details", component: ShopDetails },


      { url: "affiliate", component: Affiliate },
      { url: "contact-support", component: ContactSupport },
      { url: "live/hot-live", component: HotLiveChannelFilter },
      { url: "live/live-search", component: LiveSearchFilter },
      { url: "influencers-review-videos", component: ProductReview },
      { url: "content-creation", component: PageOne },
      { url: "content-creation/:influencerid/influencers-details", component: InfluencersDetails },
      { url: "content-creation/:subscriptionid/thank-you", component: OneoffForm },
      { url: "products/printify", component: PrintifyProducts },
      // { url: "live/hot-live", component: HotLiveChannelFilter },
      // { url: "live/live-search", component: LiveSearchFilter },
      // { url: "live/:liveId/:name", component: LiveDetails },

      { url: "products/printify", component: PrintifyProducts },
      { url: "exports", component: ExportModule }
   ];

   if (user_type == 'admin') {
      routesWithLogin.push(
         { url: "admin-dashboard", component: AdminDashboard },
         { url: "user-management", component: UserManagement },
         { url: "coupon", component: CouponsList },
         { url: "coupon/create", component: AddUpdateCoupon },
         { url: "coupon/edit", component: AddUpdateCoupon },
         { url: "register-finance-report", component: RegisterFinanceReport },
         { url: "influancer-video", component: InfluancerVideoModule },
         { url: "oneoffuser/questions", component: OneoffQuestion },
         { url: "oneoffuser/answers", component: UserAnswers },
         { url: "oneoffuser/question-answer", component: UserAnswerList },
         { url: "event-log", component: EventLog },
      );
   }

   const routesWithOutLogin = [
      /// pages
      { url: "", component: Home },
      { url: "pre-register", component: Preregister },
      { url: "login", component: Login },
      { url: "forgot-password", component: ForgotPassword },
      { url: "reset-password/:token", component: ResetPassword },
      { url: "lock-screen", component: LockScreen },
      { url: "error-404", component: Error404 },
      { url: "price-calculator", component: PriceCalculator },
      { url: "tiktok-store/store-listing", component: StoreListing },
      { url: "products-research", component: ProductsResearch },
      { url: "influencers", component: Influencers },
      { url: "my-tiktok-shop", component: MytiktokShop },
      { url: "our-story", component: OurStory },
      { url: "affiliate", component: Affiliate },
      { url: "influencers-review-videos", component: ProductReview },
      { url: "contact-support", component: ContactSupport },
      { url: "pricing", component: Pricing },
      { url: "content-creation", component: PageOne },
      { url: "content-creation/:subscriptionid/thank-you", component: OneoffForm },
      { url: "content-creation/:influencerid/influencers-details", component: InfluencersDetails },

      // New Plan Pages
      { url: "/start", component: PreRegisterStart },
      { url: "/join", component: PreRegisterJoin },
   ];

   return (
      !isTokenExists ?
         <GeneralLayout >
            <Router basename="/">
               <div id={`${!pagePath ? "main-wrapper" : ""}`} className={`${!pagePath ? "show" : "mh100vh front-site"}`}>
                  <div className={` ${!path && activeEvent ? "rightside-event" : ""} ${!pagePath ? "content-body" : ""}`}>
                     <div className={`${!pagePath ? "container-fluid" : ""}`}>
                        <Routes>
                           {routesWithOutLogin.map((data, i) => (
                              <Route key={i} exact path={`/${data.url}`} element={<data.component />} />
                           ))}
                           <Route path="*" element={<Navigate to="/login" replace />} />
                        </Routes>
                     </div>
                  </div>
                  {!pagePath && <Footer />}
               </div>
               <ScrollToTop />
            </Router>
         </GeneralLayout>
         :
         <MasterLayout>
            <Router basename="/">
               <div id={`${!pagePath ? "main-wrapper" : ""}`} className={`${!pagePath ? "show menu-toggle admin-site" : "mh100vh"}`}>
                  {!pagePath && (<Nav
                     onClick={() => setActiveEvent(!activeEvent)} activeEvent={activeEvent}
                     onClick2={() => setActiveEvent(false)}
                     onClick3={() => setActiveEvent(true)}
                  />)}
                  <div className={` ${!path && activeEvent ? "rightside-event" : ""} ${!pagePath ? "content-body" : ""}`}>
                     <div className={`${!pagePath ? "container-fluid" : ""}`}>
                        <Routes>
                           {routesWithLogin.map((data, i) => (<Route key={i} exact path={`/${data.url}`} element={<data.component />} />))}
                           {routesWithOutLogin.map((data, i) => (<Route key={routesWithLogin.length + i} exact path={`/${data.url}`} element={<Navigate to="/dashboard" />} />))}
                           <Route key={path} path='*' element={<Error404 />} />
                        </Routes>
                     </div>
                  </div>
               </div>
               <ScrollToTop />
            </Router>
         </MasterLayout>
   );
};

export default Markup;
