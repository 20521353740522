import React from "react";
import "../../css/landing/landing.css";
import "../../css/landing/bootstrap.min.css";
import "../../css/landing/glightbox.min.css";
import "../../css/landing/main.css";
import "../../css/landing/tiny-slider.css";
import { Nav, Tab} from 'react-bootstrap'
import ProductsresearchImg from "../../../src/images/products-research.png";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useTranslation } from 'react-i18next';

const ProductsResearch = () => {
   const { t } = useTranslation();
   return (
      <>
         <Header />

         <section id="home" className="hero-area hero-inner-area">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-6 col-md-12 col-12">
                     <div className="hero-content">
                        <h1 className="wow fadeInLeft mb-3">{t('productsresearchfront.products_research')}</h1>
                        <div className="button wow fadeInLeft">
                           <a href="/pre-register" className="btn">{t('productsresearchfront.get_started')}</a>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 text-center">
                     <div className="hero-image wow fadeInRight">
                        <img src={ProductsresearchImg} alt="" />
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section id="features" className="features section pt-0 pb-0">
            <Tab.Container
               defaultActiveKey="topsales"
            >
               <div className="bg-light py-5 mb-0">
                  <div className="container">
                     <div className="row mb-4">
                        <div className="col-12">
                           <div className="section-title p-0">
                              <h2 className="wow fadeInUp mb-2">{t('productsresearchfront.supercharge_your_ecommerce')}</h2>
                              <p className="wow fadeInUp">{t('productsresearchfront.explore_a_world_of_insights')}</p>
                           </div>
                        </div>
                     </div>
                     <Nav as="ul" className="nav-pills mb-4">
                        <Nav.Item as="li" key="topsales">
                           <Nav.Link eventKey="topsales">
                           {t('productsresearchfront.top_sales')}
                           </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li" key="products">
                           <Nav.Link eventKey="products" >
                           {t('productsresearchfront.products')}
                           </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li" key="productvideo">
                           <Nav.Link eventKey="productvideo" >
                           {t('productsresearchfront.product_video')}
                           </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li" key="profitabilitycalculator">
                           <Nav.Link eventKey="profitabilitycalculator" >
                           {t('productsresearchfront.profitability_calculator')}
                           </Nav.Link>
                        </Nav.Item>
                     </Nav>
                  </div>

                  <div className="container">
                     <Tab.Content>
                        <Tab.Pane
                           eventKey="topsales"
                           key="topsales"
                        >
                           <div className="row align-items-center">
                              <div className="col-lg-7 col-md-12 col-12">
                                 <div className="hero-content">
                                    <h3 className="wow fadeInLeft mb-2">{t('productsresearchfront.top_sales_unveil')}</h3>
                                    <p className="wow fadeInLeft lead">{t('productsresearchfront.discover_the_heartbeat')}</p>
                                    <div className="button wow fadeInLeft">
                                       <a href="/#" className="text-primary">{t('productsresearchfront.learn_more')} <i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-lg-5 col-md-12 col-12 text-center">
                                 <div className="hero-image wow fadeInRight">
                                    <img src={ProductsresearchImg} alt="" />
                                 </div>
                              </div>
                           </div>
                        </Tab.Pane>
                        <Tab.Pane
                           eventKey="products"
                           key="products"
                        >
                           <div className="row align-items-center">
                              <div className="col-lg-7 col-md-12 col-12">
                                 <div className="hero-content">
                                    <h3 className="wow fadeInLeft mb-2">{t('productsresearchfront.products_your_general')}</h3>
                                    <p className="wow fadeInLeft lead">{t('productsresearchfront.navigate_the_vast_landscape')}</p>
                                    <div className="button wow fadeInLeft">
                                       <a href="/#" className="text-primary">{t('productsresearchfront.learn_more')} <i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-lg-5 col-md-12 col-12 text-center">
                                 <div className="hero-image wow fadeInRight">
                                    <img src={ProductsresearchImg} alt="" />
                                 </div>
                              </div>
                           </div>
                        </Tab.Pane>
                        <Tab.Pane
                           eventKey="productvideo"
                           key="productvideo"
                        >
                           <div className="row align-items-center">
                              <div className="col-lg-7 col-md-12 col-12">
                                 <div className="hero-content">
                                    <h3 className="wow fadeInLeft mb-2">{t('productsresearchfront.product_video_visualize')}</h3>
                                    <p className="wow fadeInLeft lead">{t('productsresearchfront.see_the_products_that')}</p>
                                    <div className="button wow fadeInLeft">
                                       <a href="/#" className="text-primary">{t('productsresearchfront.learn_more')} <i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-lg-5 col-md-12 col-12 text-center">
                                 <div className="hero-image wow fadeInRight">
                                    <img src={ProductsresearchImg} alt="" />
                                 </div>
                              </div>
                           </div>
                        </Tab.Pane>
                        <Tab.Pane
                           eventKey="profitabilitycalculator"
                           key="profitabilitycalculator"
                        >
                           <div className="row align-items-center">
                              <div className="col-lg-7 col-md-12 col-12">
                                 <div className="hero-content">
                                    <h3 className="wow fadeInLeft mb-2">{t('productsresearchfront.profitability_calculator_smart')}</h3>
                                    <p className="wow fadeInLeft lead">{t('productsresearchfront.make_informed_decisions')}</p>
                                    <div className="button wow fadeInLeft">
                                       <a href="/#" className="text-primary">{t('productsresearchfront.learn_more')} <i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-lg-5 col-md-12 col-12 text-center">
                                 <div className="hero-image wow fadeInRight">
                                    <img src={ProductsresearchImg} alt="" />
                                 </div>
                              </div>
                           </div>
                        </Tab.Pane>
                     </Tab.Content>
                  </div>
               </div>
            </Tab.Container>
         </section>

         <section id="pricing" className="pricing-table section pt-0">
            <div className="container">
               <div className="row">
                  <div className="col-lg-4 col-md-6 col-12">
                     <div className="single-table wow fadeInUp">
                        <div className="table-head">
                           <h4 className="title">{t('productsresearchfront.advanced_filters_for_precision')}</h4>
                           <p>{t('productsresearchfront.refine_your_research')}</p>
                        </div>                        
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                     <div className="single-table wow fadeInUp">
                        <div className="table-head">
                           <h4 className="title">{t('productsresearchfront.why_tikshop')}</h4>
                           <p>- {t('productsresearchfront.real_time_data')}</p>
                           <p>- {t('productsresearchfront.intuitive_design_effortlessly')}</p>
                           <p>- {t('productsresearchfront.comprehensive_solutions')}</p>
                        </div>                        
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                     <div className="single-table wow fadeInUp">
                        <div className="table-head">
                           <h4 className="title">{t('productsresearchfront.join_now_and_boost')}</h4>
                           <p>{t('productsresearchfront.ready_to_boost_your')}</p>
                        </div>                        
                     </div>
                  </div>                                 
               </div>
            </div>
         </section>
         
         <section className="section call-action">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                     <div className="cta-content">
                        <h2 className="wow fadeInUp">{t('productsresearchfront.focus_on_tiktok_analytics')}</h2>
                        <div className="button wow fadeInUp">
                           <a href="/#" className="btn">{t('productsresearchfront.start_free_trial')}</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <Footer />
      </>
   );
};

export default ProductsResearch;