import moment from 'moment';
import React, { useEffect, useRef, useState, useContext } from 'react'
import { Button, ButtonGroup, Card } from 'react-bootstrap'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { useParams } from 'react-router-dom';
import Axios from '../../../../plugins/axios';
import { Bar } from 'react-chartjs-2';
import Category from '../../../Products/Category';
import RangeSlider from '../../../Products/RangeSlider';
import ProductList from './ProductList';
import { Context } from '../../../../../MasterLayout';
import { useTranslation } from 'react-i18next';


function RelevantProducts() {
    const { t } = useTranslation();

    const { dispatch } = useContext(Context);
    const params = useParams();
    const [relevantProduct, setrelevantProduct] = useState({});
    const dateRangeRef = useRef();
    const dateRangeRefProduct = useRef();
    const [relevantProductFilter, setrelevantProductFilter] = useState('7_days');
    const [ProductFilter, setProductFilter] = useState('7_days');
    const [AllPriceRange, setAllPriceRange] = useState("");
    const [category1, setCategory1] = useState('');
    const [category2, setCategory2] = useState('');
    const [categoryId1, setCategoryId1] = useState('');
    const [categoryId2, setCategoryId2] = useState('');

    const [dateFilterOption] = useState({
        '7_days': {
            label: 'Past 7 Days',
            begin_date: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        },
        '15_days': {
            label: 'Past 15 Days',
            begin_date: new Date(new Date().setDate(new Date().getDate() - 15)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        },
        '30_days': {
            label: 'Past 30 Days',
            begin_date: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        }
    });
    const [dateFilterOptionProduct] = useState({
        '7_days': {
            label: 'Past 7 Days',
            begin_date: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        },
        '15_days': {
            label: 'Past 15 Days',
            begin_date: new Date(new Date().setDate(new Date().getDate() - 15)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        },
        '30_days': {
            label: 'Past 30 Days',
            begin_date: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        }
    });

    const [dateRange, setDateRange] = useState(dateFilterOption[relevantProductFilter]);

    const dateFilterOptionHtml = Object.keys(dateFilterOption).map(function (key) {
        return <Button key={key} variant="outline-dark" size="sm" className={(relevantProductFilter == key ? 'btn-primary' : '')} onClick={() => { setrelevantProductFilter(key); }}>{dateFilterOption[key].label}</Button>
    });
    const [productlistdateRange, setProductlistDateRange] = useState(dateFilterOptionProduct[ProductFilter]);

    const handledateProduct = (key) => {
        setProductFilter(key); setPage(0); setApiPage(1)
    }

    const dateProductFilterOptionHtml = Object.keys(dateFilterOptionProduct).map(function (key) {
        return <Button key={key} variant="outline-dark" size="sm" className={(ProductFilter == key ? 'btn-primary' : '')} onClick={() => { handledateProduct(key) }}>{dateFilterOptionProduct[key].label}</Button>
    });

    const [sorting, setSorting] = useState({ sort: 2, sort_type: 1 });
    const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-desc-blue';
    const handleSort = async (sort, sort_type) => {
        if (sort != sorting.sort || (sort_type && sort_type != sorting.sort_type)) {
            setSorting({ sort, sort_type: sort_type });
            setPage(0);
            setApiPage(1);
        }
    };


    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD')
        );
        setDateRange({ begin_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
        setrelevantProductFilter('custom');
    };
    const handleCancel = (event, picker) => {
        picker.element.val('');
        setDateRange([]);
    };
    const handleProductApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD')
        );
        setProductlistDateRange({ begin_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
        setProductFilter('custom');
    };
    const handleCancelProduct = (event, picker) => {
        picker.element.val('');
        setProductlistDateRange([]);
    };

    const changeDate = (startDate, endDate) => {
        dateRangeRef.current.setStartDate(moment(new Date(startDate)).format('MM/DD/YYYY'));
        dateRangeRef.current.setEndDate(moment(new Date(endDate)).format('MM/DD/YYYY'));
    };
    const changeDateProduct = (startDate, endDate) => {
        dateRangeRefProduct.current.setStartDate(moment(new Date(startDate)).format('MM/DD/YYYY'));
        dateRangeRefProduct.current.setEndDate(moment(new Date(endDate)).format('MM/DD/YYYY'));
    };
    const [productrelevantProductTrend, setProductrelevantProductTrend] = useState({});
    const relevantProductProductRef = useRef();
    const [productdata, setproductdata] = useState([])
    const [totalCount, setTotalcount] = useState()
    const [page, setPage] = useState(0)
    const [Apipage, setApiPage] = useState(1)

    const relevantProductApi = async () => {
        try {
            let bodyParams = {};


            if (relevantProductFilter == 'custom') {
                bodyParams = {
                    begin_date: dateRange.begin_date,
                    end_date: dateRange.end_date,
                };
            } else {
                bodyParams = dateFilterOption[relevantProductFilter];
                setDateRange(bodyParams);
            }

            bodyParams.author_id = params.influencerId;

            changeDate(bodyParams.begin_date, bodyParams.end_date);

            let response = await Axios.post('/api/influencer-product-overview', bodyParams);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.status === 200) {
                if (response.data.data && response.data.data.report_list) {
                    var labels = [], data = [
                        {
                            label: "Sales",
                            data: [],
                            borderColor: "rgba(210, 47, 110, 1)",
                            backgroundColor: "#f82c8f",
                        },
                        {
                            label: "Revenue",
                            data: [],
                            borderColor: "#2bc155",
                            backgroundColor: "#0aa2ee",
                        },
                    ];
                    response.data.data.report_list.map(function (value, index) {
                        labels[index] = value.date;
                        data[0]['data'][index] = value.estimated_sales_volume;
                        data[1]['data'][index] = value.estimated_sales_amount_usd;
                        return null;
                    });
                    // data[0]['data'] = data[0]['data'];           
                    // data[1]['data'] = data[1]['data'];

                    setProductrelevantProductTrend({
                        labels: labels,
                        datasets: data,
                    });
                }

                setrelevantProduct(response.data.data);
                await relevantProductProductRef.current.relevantProductProduct(response.data.data);
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        };
    }

    useEffect(() => {
        relevantProductApi();
    }, [relevantProductFilter]);

    useEffect(() => {
        productListApi()
    }, [ProductFilter, page, Apipage, AllPriceRange, categoryId1, category1, categoryId2, category2, sorting])

    async function productListApi() {
        try {
            let bodyParams = {};
            if (ProductFilter == 'custom') {
                bodyParams = {
                    begin_date: productlistdateRange.begin_date,
                    end_date: productlistdateRange.end_date,
                };
            } else {
                bodyParams = dateFilterOptionProduct[ProductFilter];
                setProductlistDateRange(bodyParams)
            }
            delete bodyParams.category1;
            delete bodyParams.category2;
            delete bodyParams.category_id1;
            delete bodyParams.category_id2;

            if (Apipage) {
                bodyParams.cursor = Apipage;
            }
            if (AllPriceRange) {
                bodyParams.begin_price = AllPriceRange?.from
                bodyParams.end_price = AllPriceRange?.to
            }

            bodyParams.author_id = params.influencerId;

            changeDateProduct(bodyParams.begin_date, bodyParams.end_date);
            if (category1 != '') {
                bodyParams.category1 = category1;
            }
            if (categoryId1 != '') {
                bodyParams.category_id1 = categoryId1
            }
            if (category2 != '') {
                bodyParams.category2 = category2;
            }
            if (categoryId2 != '') {
                bodyParams.category_id2 = categoryId2
            }
            if (sorting.sort) {
                bodyParams.sort = sorting.sort;
                bodyParams.sort_type = sorting.sort_type;
            }
            let response = await Axios.post('/api/influencer-product-list', bodyParams);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            setTotalcount(response.data.data.total_count)
            setproductdata(response.data.data.items)

        } catch (error) {
            console.error('Error: ' + error.message);

        }
    }


    const options = {
        plugins: {
            tooltips: {
                enabled: false,
            },
            legend: {
                display: false,
                labels: {
                    usePointStyle: false,
                },
            },
        },
        maintainAspectRatio: false,

        scales: {
            yAxes: [
                {
                    display: false,
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                    },
                },
            ],
            xAxes: [
                {
                    display: false,
                    categoryPercentage: 1.0,
                    barPercentage: 1,
                    gridLines: {
                        display: false,
                        drawBorder: false,
                    },
                },
            ],
        },
    };


    const priceRangeOption = {
        '0-1': '≤$1',
        '1-5': '$1-$5',
        '5-10': '$5-$10',
        '10-20': '$10-$20',
        '20-50': '$20-$50',
        '50-100': '$50-$100',
        "100-": '≥$100',
    }
    const handlePageClick = (event) => {
        setPage(event.selected);
        setApiPage(event.selected + 1)
    };
    const handleCategory = async (categoryIds, categoryNames) => {
        setCategory1(categoryNames[0] ? categoryNames[0] : '');
        setCategory2(categoryNames[1] ? categoryNames[1] : '');
        setCategoryId1(categoryIds[0] ? categoryIds[0] : '');
        setCategoryId2(categoryIds[1] ? categoryIds[1] : '');
    };
    return (
        <div>
            <Card className='rounded-0'>
                <Card.Body>
                    <div className="row mb-4">
                        <div className="col-lg-5">
                            <h3>{t('influencers.relevant_products')} </h3>
                        </div>
                        <div className="col-lg-7 text-right">
                            <div className='d-flex justify-content-end align-items-center'>
                                <ButtonGroup className='mr-4'>
                                    {dateFilterOptionHtml}
                                </ButtonGroup>
                                <DateRangePicker
                                    ref={dateRangeRef}
                                    initialSettings={{ startDate: moment(new Date(dateRange.begin_date)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_date)).format('MM/DD/YYYY') }}
                                    onApply={handleApply} onCancel={handleCancel} /*onCallback={handleCallback}*/
                                >
                                    <input type="text" placeholder={t('influencers.first_seen')} className="form-control form-control-sm input-daterange-timepicker w-auto" value={dateRange.begin_date + " - " + dateRange.end_date} />
                                </DateRangePicker>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex mb-0'>
                        <Card className="bg-light mr-3 mb-0">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('common.products')}</strong></p>
                                    <h3>{relevantProduct.product_qty || 0}</h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-light mr-3 mb-0">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('common.sales')}</strong></p>
                                    <h3>{relevantProduct.estimated_sales_volume_str || '-'}</h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-light mr-3 mb-0">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('common.revenue')}</strong></p>
                                    <div className='d-flex'>
                                        <h3>{relevantProduct.estimated_sales_amount_usd_str || '-'}</h3>
                                        <p>({relevantProduct?.estimated_sales_amount_str})</p>
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Card>

                    </div>
                </Card.Body>


                <div className="row mb-4">
                    <div className="col-lg-12">
                        <div style={{ "height": "400px" }} className='p-4'>
                            {productrelevantProductTrend.labels && <Bar data={productrelevantProductTrend} options={options} />}
                        </div>
                    </div>
                </div>

                <Card.Body>
                    <div className="row mb-4">
                        <div className="col-lg-5">
                            <h3>{t('influencers.product_list')}</h3>
                        </div>
                        <div className="col-lg-7 text-right">
                            <div className='d-flex justify-content-end align-items-center'>
                                <ButtonGroup className='mr-4'>
                                    {dateProductFilterOptionHtml}
                                </ButtonGroup>
                                <DateRangePicker
                                    ref={dateRangeRefProduct}
                                    initialSettings={{ startDate: moment(new Date(productlistdateRange.begin_date)).format('MM/DD/YYYY'), endDate: moment(new Date(productlistdateRange.end_date)).format('MM/DD/YYYY') }}
                                    onApply={handleProductApply} onCancel={handleCancelProduct} /*onCallback={handleCallback}*/
                                >
                                    <input type="text" placeholder={'First Seen'} className="form-control form-control-sm input-daterange-timepicker w-auto" value={productlistdateRange.begin_date + " - " + productlistdateRange.end_date} />
                                </DateRangePicker>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <Category singleCategory={false}
                                handleCategory={handleCategory}
                                categoryId1={categoryId1}
                            />
                            <div className="dropdown product-rating-menu d-inline-block">
                                <a className="btn btn-outline-dark btn-sm mr-2 dropdown-toggle"
                                    role="button"
                                    id="dropdownMenuLink"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('influencers.all_price')}
                                </a>
                                <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                    <RangeSlider min={0} max={500000} defaultMin={0} defaultMax={500000} rangeOption={priceRangeOption} rangeOptionName={'price'} step={10} handleUpdate={(e) => { setAllPriceRange(e) }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <ProductList currentPage={page} handleSort={handleSort} sortClass={sortClass} sorting={sorting} productdata={productdata} totalCount={totalCount / 10} handlePageClick={handlePageClick} />
                </Card.Body>

            </Card>
        </div>
    )
}

export default RelevantProducts