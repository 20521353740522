import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LogoImg from "../../../../src/images/logo.svg";

const ResetPassword = () => {
  const { t } = useTranslation();
  const [Language, setLanguage] = useState("")

  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    if (lng === 'en') {
      setLanguage('en')
    } else if (lng === 'es') {
      setLanguage('es')
    }
  };

  useEffect(() => {
    const lng = localStorage.getItem("language");
    if (lng === 'en') {
      setLanguage('en')
    } else if (lng === 'es') {
      setLanguage('es')
    }
  }, [])
  

  return (
    <header className="la-header navbar-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="nav-inner">
              <nav className="navbar navbar-expand-lg">
                <a className="navbar-brand" href="/">
                  <img src={LogoImg} alt="Logo" />
                </a>
                <button className="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                  aria-expanded="false" aria-label="Toggle navigation">
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                  <ul id="nav" className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <a href="/#" className="active"
                        aria-label="Toggle navigation">{t('header.free_training')}</a>
                    </li>
                    <li className="nav-item">
                      <a href="/pricing" className=""
                        aria-label="Toggle navigation">{t('header.pricing')}</a>
                    </li>
                    <li className="nav-item">
                      <a href="/affiliate" className=""
                        aria-label="Toggle navigation">{t('header.affiliate')}</a>
                    </li>
                    <li className="nav-item">
                      <a href="/our-story" aria-label="Toggle navigation">{t('header.our_story')}</a>
                    </li>
                  </ul>
                </div>

                <div className="btn-group mr-3 text-nowrap" role="group" aria-label="Basic example">
                  <button type="button" className={`btn  btn-sm py-1 px-2 ${Language === 'en' || Language == '' ? 'btn-primary' : 'btn-outline-primary'}`}  onClick={() => changeLanguage('en')}><span className="fi fi-us"></span> EN</button>
                  <button type="button" className={`btn  btn-sm py-1 px-2 ${Language === 'es' ? 'btn-primary' : 'btn-outline-primary'}`}  onClick={() => changeLanguage('es')}><span className="fi fi-es"></span>  ES</button>                  
                </div>

                <div className="button add-list-button">
                  <a href="/login" className="btn-link">{t('header.log_in')}</a>
                </div>
                <div className="button add-list-button">
                  <a href="/pre-register" className="btn py-1 px-3">{t('header.get_started_for_free')}</a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default ResetPassword;
