

import React from 'react'
import InfiniteScrollWrapper from '../../Products/InfiniteScrollWrapper';
import { Link } from 'react-router-dom';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import Conversion from '../../CommonComponents/Conversion';
import { useTranslation } from 'react-i18next';


function HotShopList({ sortType, sorting, sortClass, handleSort, functionNext, items, favourite, markFavourite, hasMore }) {
  const { t } = useTranslation();

  const tableHead = [
    {
      title: '#',
      className: 'text-right',
    },
    {
      title: t('shops.shop'),
    },
    {
      title: t('shops.main_category'),
    },
    {
      title: t('common.sales'),
      className: 'text-right sorting',
      sort: 37,
    },
    {
      title: t('common.revenue'),
      className: 'text-right sorting',
      sort: 36,
    },
    {
      title: t('shops.relevant_br_influencers'),
      className: 'text-right sorting',
      sort: 39,
    },
    {
      title: t('common.videos'),
      className: 'text-right sorting',
      sort: 40,
    },
    {
      title: t('common.products'),
      className: 'text-right sorting',
      sort: 41,
    },
    {
      title: t('common.favorite'),
      className: 'text-right',
    },
  ];

  const countryicon = Object.keys(global?.config?.region).find(key => global?.config?.region[key] === items[0]?.sys_country?.country)

  return (
    <div>
      <InfiniteScrollWrapper
        isInfiniteScrollOn={true}
        lengthData={items.length}
        functionNext={functionNext}
        hasMore={hasMore}
      >
        <table id="example" className="display w-100 dataTable">
          <thead>
            <tr role="row">
              {tableHead.map((value, index) => (
                <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort, sorting.sort == value.sort ? sortType : 'DESC') }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
              ))}
            </tr>
          </thead>
          <tbody>
            {items?.length ? items?.map((shop, index) => (
              <tr key={index}>
                <td className='text-right'>{shop.rank}</td>

                <td>
                  <div className='d-flex'>
                    <div className="prd-img mr-3">
                      <OverlayTrigger
                        key="right"
                        trigger={["hover", "focus"]}
                        placement="right"
                        rootClose
                        overlay={
                          <Popover>
                            <Popover.Body>
                              {shop?.shop_logo_privatization &&
                                <img alt="" width={500} src={`https://t-img.picturehaven.net/product/${shop?.shop_logo_privatization}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                              }
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <Button variant="link" className='p-0' size="sm">
                          <Link to={'/shops/' + shop.id + '/details'} target="_blank">
                            {shop?.shop_logo_privatization && <img alt="" width={70} className="prod-thum-img" src={`https://t-img.picturehaven.net/product/${shop?.shop_logo_privatization}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />}
                          </Link>
                        </Button>
                      </OverlayTrigger>
                    </div>
                    <div className='prd-title'>
                      <Link to={'/shops/' + shop.id + '/details'} target="_blank"><p>{shop?.shop_name || '--'}</p></Link>
                      <p className='small'><span className={`fi fi-${countryicon?.toLocaleLowerCase()} mr-1`}></span>{shop?.sys_country?.country}</p>
                    </div>
                  </div>
                </td>
                {/* //MAIN CATEGORY */}
                <td>
                  {shop?.category_list && shop?.category_list.length != 0 && <div className="mb-1">
                    <span className="btn btn-outline-dark btn-sm px-2 py-1 mr-1 mb-1">{shop?.category_list[0]?.category_name}</span> <br />
                    {shop?.category_list[1]?.category_name && <><span className="btn btn-outline-dark btn-sm px-2 py-1 mr-1 mb-1">{shop?.category_list[1]?.category_name}</span> <br /></>}
                    {shop?.category_list.length > 2 && <OverlayTrigger
                      key="right"
                      trigger="click"
                      placement="bottom"
                      rootClose
                      overlay={
                        <Popover className="variants-tooltip">
                          <Popover.Body>
                            <div className="m-2">
                              {shop.category_list && shop.category_list.map((v, i) => {
                                if (i != 0 && i != 1) return <span key={i} className="btn btn-outline-dark btn-sm px-2 py-1 mb-1 mr-1">{v.category_name}</span>
                              })}
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button variant="outline-dark" size="sm" className='px-2 py-1'>{shop.category_list.length > 1 ? ('+' + (shop.category_list.length - 2)) : ''}  <i className="fa fa-chevron-down" aria-hidden="true"></i></Button>
                    </OverlayTrigger>}
                  </div>}

                </td>
                {/* // SALES */}
                <td className='text-right'><Conversion value={shop.sold_count} /></td>

                {/* //REVENUE */}
                <td className='text-right'>
                  {shop?.sold_amount_usd}<br />
                  <span className='text-muted'>{shop?.sold_amount_str}</span>
                </td>

                {/* // RELEVNT INFLUENCERS */}
                <td className='text-right'><Conversion value={shop.related_authors} /></td>

                {/* //VIDEOS  */}
                <td className='text-right'><Conversion value={shop.related_videos} /> </td>

                {/* //PRODUCTS  */}
                <td className='text-right'><Conversion value={shop.related_products} /></td>
                

                {/* //FAVORITES */}
                <td className="text-right">
                  <div className="d-flex justify-content-end">
                    <div className="mr-1">
                      {<Button
                        variant="outline-dark" size="sm"
                        className="px-2 py-1"
                        onClick={() => { markFavourite({ shop_id: shop.id, shop_name: shop?.shop_name }); }}>
                        <i className={"fa " + (favourite.indexOf(shop.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                      </Button>}
                    </div>
                  </div>
                </td>
              </tr>


            )) : ''}
          </tbody>
        </table>
      </InfiniteScrollWrapper>
    </div>
  )
}

export default HotShopList