import { React, useContext, useEffect, useState } from 'react';
import { Button, Card, Table, Popover, OverlayTrigger, Tooltip } from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Link } from 'react-router-dom';
import ReactApexChart from "react-apexcharts";
import { useTranslation } from 'react-i18next';
import Axios from '../../../plugins/axios';
import { Context } from '../../../../MasterLayout.js';

const BasicInformationTab = ({ live }) => {
   const { t } = useTranslation();
   const { dispatch } = useContext(Context);
   const [traficShares, setTraficShares] = useState(null);
   const [items, setItems] = useState([])
   const [productCategoryList, setProductCategoryList] = useState(null);
   const [salesCategoryList, setSalesCategoryListList] = useState(null);

   const relevantProductHead = [
      { title: t('live_products.ranking'), className: 'text-left' },
      { title: t('live_products.goods'), className: 'text-left', },
      { title: t('live_products.sales'), className: 'text-left' },
      { title: t('live_products.revenue'), className: 'text-left' },
   ];
   let staticData = [
      {
         ratio: 0.00,
         name: 'Followers',
         count: 0
      },
      {
         ratio: 1.00,
         name: 'Video stream',
         count: 10
      },
      {
         ratio: 0.00,
         name: 'Others (Ads, private, trafic, etc.)',
         count: 0
      },
   ]

   const pieChartOptions = {
      chart: { type: 'donut' },
      labels: [],
      responsive: [{ breakpoint: 480, options: { chart: { width: 200 }, legend: { position: 'bottom' } } }],
      plotOptions: { pie: { donut: { labels: { show: true, total: { showAlways: true, show: true } } }, dataLabels: { offset: -5 } } },
   };
   

   useEffect(() => {
      countTraficData();
      countProductCategory();
      countSalesCategory();
      getTopProduct();
   }, []);

   const getTopProduct = async () => {
      let params = {
         "room_id": live.id,
         is_commerce: false,
         sort: 35,
         cursor: 0,
         sort_type: "DESC",
         size: 5,
      }
      let response = await Axios.post('/api/live/products', params).catch(e => console.error('Axios-Error: ' + e.message));
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200 && response.data.data) {
         if (response && response.status === 200) {
            setItems(response.data.data);
         }
      }
   }

   const countTraficData = () => {
      let labels = [], data = [], options = { ...pieChartOptions };
      staticData.map((value, index) => {
         value.ratio = (value.ratio * 100).toFixed(2) + '%'
         labels[index] = value.name + ' ' + value.ratio;
         data[index] = value.count;
      });
      options.labels = labels;
      setTraficShares({ data: data, options: options });
   };
   const countProductCategory = () => {
      let labels = [], data = [], options = { ...pieChartOptions };
      live.product_count_rate_list.map((value, index) => {
         value.ratio = value.product_count_rate.toFixed(2) + '%'
         labels[index] = value.category1 + ' ' + value.ratio;
         data[index] = value.product_count;
      });
      options.labels = labels;
      setProductCategoryList({ data: data, options: options });
   };
   const countSalesCategory = () => {
      let labels = [], data = [], options = { ...pieChartOptions };
      live.product_sales_rate_list.map((value, index) => {
         value.ratio = value.sold_count_rate.toFixed(2) + '%'
         labels[index] = value.category1 + ' ' + value.ratio;
         data[index] = value.sold_count;
      });
      options.labels = labels;
      setSalesCategoryListList({ data: data, options: options });
   }





   return (
      <>
         <div className='row'>
            <div className='col-lg-6'>
               <Card>
                  <Card.Body>
                     <div id="chart" className="text-left m-auto">
                        <div id="chart" className="text-left m-auto" style={{ width: "80%" }}>
                           <h5>{t('live_detail.share_of_trafic')}</h5>
                           <p>{t('live_detail.long_text_1')}</p>
                           {traficShares && <ReactApexChart options={traficShares.options} series={traficShares.data} type="donut" height={350} />}
                        </div>
                     </div>
                  </Card.Body>
               </Card>
            </div>
            <div className='col-lg-6'>
               <Card className='rounded-5'>
                  <Card.Body>
                     <div className="row mb-4">
                        <div className="col-lg-12">
                           <h3>{t('live_products.long_text_2')}</h3>
                        </div>
                        <div className="col-lg-12">
                           <div className="fh-table">
                              <Table responsive className="w-100 mt-3">
                                 <div id="example_wrapper" className="dataTables_wrapper">
                                    <table id="example" className="display w-100 dataTable">
                                       <thead>
                                          <tr role="row" className='bg-light'>
                                             {relevantProductHead.map((value, index) => (<th key={index} className={value.className}>{value.title}</th>))}
                                          </tr>
                                       </thead>
                                       <tbody>
                                          {items.map((value, index) => (
                                             <tr key={index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td>
                                                   <div className="d-flex">
                                                      <div className="prd-img mr-3">
                                                         <OverlayTrigger
                                                            key="right"
                                                            trigger={["hover", "focus"]}
                                                            placement="right"
                                                            rootClose
                                                            overlay={
                                                               <Popover>
                                                                  <Popover.Body>
                                                                     <img src={"https://t-img.picturehaven.net/product/" + value.cover + "?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={500} />
                                                                  </Popover.Body>
                                                               </Popover>
                                                            }
                                                         >
                                                            <Button variant="link" className='p-0' size="sm">
                                                               <Link to={'/products/' + value.id + '/details'} target="_blank">
                                                                  <img src={"https://t-img.picturehaven.net/product/" + value.cover + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />
                                                               </Link>
                                                            </Button>
                                                         </OverlayTrigger>
                                                      </div>
                                                      <div className="prd-title m-0 d-flex justify-content-center align-items-center">
                                                         <p className="m-0">
                                                            <OverlayTrigger overlay={<Tooltip>{value.title}</Tooltip>}>
                                                               <Link to={'/products/' + value.id + '/details'} target="_blank">
                                                                  <span>{value.title.length > 15 ? value.title.substring(0, 15) + '...' : value.title}</span>
                                                               </Link>
                                                            </OverlayTrigger>
                                                         </p>
                                                      </div>
                                                   </div>
                                                </td>
                                                <td className="text-left">{value.sold_count || '-'}</td>
                                                <td className="text-left">{value.total_sales_str || '-'}</td>
                                             </tr>
                                          ))}
                                          {relevantProductHead.length === 0 && <tr><td colSpan="4" className="text-center">{t('common.no_data_found')}</td></tr>}
                                       </tbody>
                                    </table>
                                 </div>
                              </Table>
                           </div>
                        </div>
                     </div>
                  </Card.Body>
               </Card>
            </div>
         </div>
         <Card className='rounded-0'>
            <Card.Body>
               <div className="row mb-4">
                  <div className="col-lg-5">
                     <h3>{t('live_detail.share_of_product_category')}</h3>
                  </div>
               </div>
               <div className='row'>
                  <div className="col-lg-6 mb-4">
                     <Card>
                        <Card.Body>
                           <div id="chart" className="text-left m-auto">
                              <div id="chart" className="text-left m-auto" style={{ width: "80%" }}>
                                 <h5>{t('live_detail.long_text_2')}</h5>
                                 <p>{t('live_detail.long_text_3')}</p>
                                 {productCategoryList && <ReactApexChart options={productCategoryList.options} series={productCategoryList.data} type="donut" height={350} />}
                              </div>
                           </div>
                        </Card.Body>
                     </Card>
                  </div>
                  <div className="col-lg-6 mb-4">
                     <Card>
                        <Card.Body>
                           <div id="chart" className="text-left m-auto">
                              <div id="chart" className="text-left m-auto" style={{ width: "80%" }}>
                                 <h5>{t('live_detail.long_text_4')}</h5>
                                 <p>{t('live_detail.long_text_5')}</p>
                                 {salesCategoryList && <ReactApexChart options={salesCategoryList.options} series={salesCategoryList.data} type="donut" height={350} />}
                              </div>
                           </div>
                        </Card.Body>
                     </Card>
                  </div>
               </div>
            </Card.Body>
         </Card>

      </>
   );
};

export default BasicInformationTab;