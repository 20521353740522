import React, { useContext, useEffect, useState } from "react";
import Axios from "../plugins/axios";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { Context } from '../../MasterLayout';
import { GeneralContext } from '../../GeneralLayout';
import { useTranslation } from 'react-i18next';
import Footer from "./components/Footer";
import Header from "./components/Header";

import fetchimage from "../../images/check.png";
import { Link, useParams } from "react-router-dom";

const OneoffForm = () => {
   const params = useParams();
   const { t } = useTranslation();
   const isUserLoggedIn = localStorage.getItem('user') && localStorage.getItem('token') ? true : false;
   const currentContext = localStorage.getItem('user') && localStorage.getItem('token') ? Context : GeneralContext;
   const { dispatch } = useContext(currentContext);
   const [questions, setQuestions] = useState([]);
   const [userData, setUserData] = useState(null);
   const [name, setName] = useState('');
   const [nameMessage, setNameMessage] = useState('');
   const [email, setEmail] = useState('');
   const [emailMessage, setEmailMessage] = useState('');
   const [yesOrNo, setYesOrNo] = useState('');
   const [show, setShow] = useState(false);

   const checkemail = (value) => {
      setEmail(value);
      // eslint-disable-next-line no-useless-escape
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!email) {
         setEmailMessage(t('registration.step_1.email_address_is_required'));
      } else {
         if (emailRegex.test(email)) {
            setEmailMessage('');
         } else {
            setEmailMessage(t('registration.step_1.long_text_5'));
         };
      };
   };

   useEffect(() => {
      isValidUrl();
   }, []);

   const isValidUrl = async () => {
      try {
         dispatch({ type: 'loader_show', response: true });
         const response = await Axios.get(`/api/influencer-videos/check-url?subid=${params.subscriptionid}`).catch(e => console.error(e.message));
         if (response && response.status === 200 && response.data.data) {
            setName(response.data.data.user_id.name);
            setEmail(response.data.data.user_id.email);
            setUserData(response.data.data);
            getQuestions();
         } else {
            window.location.href = '/';
         };
      } catch (error) {
         console.error(error.message);
      };
      dispatch({ type: 'loader_hide', response: true });
   };

   const getQuestions = async () => {
      try {
         dispatch({ type: 'loader_show', response: true });
         const response = await Axios.get('/api/influencer-videos/questions').catch(e => console.error(e.message));
         if (response && response.status === 200 && response.data.data) {
            response.data.data.map(v => v.answer = '');
            setQuestions(response.data.data);
         };
      } catch (error) {
         console.error(error.message);
      };
      dispatch({ type: 'loader_hide', response: true });
   };

   const answerAdd = async (e, videoKey) => {
      let ques = [...questions];
      let i = e.target.name;

      if (ques[i].type !== 'file') {
         ques[i].answer = e.target.value;
      } else if (ques[i].type === 'file' && ques[i].id != process.env.REACT_APP_QUE_ID) {
         ques[i].answer = e.target.files[0];
      } else if (ques[i].type === 'file' && ques[i].id == process.env.REACT_APP_QUE_ID) {
         if (Math.round((e.target.files.item(0).size / 1024)) >= 10240) {
            alert("File is too Big, please select a file less than 10mb");
         } else {
            ques[i].answer = { ...ques[i].answer };
            ques[i].answer[videoKey] = e.target.files[0];
         };
      };

      setQuestions(ques);
   };

   const submitForm = async () => {
      try {
         dispatch({ type: 'loader_show', response: true });
         let formData = new FormData();
         formData.append('userData', JSON.stringify(userData));
         formData.append('staticAnswers', JSON.stringify({ Name: name, Email: email.toLocaleLowerCase() }));

         let ques = [...questions], dataObj = {};
         for (let i = 0; i < ques.length; i++) {
            if (ques[i].type !== 'file') {
               dataObj[`${ques[i]._id}`] = ques[i].answer;
            } else if (ques[i].type === 'file' && ques[i].id == process.env.REACT_APP_QUE_ID) {
               if (ques[i].answer[0]) formData.append(ques[i]._id + ',0', ques[i].answer[0]);
               if (ques[i].answer[1]) formData.append(ques[i]._id + ',1', ques[i].answer[1]);
               if (ques[i].answer[2]) formData.append(ques[i]._id + ',2', ques[i].answer[2]);
            } else {
               formData.append(ques[i]._id, ques[i].answer);
            };
         };
         formData.append('answers', JSON.stringify(dataObj));

         let config = { headers: { "Content-Type": "multipart/form-data" } };
         let response = await Axios.post('/api/influencer-videos/answers', formData, config).catch(e => console.error('Axios-Catch: ' + e.message));

         if (response && response.status === 200) {
            setShow(!show);
         };
      } catch (error) {
         console.error(error.message);
      };
      dispatch({ type: 'loader_hide', response: true });
   };

   return (
      <>
         {isUserLoggedIn === false && <Header />}
         {isUserLoggedIn === false && <section id="home" className="hero-area hero-inner-area py-5">
            <div className="container">
               <div className="row align-items-center"></div>
            </div>
         </section>}
         <section id="pricing" className="pricing-table section">
            <div className="container">
               <div className="row">
                  <div className="col-lg-8">
                     <Card>
                        <Card.Body>
                           <Form.Group className="mb-3">
                              <Form.Label className="mb-0">{t('common.name')}</Form.Label>
                              <Form.Control
                                 type="text"
                                 className={`form-control form-control-sm ${nameMessage ? 'is-invalid' : ''} mb-2`}
                                 value={name}
                                 onChange={(e) => { setName(e.target.value); setNameMessage(''); }}
                                 onBlur={() => !name ? setNameMessage(t('common.name_message')) : ''}
                                 required
                              />
                              {nameMessage && <div className="invalid-feedback">{nameMessage}</div>}
                           </Form.Group>
                           <Form.Group className="mb-3">
                              <Form.Label className="mb-0">{t('registration.step_1.email_address')}</Form.Label>
                              <Form.Control
                                 type="email"
                                 className={`form-control form-control-sm ${emailMessage ? 'is-invalid' : ''} mb-2`}
                                 value={email}
                                 onChange={(e) => { setEmail(e.target.value); setEmailMessage('') }}
                                 onBlur={(e) => !email ? setEmailMessage(t('registration.step_1.long_text_2')) : checkemail(e.target.value)}
                                 required
                              />
                              {emailMessage && <div className="invalid-feedback">{emailMessage}</div>}
                           </Form.Group>
                           <Form.Group className="mb-3">
                              <Form.Label className="mr-2 mb-0">{t('registration.step_1.long_text_8')}</Form.Label>
                              <Form.Check
                                 type="radio"
                                 id="yes"
                                 className="ms-0 me-0"
                                 value="yes"
                                 inline name="yesOrNo"
                                 checked={yesOrNo === 'yes'}
                                 onChange={(e) => setYesOrNo(e.target.value)}
                                 label="Yes"
                              />
                              <Form.Check
                                 type="radio"
                                 id="no"
                                 className="ms-3 me-0"
                                 value="no"
                                 inline name="yesOrNo"
                                 checked={yesOrNo === 'no'}
                                 onChange={(e) => setYesOrNo(e.target.value)}
                                 label="No"
                              />
                           </Form.Group>
                           {questions && questions.length > 0 && yesOrNo === 'yes' &&
                              questions.map((v, i) => (
                                 <div key={i} className="mb-2">
                                    {v.type === 'textarea'
                                       ?
                                       <Form.Group className="mb-3">
                                          <Form.Label className="mr-2 mb-0">{v.question}</Form.Label>
                                          <Form.Control as={v.type} value={v.answer} rows={3} required={v.is_mandatory} name={i} onChange={(e) => answerAdd(e)} />
                                       </Form.Group>
                                       :
                                       v.type === 'radio' || v.type === 'checkbox'
                                          ?
                                          <Form.Group>
                                             <Form.Label className="mb-0">{v.question}</Form.Label>
                                             <Form.Check type={v.type} label="Yes" value='yes' className="ms-3 me-0" inline name={i} checked={v.answer === 'yes'} onChange={(e) => answerAdd(e)} />
                                             <Form.Check type={v.type} label="No" value='no' className="ms-3 me-0" inline name={i} checked={v.answer === 'no'} onChange={(e) => answerAdd(e)} />
                                          </Form.Group>
                                          :
                                          v.type === 'file'
                                             ?
                                             v.type === 'file' && v.id == process.env.REACT_APP_QUE_ID
                                                ?
                                                <>
                                                   <Form.Group>
                                                      <Form.Label className="mb-0">{v.question}</Form.Label>
                                                      <Form.Control
                                                         name={i}
                                                         className={`form-control form-control-sm`}
                                                         type={v.type}
                                                         onChange={(e) => answerAdd(e, 0)}
                                                         required={v.is_mandatory}
                                                         accept="video/*"
                                                      />
                                                      <Form.Control
                                                         name={i}
                                                         className={`form-control form-control-sm`}
                                                         type={v.type}
                                                         onChange={(e) => answerAdd(e, 1)}
                                                         required={v.is_mandatory}
                                                         accept="video/*"
                                                      />
                                                      <Form.Control
                                                         name={i}
                                                         className={`form-control form-control-sm`}
                                                         type={v.type}
                                                         onChange={(e) => answerAdd(e, 2)}
                                                         required={v.is_mandatory}
                                                         accept="video/*"
                                                      />
                                                   </Form.Group>
                                                </>
                                                :
                                                <Form.Group>
                                                   <Form.Label className="mb-0">{v.question}</Form.Label>
                                                   <Form.Control
                                                      name={i}
                                                      className={`form-control form-control-sm`}
                                                      type={v.type}
                                                      onChange={(e) => answerAdd(e)}
                                                      required={v.is_mandatory}
                                                   />
                                                </Form.Group>
                                             :
                                             <Form.Group>
                                                <Form.Label className="mb-0">{v.question}</Form.Label>
                                                <Form.Control
                                                   name={i}
                                                   className={`form-control form-control-sm`}
                                                   value={v.answer}
                                                   type={v.type}
                                                   onChange={(e) => answerAdd(e)}
                                                   required={v.is_mandatory}
                                                />
                                             </Form.Group>
                                    }
                                 </div>
                              ))
                           }

                           {yesOrNo === 'no' && <div>
                              <p className="lead mb-0">{t('registration.step_1.no_problem')}</p>
                              <p className="mb-0">{t('registration.step_1.long_text_9')}</p>
                              <p>{t('registration.step_1.long_text_10')}</p>
                           </div>}
                           {yesOrNo !== '' && <Button className="my-2" variant="primary" size="sm" onClick={() => submitForm()}>{t('product_review.submit')}</Button>}
                        </Card.Body>
                     </Card>

                  </div>
                  <div className="col-lg-4">
                     <h1 className="text-primary">{t('registration.step_3.THANK_YOU')}</h1>
                     <h3>{t('registration.step_3.long_text_15')}</h3>
                     <p><strong>{t('registration.step_3.long_text_16')}</strong></p>
                     <p className="mb-0"><strong>{t('registration.step_3.long_text_17')}</strong></p>
                     <p>{t('registration.step_3.long_text_18')}</p>

                     <p className="mb-0"><strong>{t('registration.step_3.long_text_19')}</strong></p>
                     <p>{t('registration.step_3.long_text_20')}</p>

                     <p className="mb-0"><strong>{t('registration.step_3.long_text_21')}</strong></p>
                     <p>{t('registration.step_3.long_text_22')}</p>
                  </div>
               </div>
            </div>
         </section>
         {isUserLoggedIn === false && <section className="section call-action">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                     <div className="cta-content">
                        <h2 className="wow fadeInUp">{t('pricing.focus_on_tiktok_analytics')}</h2>
                        <div className="button wow fadeInUp">
                           <a href="/pre-register" className="btn">{t('pricing.start_free_trial')}</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>}
         {isUserLoggedIn === false && <Footer />}
         <Modal show={show} onHide={() => setShow(!show)} backdrop="static" keyboard={false} centered>
            <Modal.Body>
               <div className="text-center">
                  <img src={fetchimage} alt="" style={{ height: "155px", width: "101px" }} />
                  <h3 className="mt-4">{t('pricing.thankyou_message')}</h3>
                  <p>{t('pricing.message_1')}</p>
                  <Link to="/">
                     <Button variant="primary" size="sm" onClick={() => setShow(!show)}>
                        <strong>{t('pricing.back_to_home')}</strong>
                     </Button>
                  </Link>
               </div>
            </Modal.Body>
         </Modal>
      </>
   )
};

export default OneoffForm;