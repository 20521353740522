import React, { useState, useEffect, useContext } from "react";
import "../../css/landing/landing.css";
import "../../css/landing/bootstrap.min.css";
import "../../css/landing/glightbox.min.css";
import "../../css/landing/main.css";
import "../../css/landing/tiny-slider.css";
import { Modal, Card, Button, Accordion } from 'react-bootstrap'
import ProductsresearchImg from "../../../src/images/products-research.png";
import AffiliateImg from "../../../src/images/affiliate.png";
import ProfitLossImg from "../../../src/images/profit-loss.png";
import ReviewImg from "../../../src/images/Review-1.jpg.webp";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Link } from "react-router-dom";
import { TikTokEmbed } from 'react-social-media-embed';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Context } from '../../MasterLayout';
import { GeneralContext } from '../../GeneralLayout';

import { useTranslation } from 'react-i18next';

const Affiliate = () => {
   const { t } = useTranslation();
   const isUserLoggedIn = localStorage.getItem('user') && localStorage.getItem('token') ? true : false;
   const currentContext = localStorage.getItem('user') && localStorage.getItem('token') ? Context : GeneralContext;
   const { dispatch } = useContext(currentContext);
   const [show, setShow] = useState(false);
   const [openVideo, setOpenVideo] = useState('');

   const videoArray = [
      'influencer-videos/maria-vid-1.mp4',
      'influencer-videos/maria-video-2.mp4',
      'influencer-videos/cristina-video.mp4',
      'influencer-videos/cristina-video-1.mp4',
      'influencer-videos/silvi-s-video-1.mp4',
      'influencer-videos/silvi-s-video-2.mp4',
      'influencer-videos/juandi-g-video-1.mp4',
      'influencer-videos/juandi-g-video-2.mp4',
      'influencer-videos/helen-b-video-1.mp4',
      'influencer-videos/eru-b-video-1.mp4',
   ];

   const sliderVideoArray = [
      'influencer-videos/maria-video-3.mp4',
      'influencer-videos/juandi-g-video-3.mp4',
      'KEET-IT(2nd-index)-EMPTY',
      'influencer-videos/helen-b-video-2.mp4',
      'influencer-videos/eru-b-video-2.mp4',
   ];

   const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
         {
            breakpoint: 988,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 2,
               initialSlide: 2,
            },
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
            },
         },
      ],
   };

   return (
      <>
         {isUserLoggedIn == false && <Header />}
         <section className="hero-area hero-inner-area">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-7 col-md-12 col-12">
                     <div className="hero-content">
                        <h1 className="wow fadeInLeft mb-0">
                           {t('product_review.title')}
                        </h1>
                        <p className="wow fadeInLeft">
                           {t('product_review.description')}
                        </p>
                        <div className="button wow fadeInLeft mb-2">
                           <Link to="/content-creation">
                              <Button className="btn">
                                 {t('product_review.order_button')}
                              </Button>
                           </Link>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-5 col-md-12 col-12 text-center">
                     <div className="hero-image wow fadeInRight">
                        <img src={ProductsresearchImg} alt="" />
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="section call-action">
            <div className="container">
               <div className="row">
                  <div className="col-lg-4">
                     <h2 className="wow fadeInUp mb-2 text-white text-center">
                        {t('product_review.string_1')}
                     </h2>
                  </div>
                  <div className="col-lg-4">
                     <h2 className="wow fadeInUp mb-2 text-white text-center">
                        {t('product_review.string_2')}
                     </h2>
                  </div>
                  <div className="col-lg-4">
                     <h2 className="wow fadeInUp mb-2 text-white text-center">
                        {t('product_review.string_3')}
                     </h2>
                  </div>
               </div>
            </div>
         </section>

         <section className="pricing-table section pb-0">
            <div className="container" style={{ maxWidth: '1700px' }}>
               <div className="row mb-5">
                  <div className="col-12">
                     <div className="section-title mb-0 px-0">
                        <h2 className="wow fadeInUp">
                           {t('product_review.title_2')}
                        </h2>
                     </div>
                  </div>
               </div>
               <div className="row justify-content-center">
                  {videoArray.map((v, i) => (
                     <div className="col-xl-2 col-lg-4 col-md-6 pt-5" key={i}>
                        <Card style={{ height: 'auto' }}>
                           <div className='d-flex justify-content-center align-items-center'>
                              <div style={{ width: '100%', objectFit: 'cover' }}>
                                 <div className="prd-img-front">
                                    <video className="w-100" loop>
                                       <source src={`http://${process.env.REACT_APP_S3BUCKET_NAME}.s3.amazonaws.com/${v}`} type="video/mp4" allowFullScreen />
                                    </video>
                                    <Button variant="primary" className="p-0 video-popup-btn" onClick={() => { setOpenVideo(v); setShow(!show); }}>
                                       <i className="fa fa-play-circle" aria-hidden="true"></i>
                                    </Button>
                                 </div>
                              </div>
                           </div>
                        </Card>
                     </div>
                  ))}
               </div>
            </div>
         </section>

         <section className="features section brand-trust mt-5">
            <div className="container">
               <div className="row mb-2">
                  <div className="col-12">
                     <div className="section-title mb-0 px-0">
                        <h2 className="wow fadeInUp">{t('product_review.title_3')}</h2>
                     </div>
                  </div>
               </div>
               <div className="row mb-5 align-items-center">
                  <div className="col-lg-4">
                     <div className="bg-light shadow-none card">
                        <div className="d-flex align-items-center card-body pb-0">
                           <span className="bolt-icon mr-sm-3 mr-2">
                              <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                           </span>
                           <h4 className="mb-0 ">{t('product_review.feature_title_1')}</h4>
                        </div>
                        <div className="p-4 pt-0">
                           {t('product_review.feature_description_1')}
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-4">
                     <div className="bg-light shadow-none card">
                        <div className="d-flex align-items-center card-body pb-0">
                           <span className="bolt-icon mr-sm-3 mr-2">
                              <i className="fa fa-star-o" aria-hidden="true"></i>
                           </span>
                           <h4 className="mb-0 ">{t('product_review.feature_title_2')}</h4>
                        </div>
                        <div className="p-4 pt-0">
                           {t('product_review.feature_description_2')}
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-4">
                     <div className="bg-light shadow-none card h-100">
                        <div className="d-flex align-items-center card-body pb-0">
                           <span className="bolt-icon mr-sm-3 mr-2">
                              <i className="fa fa-mouse-pointer" aria-hidden="true"></i>
                           </span>
                           <h4 className="mb-0 ">{t('product_review.feature_title_3')}</h4>
                        </div>
                        <div className="p-4 pt-0">
                           {t('product_review.feature_description_3')}
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12 text-center mt-3">
                     <Link to="/content-creation">
                        <Button className="btn">
                           {t('product_review.feature_button')}
                        </Button>
                     </Link>
                  </div>
               </div>
            </div>
         </section>

         <section className="section pb-0 mb-5">
            <div className="container-fluid">
               <div className="row mb-4">
                  <div className="col-12">
                     <div className="section-title mb-0 px-0">
                        <h2 className="wow fadeInUp" dangerouslySetInnerHTML={{ __html: t('product_review.title_4') }}>
                        </h2>
                     </div>
                  </div>
               </div>
               <Slider {...settings}>
                  {sliderVideoArray.map((v, i) => (
                     <div key={i}>
                        {i !== 2 && <div className="items mr-2">
                           <Card style={{ height: 'auto' }} className="mb-0">
                              <div className='d-flex justify-content-center align-items-center'>
                                 <div style={{ width: '100%', objectFit: 'cover' }}>
                                    <div className="prd-img-front">
                                       <video className="w-100" loop>
                                          <source src={`http://${process.env.REACT_APP_S3BUCKET_NAME}.s3.amazonaws.com/${v}`} type="video/mp4" allowFullScreen />
                                       </video>
                                       <Button variant="primary" className="p-0 video-popup-btn" onClick={() => { setOpenVideo(v); setShow(!show); }}>
                                          <i className="fa fa-play-circle" aria-hidden="true"></i>
                                       </Button>
                                    </div>
                                 </div>
                              </div>
                           </Card>
                        </div>}
                        {i === 2 && <div className="items mr-2">
                           <Card style={{ height: 'auto' }} className="mb-0">
                              <div className='d-flex justify-content-center align-items-center slider-signup'>
                                 <div className="prd-img-front">
                                    <img src={ReviewImg} alt="" style={{ opacity: '0' }} />
                                    <div className="slider-signup-wrap">
                                       <div className="display-4"><strong>{t('product_review.number')}</strong></div>
                                       <div className="lead" dangerouslySetInnerHTML={{ __html: t('product_review.desc_2') }}></div>
                                       <Link to="/content-creation" className="mt-3 py-2 btn btn-secondary">
                                          {t('product_review.order_video')}
                                       </Link>
                                    </div>
                                 </div>
                              </div>
                           </Card>
                        </div>}
                     </div>
                  ))}
               </Slider>
            </div>
         </section>

         {isUserLoggedIn == false && <section className="features section brand-trust-cta mt-5">
            <div className="container">
               <div className="row mb-5 justify-content-center">
                  <div className="col-lg-12">
                     <div className="brand-trust-wrap p-5">
                        <div className="row justify-content-center">
                           <div className="col-lg-6">
                              <h1>{t('product_review.title_5')}</h1>
                              <p className="lead">{t('product_review.desc_3')}</p>
                              <Link to="/pre-register">
                                 <Button className="btn" variant="primary">
                                    {t('product_review.sign_up')}
                                 </Button>
                              </Link>
                           </div>
                           <div className="col-lg-6" >

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>}

         {/* <section className="pricing-table section pb-0 mb-5 pt-0">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="section-title mb-0 px-0">
                        <h2 className="wow fadeInUp mb-0">{t('product_review.title_6')}</h2>
                        <p className="wow fadeInUp lead" dangerouslySetInnerHTML={{ __html: t('product_review.desc_4')}}>
                           
                        </p>
                     </div>
                  </div>
               </div>
               <div className="row justify-content-center">
                  <div className="col-lg-10">
                     <Accordion defaultActiveKey="0"
                        className="accordion-no-gutter accordion-bordered">
                        <Accordion.Item eventKey="0">
                           <Accordion.Header>{t('product_review.list_title_1')}</Accordion.Header>
                           <Accordion.Body>
                           {t('product_review.list_desc_1')}
                           </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                           <Accordion.Header>{t('product_review.list_title_2')}</Accordion.Header>
                           <Accordion.Body>
                           {t('product_review.list_desc_2')}
                           </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                           <Accordion.Header>{t('product_review.list_title_3')}</Accordion.Header>
                           <Accordion.Body>
                           {t('product_review.list_desc_3')}
                           </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                           <Accordion.Header>{t('product_review.list_title_4')}</Accordion.Header>
                           <Accordion.Body>
                           {t('product_review.list_desc_4')}
                           </Accordion.Body>
                        </Accordion.Item>
                     </Accordion>
                  </div>
               </div>
            </div>
         </section> */}

         {
            isUserLoggedIn == false && <section className="section call-action">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-12 col-md-12 col-12">
                        <div className="cta-content">
                           <h2 className="wow fadeInUp mb-2">{t('affiliate.start_earning')}</h2>
                           <p className="mb-2">{t('affiliate.sign_up_now_and_transform')}</p>
                           <div className="button wow fadeInUp mt-3">
                              <a href="/pre-register" className="btn">{t('affiliate.start_free_trial')}</a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         }

         {isUserLoggedIn == false && <Footer />}

         <Modal className="fade video-popup" show={show} onHide={() => { setOpenVideo(''); setShow(!show); }} backdrop="static" keyboard={false}>
            <Modal.Body className='p-0'>
               <div className='d-flex'>
                  <div className='w-75 text-center'>
                     <video className="w-100" autoPlay controls loop>
                        <source src={`http://${process.env.REACT_APP_S3BUCKET_NAME}.s3.amazonaws.com/${openVideo}`} type="video/mp4" allowFullScreen />
                     </video>
                     <div className='d-flex bg-light px-3 py-3 align-items-center justify-content-center'>
                        <p className="mb-0 mr-2"><strong>Like this?</strong></p>
                        <Link to="/content-creation"><Button variant="primary" className="mb-0">Get a video</Button></Link>
                     </div>
                  </div>
                  <div className='px-3'>
                     <div className='d-flex flex-column'>
                        <Button variant="dark" className="mb-3" onClick={() => { setOpenVideo(''); setShow(!show); }}>
                           <i className="fa fa-times" aria-hidden="true"></i>
                        </Button>
                     </div>
                  </div>
               </div>
            </Modal.Body>
         </Modal>
      </>
   );
};

export default Affiliate;