import React from 'react'
import InfiniteScrollWrapper from '../../Products/InfiniteScrollWrapper';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import Conversion from '../../CommonComponents/Conversion';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function TopLiveStreamers({ sortType, sorting, sortClass, handleSort, functionNext, items, favourite, markFavourite, hasMore }) {
  const { t } = useTranslation();

  const tableHead = [
    {
      title: '#',
      className: 'text-right',
    },
    {
      title: t('common.influencers'),
    },
    {
      title: t('common.revenue'),
      className: 'text-right sorting',
      sort: 61,
    },
    {
      title: t('common.sales'),
      className: 'text-right sorting',
      sort: 60,
    },
    {
      title: t('influencers.atv'),
      className: 'text-right sorting',
      sort: 62,
    },
    {
      title: t('influencers.related_products'),
      className: 'text-right sorting',
      sort: 63,
    },
    {
      title: t('influencers.related_live'),
      className: 'text-right sorting',
      sort: 64,
    },
    {
      title: t('influencers.avg_views'),
      className: 'text-right sorting',
      sort: 65,
    },
    {
      title: t('influencers.cummulative_views'),
      className: 'text-right sorting',
      sort: 66,
    },
  ];

  const countryicon = Object.keys(global?.config?.region).find(key => global?.config?.region[key] === items[0]?.sys_country?.country)

  return (
    <div>
      <InfiniteScrollWrapper
        isInfiniteScrollOn={true}
        lengthData={items.length}
        functionNext={functionNext}
        hasMore={hasMore}
      >
        <table id="example" className="display w-100 dataTable">
          <thead>
            <tr role="row">
              {tableHead.map((value, index) => (
                <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort, sorting.sort == value.sort ? sortType : 'DESC') }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
              ))}
            </tr>
          </thead>
          <tbody>
            {items?.map((influencer, index) => (
              <tr key={index}>
                <td>{influencer.rank}</td>
                <td>
                  <div className='d-flex'>
                    <div className="prd-img mr-3">
                      <OverlayTrigger
                        key="right"
                        trigger={["hover", "focus"]}
                        placement="right"
                        rootClose
                        overlay={
                          <Popover>
                            <Popover.Body>
                              {influencer?.author?.author_avatar &&
                                <img alt="" width={500} src={`https://t-img.picturehaven.net/tikmeta/${influencer?.author?.author_avatar}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                              }
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <Button variant="link" className='p-0' size="sm">
                          <Link to={'/influencers/details/' + influencer.id + "/live-streamers"} target="_blank">
                            {influencer?.author?.author_avatar &&
                              <img alt="" width={70} className="prod-thum-img" src={`https://t-img.picturehaven.net/tikmeta/${influencer?.author?.author_avatar}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                            }
                          </Link>
                        </Button>
                      </OverlayTrigger>
                    </div>
                    <div className='prd-title'>
                      <Link to={'/influencers/details/' + influencer.id + "/live-streamers"} target="_blank">
                        <p>{influencer?.author?.nickname || '--'}</p>
                      </Link>
                      <p className='small'><span className={`fi fi-${countryicon.toLocaleLowerCase()} mr-1`}></span>{influencer?.sys_country?.country}</p>
                      {influencer?.category_sales_list && influencer?.category_sales_list.length != 0 && <div className="mb-1">
                        <span className="btn btn-outline-dark btn-sm px-2 py-1 mr-1 mb-1">{influencer?.category_sales_list[0]?.name || influencer?.category_sales_list[0]?.category1}</span> <br />
                        {influencer?.category_sales_list[1]?.name && <><span className="btn btn-outline-dark btn-sm px-2 py-1 mr-1 mb-1">{influencer?.category_sales_list[1]?.name || influencer?.category_sales_list[1]?.category1}</span> <br /></>}
                        {influencer?.category_sales_list.length > 2 && <OverlayTrigger
                          key="right"
                          trigger="click"
                          placement="bottom"
                          rootClose
                          overlay={
                            <Popover className="variants-tooltip">
                              <Popover.Body>
                                <div className="m-2">
                                  {influencer.category_sales_list && influencer.category_sales_list.map((v, i) => {
                                    if (i != 0 && i != 1) return <span key={i} className="btn btn-outline-dark btn-sm px-2 py-1  mr-1">{v.name || v.category1}</span>
                                  })}
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <Button variant="outline-dark" size="sm" className='px-2 py-1'>{influencer.category_sales_list.length > 1 ? ('+' + (influencer.category_sales_list.length - 2)) : ''}  <i className="fa fa-chevron-down" aria-hidden="true"></i></Button>
                        </OverlayTrigger>}
                      </div>}
                    </div>
                  </div>
                </td>
                <td className='text-right'>
                    {influencer?.total_sales_usd} <br />
                    <span className='text-muted'> ({influencer?.total_sales})</span>
                </td>
                <td className='text-right'>
                  <Conversion value={influencer?.sold_count} />
                </td>
                <td className='text-right'>
                    {influencer?.avg_price_usd} <br />
                    <span className='text-muted'>({influencer?.avg_price})</span>                  
                </td>
                <td className='text-right'>{influencer?.product_count}</td>
                <td className='text-right'>{influencer?.live_count}</td>
                <td className='text-right'>{influencer?.avg_user_count}</td>
                <td className='text-right'><Conversion value={influencer.avg_total_user || 0} /></td>
              </tr>
            ))}
          </tbody>
        </table>
      </InfiniteScrollWrapper>
    </div>
  )
}

export default TopLiveStreamers