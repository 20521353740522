import React, { useEffect, useState, useContext } from 'react';
import Axios from "../../../plugins/axios";
import { Tab, Nav, Card, Button, OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Conversion from "../../CommonComponents/Conversion";
import { Context } from '../../../../MasterLayout';
import { useTranslation } from 'react-i18next';
import BasicInformationTab from './BasicInformationTab';
import LiveProducts from './LiveProducts';


const LiveDetails = () => {
    const { t } = useTranslation();

    const { dispatch } = useContext(Context);
    const params = useParams();
    const [live, setLive] = useState(null);
    const [category, setCategory] = useState([]);

    useEffect(() => {
        if (category.length === 0) {
            getCategory();
        }
        liveDetail();
    }, []);

    const getCategory = async () => {
        const response = await Axios.get('/api/categories');

        setCategory(response?.data?.data || []);
    };

    const liveDetail = async () => {
        try {
            let response = await Axios.post('/api/live/details', { room_id: params.liveId });
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.status === 200 && response.data.data) {
                setLive(response.data.data);
            }

        } catch (error) {
            console.error('Error: ' + error.message);
        };
    }
    function convertTimestampToDate(timestamp) {
        var date = new Date(timestamp);
        var year = date.getFullYear();
        var month = ('0' + (date.getMonth() + 1)).slice(-2);
        var day = ('0' + date.getDate()).slice(-2);
        var hour = ('0' + date.getHours()).slice(-2);
        var minute = ('0' + date.getMinutes()).slice(-2);
        var second = ('0' + date.getSeconds()).slice(-2);
        var formattedDate = `${year}/${month}/${day} ${hour}:${minute}:${second}`;
        return formattedDate;
    }

    return (
        <>
            {live &&
                <>
                    <Card>
                        <Card.Body>
                            <div className='row'>
                                <div className='col-lg-1'>
                                    {live?.room_cover && <img src={`https://t-img.picturehaven.net/product/${live.room_cover}?imageMogr2/auto-orient/thumbnail/270x/strip/format/WEBP/quality/75!/ignore-error/1`} alt="" width={108} height={130} style={{ objectFit: 'cover', boxSizing: 'inherit', borderRadius: '7px' }} />}
                                </div>
                                <div className='col-lg-10'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='d-flex align-items-center'>
                                            <h4 className='mb-0 mr-5'>{live.title}</h4>


                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <div className='mb-3'>
                                                <p className='mt-3 mr-5'>Start time : {convertTimestampToDate(live.live_start_time)}</p>
                                                <p className='mt-3 mr-5'>End  time : {convertTimestampToDate(live.live_finish_time)}</p>
                                                {live?.category_sales_count_list && live?.category_sales_count_list.length != 0 && <p><strong>{t('lives.main_category')}: </strong>
                                                    <span className="btn btn-outline-dark btn-sm m-1">{live?.category_sales_count_list[0]?.category1}</span>
                                                    {live?.category_sales_count_list[1] && <span className="btn btn-outline-dark btn-sm m-1">{live?.category_sales_count_list[1]?.category1}</span>}
                                                    {live?.category_sales_count_list.length > 2 && <OverlayTrigger
                                                        key="right"
                                                        trigger="click"
                                                        placement="right"
                                                        rootClose
                                                        overlay={<Popover className="variants-tooltip">
                                                            <Popover.Body>
                                                                <div className="m-2">
                                                                    {live?.category_sales_count_list && live.category_sales_count_list.map((v, i) => {
                                                                        return (i > 1) ? <Button key={i} variant="outline-dark" size="sm" className="mb-1 mr-1">{v.category1}</Button> : ''
                                                                    })}
                                                                </div>
                                                            </Popover.Body>
                                                        </Popover>}
                                                    >
                                                        <Button variant="outline-dark" size="sm" className='m-1'>{live?.category_sales_count_list.length > 2 ? ('+' + (live.category_sales_count_list.length - 2)) : ''} <i className="fa fa-chevron-down" aria-hidden="true"></i></Button>
                                                    </OverlayTrigger>}
                                                </p>}
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='row mt-4 ml-2 d-flex align-items-center justify-content-center'>
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    {live?.room_cover && <img src={`https://t-img.picturehaven.net/product/${live.room_cover}?imageMogr2/auto-orient/thumbnail/270x/strip/format/WEBP/quality/75!/ignore-error/1`} alt="" width={48} height={48} style={{ borderRadius: '50%' }} />}
                                                    <div className='ml-2'>
                                                        <h4 className='mb-0 mr-5'>{live.title}</h4>

                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            <p className='m-0'>@{live.unique_id}</p>
                                                            <p className='my-0 ml-2'>{t('live_detail.followers')} : <Conversion value={live?.follower_count} /></p>
                                                            <p className='my-0 ml-2'>{t('live_detail.videos')} : <Conversion value={live?.video_count} /></p>
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <h3>{t('live_detail.trafic_statistic')}</h3>
                                    <div className='d-flex'>
                                        <div>
                                            <h5>{t('live_detail.cumulative_viewers')}</h5>
                                            <p>{live?.live_user_count ? <Conversion value={live?.live_user_count.total_user} /> : '0'}</p>
                                        </div>
                                        <div>
                                            <h5>{t('live_detail.online_viewers_peak')}</h5>
                                            <p>{live?.live_user_count ? <Conversion value={live?.live_user_count.max_user_count} /> : '0'}</p>
                                        </div>
                                        <div>
                                            <h5>{t('live_detail.average_viewers')}</h5>
                                            <p>{live?.live_user_count ? <Conversion value={live?.live_user_count.avg_user_count} /> : '0'}</p>
                                        </div>
                                        <div>
                                            <h5>{t('live_detail.follower_growth')}</h5>
                                            <p>{live?.live_user_count ? <Conversion value={live?.live_user_count.follower_count_incr} /> : '0'}</p>
                                        </div>
                                        <div>
                                            <h5>{t('live_detail.er')}</h5>
                                            <p>{live?.live_user_count ? live?.live_user_count.follower_conversion_rate : '0'}%</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <h3>{t('live_detail.transaction_statistic')}</h3>
                                    <div className='d-flex gap-4'>
                                        <div>
                                            <h5>{t('live_detail.revenue')}</h5>
                                            <p>{live?.product_sales_count ? live?.product_sales_count.total_sales_usd_str : '0'}</p>
                                        </div>
                                        <div>
                                            <h5>{t('live_detail.sales')}</h5>
                                            <p>{live?.product_sales_count ? live?.product_sales_count.sold_count : '0'}</p>
                                        </div>
                                        <div>
                                            <h5>{t('live_detail.atp')}</h5>
                                            <p>{live?.product_sales_count ? live?.product_sales_count.avg_price_usd_str : '0'}</p>
                                        </div>
                                        <div>
                                            <h5>{t('live_detail.product')}</h5>
                                            <p>{live?.product_sales_count ? <Conversion value={live?.product_sales_count.product_count} /> : '0'}</p>
                                        </div>
                                        <div>
                                            <h5>{t('live_detail.sales_conversion_rate')}</h5>
                                            <p>{live?.product_sales_count ? live?.product_sales_count.sales_conversion_rate : '0'}%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <div className="custom-tab-1">
                        <Tab.Container defaultActiveKey="basicInformation">
                            <Nav as="ul" className="nav-tabs mb-0 bg-white">
                                <Nav.Item as="li" key="basicInformation">
                                    <Nav.Link eventKey="basicInformation" as={Link}>
                                        <i className="fa fa-bar-chart" aria-hidden="true"></i> {t('common.basic_information')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li" key="Products">
                                    <Nav.Link eventKey="Products" as={Link} to="#Products">
                                        <i className="fa fa-liveping-bag" aria-hidden="true"></i> {t('common.products')}</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="basicInformation" key="basicInformation">
                                    <BasicInformationTab live={live} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="Products" key="Products">
                                    <LiveProducts live={live}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </>
            }
        </>
    );
};

export default LiveDetails;