import React, { useContext, useMemo } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Context } from '../../../../MasterLayout';
import { useTranslation } from 'react-i18next';
import { useFilters, useGlobalFilter, useTable } from 'react-table';
import { GlobalFilter } from './GlobalFilter';

const RecurringUsers = ({ data }) => {
  const { dispatch } = useContext(Context);
  const { t } = useTranslation();

  const headGroup = [
    {
      Header: t('register_finance_report.date'),
      className: 'text-right',
      accessor: '_id',
      Filter: '',
    },
    {
      Header: t('register_finance_report.total_recurrence'),
      className: 'text-right',
      accessor: 'recurringCount',
      Filter: '',
    },
    {
      Header: t('register_finance_report.total_cost'),
      className: 'text-right',
      accessor: 'amount',
      Filter: '',
    },
    {
      Header: t('register_finance_report.total_discount'),
      className: 'text-right',
      accessor: 'discount_amount',
      Filter: '',
    },
    {
      Header: t('register_finance_report.total_refund'),
      className: 'text-right',
      accessor: 'amount_refunded',
      Filter: '',
    },
  ];

  const headList = [
    {
      Header: t('register_finance_report.plan'),
      className: 'text-right',
      accessor: 'plan',
      Filter: '',
    },
    {
      Header: t('register_finance_report.purchased_at'),
      className: 'text-right',
      accessor: 'timeZoneDate',
      Filter: '',
    },
    {
      Header: t('register_finance_report.cost'),
      className: 'text-right',
      accessor: 'amount',
      Filter: '',
    },
    {
      Header: t('register_finance_report.coupons'),
      className: 'text-right',
      accessor: 'discount_code',
      Filter: '',
    },
    {
      Header: t('register_finance_report.discount_amount'),
      className: 'text-right',
      accessor: 'discount_amount',
      Filter: '',
    },
    {
      Header: t('register_finance_report.amount_refunded'),
      className: 'text-right',
      accessor: 'amount_refunded',
      Filter: '',
    },
    {
      Header: t('register_finance_report.qty'),
      className: 'text-right',
      accessor: 'quantity',
      Filter: '',
    },
    {
      Header: t('register_finance_report.invoice'),
      className: 'text-right',
      accessor: 'invoice',
      Filter: '',
    },
    {
      Header: t('register_finance_report.description'),
      className: 'text-right',
      accessor: 'description',
      Filter: '',
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => headList, []); const list = useMemo(() => data.list, []);  
  const tableInstance = useTable({ columns, data: list, initialState: {} }, useFilters, useGlobalFilter);
  const { headerGroups, state: stateFilter, setGlobalFilter } = tableInstance;
  const { globalFilter } = stateFilter;

  const downloadCsv = async (fileName, tableId, indexLength) => {
    try {
      dispatch({ type: 'loader_show', response: true });
      let filename = fileName + Date.now();
      let csv = [];

      let columsLength = [];
      for (let k = 0; k < indexLength; k++) {
        columsLength.push(k);
      };

      let rows = document.querySelectorAll(`#${tableId} tr`);

      for (let i = 0; i < rows.length; i++) {
        let row = [], cols = rows[i].querySelectorAll("td, th");
        for (let j = 0; j < cols.length; j++) {
          if (columsLength.indexOf(j) !== -1) {
            let rowData = cols[j].innerText;
            rowData = rowData.split(",").join(" ");
            row.push(rowData);
          };
        };
        csv.push(row);
      };

      let csvContent = csv.map((e) => e.join(",")).join("\n");
      let csvFile;
      let downloadLink;

      csvFile = new Blob([csvContent], { type: "text/csv" });
      downloadLink = document.createElement("a");
      downloadLink.download = filename + ".csv";
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    } catch (error) {
      console.error("Error-Catch: downloadCsv " + error.message);
    };
    dispatch({ type: 'loader_hide', response: true });
  };


  return (
    <div>
      <div className="row mb-4">
        <div className="col-lg-12">
          <h4>{t('register_finance_report.total_records')} {data.list.length}</h4>
          <div className="fh-table">
            <Table responsive className="w-100">
              <div id="example_wrapper" className="dataTables_wrapper">
                <Button variant="primary" type="button" size="sm" className="btn btn-primary" onClick={() => downloadCsv('RECCURING_USER_', 'RECCURINGUSERLISTGROUPBY', headGroup.length)}>CSV</Button>
                <table id="RECCURINGUSERLISTGROUPBY" className="display w-100 dataTable">
                  <thead>
                    <tr role="row" className='bg-light'>
                      {headGroup.map((v, i) => (<th key={i} className={v.className}>{v.Header}</th>))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.group.length > 0 ?
                      data.group.map((v, i) => (
                        <tr key={i}>
                          <td className="text-right">{v._id}</td>
                          <td className="text-right">{v.recurringCount}</td>
                          <td className="text-right">{Math.abs(v.amount / 100)}</td>
                          <td className="text-right">{Math.abs(v.discount_amount / 100)}</td>
                          <td className="text-right">{Math.abs(v.amount_refunded / 100)}</td>
                        </tr>)) : t('register_finance_report.no_data')}
                  </tbody>
                </table>
              </div>
            </Table>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-12">
          <div className="fh-table">
            <Table responsive className="w-100">
              <div id="example_wrapper" className="dataTables_wrapper">
                <Button variant="primary" type="button" size="sm" className="btn btn-primary" onClick={() => downloadCsv('RECCURING_USER_', 'RECCURINGUSERLIST', headList.length)}>CSV</Button>
                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                <table id="RECCURINGUSERLIST" className="table dataTable display w-100">
                  <thead>
                    {headerGroups.map((head, i) => (
                      <tr key={i} role="row" className='bg-light'>
                        {head.headers.map((column, j) => (<th key={j} className="text-right">{column.render('Header')}</th>))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {tableInstance.globalFilteredRows.length > 0 ? tableInstance.globalFilteredRows.map((v, i) => (
                      <tr key={i}>
                        <td className="text-right">{v.original.plan == process.env.REACT_APP_STANDARD_ANNUAL ? 'Standard Annual' : v.original.plan == process.env.REACT_APP_STANDARD_MONTHLY ? 'Standard Monthly' : v.original.plan == process.env.REACT_APP_FREE_TRIAL_PLAN ? 'Free Trail' : ''}</td>
                        <td className="text-right">{v.original.timeZoneDate.split('.')[0]}</td>
                        <td className="text-right">{Math.abs(v.original.amount / 100)}</td>
                        <td className="text-right">{v.original.discount_code}</td>
                        <td className="text-right">{Math.abs(v.original.discount_amount / 100)}</td>
                        <td className="text-right">{Math.abs(v.original.amount_refunded / 100)}</td>
                        <td className="text-right">{v.original.quantity}</td>
                        <td className="text-right">{v.original.invoice}</td>
                        <td className="text-right">{v.original.description}</td>
                      </tr>)) : t('register_finance_report.no_data')}
                  </tbody>
                </table>
              </div>
            </Table>
          </div>
          <div className="dataTables_info">
            {data.list.length == 0 ? t('register_finance_report.no_record') : `${t('register_finance_report.showing')} 1 ${t('register_finance_report.to')} ${data.list.length} ${t('register_finance_report.of')} ${data.list.length} ${t('register_finance_report.entries')}`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecurringUsers;