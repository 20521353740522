import React from "react";
import { Card } from "react-bootstrap";

const Reports = () => {

   return (
      <>
         <Card>
            <Card.Body>
               <div className="row justify-content-center">
                  <div className="col-lg-4">
                     <div className="text-center">
                        <h2 className="mb-4">Comming Soon..</h2>
                     </div>
                  </div>
               </div>
            </Card.Body>
         </Card>         
      </>
   );
};

export default Reports;