import React, { useState, useEffect, useContext } from "react";
import { Card, Table, Button, Tooltip } from "react-bootstrap";
import Axios from "../../../plugins/axios.js";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import '../../../../config';
import { Link } from "react-router-dom";
import { Context } from '../../../../MasterLayout.js';
import { useTranslation } from 'react-i18next';
import RangeSlider from "../../Products/RangeSlider.js";
import InfiniteScrollWrapper from "../../Products/InfiniteScrollWrapper.js";
import Category from "../../Products/Category.js";


const LiveProducts = ({ live }) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [category1, setCategory1] = useState('');
    const [category2, setCategory2] = useState('');
    const [categoryId1, setCategoryId1] = useState('');
    const [categoryId2, setCategoryId2] = useState('');
    const [salesRange, setSalesgRange] = useState([]);
    const [keyword, setKeyword] = useState(state && state.keyword ? state.keyword : '');
    const [searchClick, setSearchClick] = useState(0);
    const [sorting, setSorting] = useState({ sort: 35, sort_type: 'DESC' });
    const sortType = sorting.sort_type == 'DESC' ? 'ASC' : 'DESC';
    const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';

    const salesRangeOption = {
        '0-10000': '<10K',
        '10000-100000': '10K-100K',
        '100000-1000000': '100K-1M',
        '1000000-10000000': '1M-10M',
        '10000000-': '10M+',
    };

    useEffect(() => {
        setItems([]);
        setPage(0);
        productSearch(0);
    }, [category1, category2, searchClick, salesRange, sorting])

    const handleCategory = async (categoryIds, categoryNames) => {
        setCategory1(categoryNames[0] ? categoryNames[0] : '');
        setCategory2(categoryNames[1] ? categoryNames[1] : '');
        setCategoryId1(categoryIds[0] ? categoryIds[0] : '');
        setCategoryId2(categoryIds[1] ? categoryIds[1] : '');
    };

    const handleSalesRange = async (value) => {
        setSalesgRange(value);
    };

    const productSearch = async (page) => {
        try {
            let params = {
                room_id: live.id,
                page,
                sort: sorting.sort,
                sort_type: sorting.sort_type,
            };
            if (category1 != '') {
                params['category1'] = category1;
            }
            if (category2 != '') {
                params['category2'] = category2;
            }
            if (categoryId1 != '') {
                params['category_id1'] = categoryId1;
            }
            if (categoryId2 != '') {
                params['category_id2'] = categoryId2;
            }
            if (keyword != '') {
                params['keyword'] = keyword;
                dispatch({ type: 'keyword_searched', response: '' });
            }
            if (salesRange.from && salesRange.to) {
                params['sold_count_start'] = salesRange.from;
                params['sold_count_end'] = salesRange.to;
            }

            let response = await Axios.post('/api/live/products', params).catch(e => console.error('Axios-Error: ' + e.message));
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.status === 200 && response.data.data) {
                if (response && response.status === 200) {
                    if (page == 0) {
                        setItems(response.data.data);
                    } else {
                        setItems(items.concat(response.data.data));
                    }
                    if (response.data.data.length === 0 || response.data.data.length < 20) {
                        setHasMore(false);
                    } else {
                        setHasMore(true);
                    }
                } else {
                    setHasMore(false);
                }
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        };
    }

    const handleSearch = async () => {
        setItems([]);
        const clickCount = parseInt(searchClick) + 1;
        setSearchClick(clickCount);
    };

    const functionNext = async () => {
        let number = page + 1;
        setPage(number);
        productSearch(number)
    }

    const handleSort = async (sort, sortType) => {
        if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
            setSorting({ sort, sort_type: sortType });
        };
    };

    return (
        <>
            <Card>
                <Card.Body className='p-3'>
                    <div className='topfilter'>
                        <div className="d-flex align-items-center mb-3 justify-content-end">
                            <div className="input-group mb-3 w-auto">
                                <input type="text" className="form-control form-control-sm" placeholder={t("product_research.products_search.search_for_products")} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                                <div className="input-group-append">
                                    <button className="btn btn-primary btn-sm" type="button" onClick={() => handleSearch()}>
                                        {t('product_research.products_search.search')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Category singleCategory={false} handleCategory={handleCategory} categoryId1={categoryId1} endLevelShow={1} />
                        <div className="d-flex align-items-center mb-2">
                            <p className="m-0 mr-3"><strong>{t('product_research.products_search.advanced_filtering')}</strong></p>
                            <div className="dropdown product-rating-menu">
                                <a className="btn btn-outline-dark btn-sm mr-2 dropdown-toggle"
                                    role="button"
                                    id="dropdownMenuLink"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('product_research.products_search.product_rating')}
                                </a>
                                <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                    <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={10000000} rangeOption={salesRangeOption} rangeOptionName={'rating'} step={0.1} handleUpdate={handleSalesRange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {items.length > 0 &&
                <Card>
                    <Card.Body className='p-2'>
                        <Table responsive className="w-100">
                            <div id="example_wrapper" className="dataTables_wrapper">
                                <InfiniteScrollWrapper
                                    isInfiniteScrollOn={true}
                                    lengthData={items.length}
                                    functionNext={functionNext}
                                    hasMore={hasMore}
                                >
                                    <table id="example" className="display w-100 dataTable">
                                        <thead>
                                            <tr role="row">
                                                <th className='text-left'>{t('live_products.ranking')}</th>
                                                <th className='text-left'>{t('live_products.product')}</th>
                                                <th className='text-left'>{t('live_products.price')}</th>
                                                <th className='text-left'>{t('live_products.commission')}</th>
                                                <th className={`text-left sorting ${14 == sorting.sort ? sortClass : ''}`} onClick={() => { handleSort(14, sorting.sort == 14 ? sortType : 'ASC') }}>{t('live_products.sales')}</th>
                                                <th className={`text-left sorting ${35 == sorting.sort ? sortClass : ''}`} onClick={() => { handleSort(35, sorting.sort == 35 ? sortType : 'ASC') }}>{t('live_products.revenue')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items.map((product, index) => (
                                                <tr key={index}>
                                                    <td className='text-left'>{index + 1}</td>
                                                    <td className='text-left'>
                                                        <div className="d-flex">
                                                            <div className="prd-img mr-3">
                                                                <OverlayTrigger
                                                                    key="right"
                                                                    trigger={["hover", "focus"]}
                                                                    placement="right"
                                                                    rootClose
                                                                    overlay={
                                                                        <Popover>
                                                                            <Popover.Body>
                                                                                <img src={"https://t-img.picturehaven.net/product/" + product.cover + "?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={500} />
                                                                            </Popover.Body>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Button variant="link" className='p-0' size="sm">
                                                                        <Link to={'/products/' + product.id + '/details'} target="_blank">
                                                                            <img src={"https://t-img.picturehaven.net/product/" + product.cover + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />
                                                                        </Link>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="prd-title">
                                                                <p className="">
                                                                    <OverlayTrigger overlay={<Tooltip>{product.title}</Tooltip>}>
                                                                        <Link to={'/products/' + product.id + '/details'} target="_blank">
                                                                            <span>{product.title.length > 70 ? product.title.substring(0, 70) + '...' : product.title}</span>
                                                                        </Link>
                                                                    </OverlayTrigger>
                                                                </p>
                                                                <p className='small'>
                                                                    {product?.sys_country?.country_code && <span className={`fi fi-${product?.sys_country?.country_code.toLowerCase()}`}></span>}
                                                                    <span className={`fi`}></span>{product?.sys_country?.country || '--'}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='text-left'>
                                                        {product.sale_prop ?
                                                            <div className="d-flex justify-content-start">
                                                                <div className="mr-3">
                                                                    {product.floor_price_usd}<br />
                                                                    <span className="text-muted">({product.floor_price_str})</span>
                                                                </div>
                                                                <div className='d-flex p-variants'>
                                                                    <div className='v-btn'>
                                                                        <OverlayTrigger
                                                                            key="right"
                                                                            trigger="click"
                                                                            placement="right"
                                                                            rootClose
                                                                            overlay={
                                                                                <Popover id={`popover-positioned-$"right"`} className="variants-tooltip">
                                                                                    <Popover.Header as="h3">Variants</Popover.Header>
                                                                                    <Popover.Body>
                                                                                        <div className="d-flex mb-3">
                                                                                            <div className="mr-3">
                                                                                                <img src={"https://t-img.picturehaven.net/product/" + product.cover + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={200} />
                                                                                            </div>
                                                                                            <div>
                                                                                                <p className="">{product.title}</p>
                                                                                            </div>
                                                                                            <div>
                                                                                                {product.floor_price_usd}<br />
                                                                                                <span className="text-muted">({product.floor_price_usd})</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        {product.sale_prop.map((variant, index1) => (
                                                                                            <div className="mb-3" key={index1}>
                                                                                                <p className="mb-0"><strong>{variant.prop_name}</strong></p>
                                                                                                <div className="variant-box">
                                                                                                    {variant.sale_prop_values.map((value, index2) => (
                                                                                                        <Button key={index2} variant="outline-dark" size="sm" className="mb-1 mr-1">{value.prop_value}</Button>
                                                                                                    ))}
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                    </Popover.Body>
                                                                                </Popover>
                                                                            }
                                                                        >
                                                                            <Button variant="outline-primary" size="sm">Variants</Button>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <div className='v-truck'>
                                                                        {product.free_shipping &&
                                                                            <OverlayTrigger overlay={<Tooltip>Free Shipping</Tooltip>}>
                                                                                <span><i className="fa fa-truck mt-2 ml-2 text-muted" aria-hidden="true"></i></span>
                                                                            </OverlayTrigger>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''}
                                                    </td>                                                    
                                                    <td className="text-left">
                                                        {product.commission_rate ? `${(product.commission_rate * 100).toFixed(2)}%` : '-'}
                                                    </td>
                                                    <td className="text-left">
                                                        {product.sold_count ? product.sold_count : '-'}
                                                    </td>  
                                                    <td className="text-left">
                                                        {product.total_sales_usd ? product.total_sales_usd : '-'}
                                                    </td>                                                   
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </InfiniteScrollWrapper>
                            </div>
                        </Table>
                    </Card.Body>
                </Card>
            }
        </>
    );
};

export default LiveProducts;