import React from "react";
import { Card } from "react-bootstrap";
import logo from "../../../images/logo.svg";
import fetchimage from "../../../images/fetch-image.gif";
import tiktoklogo from "../../../images/tiktok-logo.png";

const StoreManager = () => {
   return (
      <div className="row">
         <div className="col-xl-12">
            <Card>
               <Card.Body>
                  <div className="row justify-content-center mt-4">
                     <div className="col-lg-9">
                        <Card className="bg-light">
                           <Card.Body className="text-center">
                              <p className='mb-2 lead'>Tiktok account sync is in progress.</p>
                              <h2>
                                 Please wait while we fetch your data<br /> and calculate your profits
                              </h2>
                              <div className='d-flex justify-content-center align-items-center'>
                                 <Card className="mb-0">
                                    <Card.Body>
                                       <img src={logo} alt="" />
                                    </Card.Body>
                                 </Card>
                                 <div className='px-2'>
                                    <img src={fetchimage} alt="" style={{ height: "180px", width: "180px" }} />
                                 </div>
                                 <Card className="mb-0">
                                    <Card.Body>
                                       <img src={tiktoklogo} alt="" style={{ width: "36px" }} />
                                    </Card.Body>
                                 </Card>
                              </div>
                           </Card.Body>
                        </Card>
                     </div>
                  </div>
               </Card.Body>
            </Card>
         </div>
      </div>
   );
};

export default StoreManager;