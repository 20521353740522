import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import Axios from '../../../../plugins/axios';
import RelevantVideoList from './RelevantVideoList';
import { Button, ButtonGroup, Card, Modal } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Conversion from '../../../CommonComponents/Conversion';
import moment from 'moment';
import { TikTokEmbed } from 'react-social-media-embed';
import { Context } from '../../../../../MasterLayout';
import { useTranslation } from 'react-i18next';


const RelevantVideos = forwardRef((props, ref) => {
    const { t } = useTranslation();

    const [onLoad, setOnLoad] = useState(false)
    const [relevantVideosFilter, setrelevantVideosFilterFilter] = useState('7_days');
    const dateRangeRef = useRef();
    const { dispatch } = useContext(Context);

    const [tikTokEmbedID, setTikTokEmbedID] = useState('');
    const [contentModal, setContentModal] = useState(false);
    const [Totalcount, setTotalcount] = useState(0)
    const [Apipage, setApiPage] = useState(1)
    const [dateFilterOption] = useState({
        '7_days': {
            label: 'Past 7 Days',
            begin_date: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        },
        '15_days': {
            label: 'Past 15 Days',
            begin_date: new Date(new Date().setDate(new Date().getDate() - 15)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        },
        '30_days': {
            label: 'Past 30 Days',
            begin_date: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        }
    });
    const [dateRange, setDateRange] = useState(dateFilterOption[relevantVideosFilter]);

    useImperativeHandle(ref, () => ({
        async relevantVideos() {
            if (onLoad == false) {
            }
            setOnLoad(true);
        }
    }));
    const [sorting, setSorting] = useState({ sort: 18 });
    const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-desc-blue';
    const handleSort = async (sort,) => {
        if (sort != sorting.sort) {
            setSorting({
                sort
            });
            setpage(0);
            setApiPage(1);
        }
    };

    const handledate = (key) => {
        setrelevantVideosFilterFilter(key);
        setpage(0);
        setApiPage(1)
    }
    const dateFilterOptionHtml = Object.keys(dateFilterOption).map(function (key) {
        return <Button key={key} variant="outline-dark" size="sm" className={(relevantVideosFilter == key ? 'btn-primary' : '')} onClick={() => { handledate(key) }}>{dateFilterOption[key].label}</Button>
    });
    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD')
        );
        setDateRange({ begin_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
        setrelevantVideosFilterFilter('custom');
    };
    const salesProductRef = useRef();

    const handleCancel = (event, picker) => {
        picker.element.val('');
        setDateRange([]);
    };
    const params = useParams();
    const changeDate = (startDate, endDate) => {
        dateRangeRef.current.setStartDate(moment(new Date(startDate)).format('MM/DD/YYYY'));
        dateRangeRef.current.setEndDate(moment(new Date(endDate)).format('MM/DD/YYYY'));
    };
    const [relevantVideo, setRelevantVideo] = useState();
    const [relevantVideolist, setRelevantVideolist] = useState([]);
    const [page, setpage] = useState(0);

    const relevantVideosAPI = async () => {
        try {
            let bodyParams = {};
            if (relevantVideosFilter == 'custom') {
                bodyParams = {
                    begin_date: dateRange.begin_date,
                    end_date: dateRange.end_date,
                };
            } else {
                bodyParams = dateFilterOption[relevantVideosFilter];
                setDateRange(bodyParams);
            }
            bodyParams.author_id = params.influencerId;

            changeDate(bodyParams.begin_date, bodyParams.end_date);
            if (sorting.sort) {
                bodyParams.sort = sorting.sort;
            }
            if (Apipage) {
                bodyParams.cursor = Apipage;
            }
            let response = await Axios.post('/api/influencer-relevant-videos', bodyParams);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.status === 200) {
                setRelevantVideo(response.data.data);
                const docs = [];
                for (let i = 0; i < 10; i++) {
                    if (response?.data?.data?.video_list_v2_list[i]) {
                        docs.push(response.data.data.video_list_v2_list[i])
                    } else { break; }
                };
                setRelevantVideolist(response.data.data.video_list_v2_list);
                setTotalcount(response.data.data.total)

                await salesProductRef.current.salesProduct(response.data.data);
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        };
    }
    useEffect(() => {
        relevantVideosAPI();
    }, [relevantVideosFilter, sorting, page, Apipage]);

    const handlePageClick = (event) => {
        setpage(event.selected);
        setApiPage(event.selected + 1)
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <div className="row mb-4">
                        <div className="col-lg-5">
                            <h3>{t('influencers.relevant_videos')}</h3>
                        </div>
                        <div className="col-lg-7 text-right">
                            <div className='d-flex justify-content-end align-items-center'>
                                <ButtonGroup className='mr-4'>
                                    {dateFilterOptionHtml}
                                </ButtonGroup>
                                <DateRangePicker
                                    ref={dateRangeRef}
                                    initialSettings={{ startDate: moment(new Date(dateRange.begin_date)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_date)).format('MM/DD/YYYY') }}
                                    onApply={handleApply} onCancel={handleCancel} /*onCallback={handleCallback}*/
                                >
                                    <input type="text" placeholder={t('influencers.first_seen')} className="form-control form-control-sm input-daterange-timepicker w-auto" value={dateRange.begin_date + " - " + dateRange.end_date} />
                                </DateRangePicker>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex mb-4'>
                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('common.sales')}</strong></p>
                                    <h3 className='mb-0'>{relevantVideo?.total_estimated_sales_volume ? <Conversion value={relevantVideo?.total_estimated_sales_volume} /> : '--'}</h3>
                                    <div className='d-flex'>{t('influencers.to_the_previous_cycle')} &nbsp;
                                        <p
                                            className={`mb-0 ${relevantVideo?.sold_count_incr > 0 ? 'text-success' :
                                                relevantVideo?.sold_count_incr < 0 ? 'text-danger' :
                                                    'text-muted'
                                                }`}
                                        >
                                            {relevantVideo?.sold_count_incr > 0 && "+"} {relevantVideo?.sold_count_incr < 0 && "-"}{relevantVideo?.sold_count_incr ? <Conversion value={Math.abs(relevantVideo?.sold_count_incr)} /> : '--'}
                                        </p>
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('common.revenue')}</strong></p>
                                    <div className='d-flex'>
                                        <h3 className='mb-0'>{relevantVideo?.total_estimated_sales_amount_by_usd_str || '--'}</h3>
                                        <p className='mb-0'>{relevantVideo?.total_estimated_sales_amount_str ? `(${relevantVideo?.total_estimated_sales_amount_str})` : '--'}</p>
                                    </div>
                                    <div className='d-flex'>{t('influencers.to_the_previous_cycle')} &nbsp;
                                        <p
                                            className={`mb-0 ${relevantVideo?.sales_amount_cycle_crc > 0 ? 'text-success' :
                                                relevantVideo?.sales_amount_cycle_crc < 0 ? 'text-danger' :
                                                    'text-muted'
                                                }`}
                                        >
                                            {((relevantVideo?.sales_amount_cycle_crc * 100).toFixed(2) > 0 && '+')} {relevantVideo?.sales_amount_cycle_crc ? `${(relevantVideo?.sales_amount_cycle_crc * 100).toFixed(2)}%` : '0.00%'}
                                        </p>
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card className="bg-light mr-3 h-100">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('influencers.sales_volume_explosion')}</strong></p>
                                    <h3> {relevantVideo?.sold_count_cycle_crc ? `${(relevantVideo?.sold_count_cycle_crc * 100).toFixed(2)}%` : '--'}</h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('influencers.sales_conversion_rate')}</strong></p>
                                    <h3> {relevantVideo?.conversion_rate ? `${(relevantVideo?.conversion_rate * 100).toFixed(2)}%` : '--'}</h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <RelevantVideoList currentPage={page} tikTokEmbedID={tikTokEmbedID} setTikTokEmbedID={setTikTokEmbedID} contentModal={contentModal} setContentModal={setContentModal} handleSort={handleSort} sortClass={sortClass} sorting={sorting} relevantVideolist={relevantVideolist} totalCount={Math.ceil(Totalcount / 10)} handlePageClick={handlePageClick} />
                </Card.Body>
            </Card>

            {/* <!-- Video Modal --> */}
            <Modal className="fade video-popup" show={contentModal}>
                <Modal.Body className='p-0'>
                    <div className='d-flex'>
                        <div className='text-center'>
                            <TikTokEmbed url={`https://www.tiktok.com/embed/${tikTokEmbedID}`} width={325} />
                        </div>
                        <div className='px-3'>
                            <div className='d-flex flex-column'>
                                <Button
                                    variant="dark"
                                    className="mb-3"
                                    onClick={() => { setTikTokEmbedID(''); setContentModal(!contentModal); }}
                                >
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                                {/* <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-line-chart" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-download" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-file-code-o" aria-hidden="true"></i>
                        </Button> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
})

export default RelevantVideos;